/*
---
name: Tagcloud
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Project
---
 *
ほげほげ説明文
 *
```css
.p-articles {
  color: aaa;
  display: block;
}
```

*
ほげほげな説明文
*
```css
.p-articles {
  &--hoge {
    なんか
  }
}
```
*/

.p-tagcloud {}

.p-tagcloud-container {
  background: black;
  color: $truewhite;
  @include fs(16);
  padding-top: 2em;
  padding-bottom: 3em;

  &__header {
    @include fs(36);
    margin-bottom: .5em;
    font-weight: 400;
    text-transform: uppercase;
  }

  &__body {
    @include fs(14);

    > li {
      display: inline-block;
      margin-right: 1em;
      margin-bottom: 1em;

      > a {
        color: rgba($truewhite,.8);
        text-decoration: none;
        display: inline-block;
        border: 1px solid rgba($truewhite,.5);
        padding: .4em 1.2em;
        border-radius: 1.4em;

        @include hover {
          background: rgba($truewhite,.1);
          color: $truewhite;
        };
      }
    }
  }
}
