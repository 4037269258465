/*
---
name: Nav Target
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Component
---

**Nav Target** はナビゲーションの表示・非表示を切り替える際のターゲット要素で、 [Nav Trigger](#Nav Trigger) と併せて使用されます。

`.c-nav-target` クラスと併せてブレイクポイントのサフィックスをマルチクラスで指定する必要があります。<br>
例えば、ブレイクポイントが SM 以下の場合にターゲットを非表示にしたい場合は、 `.c-nav-target--ltSM` となります。<br>
表示されている状態の Nav Target には、JS により `.is-visible` クラスが付与されます。非表示の状態になると `.is-visible` クラスが外れます。

また、ブラウザのリサイズを行った際に、有効になっているブレイクポイントの範囲外にある状態では `.is-visible` クラスが付与され、 Nav Target が表示されます。逆に、有効になっているブレイクポイントの範囲内にある状態では `.is-visible` クラスが削除され、 Nav Target は非表示になります。

```html
<button class="c-nav-trigger c-nav-trigger--ltLG" data-mq-trigger="[&quot;sp-nav&quot;,&quot;slide&quot;]"><span class="u-screen-reader-text">ナビゲーションを開閉する</span></button>

<div class="c-nav-target c-nav-target--ltLG" data-mq-target="[&quot;sp-nav&quot;]">
  <nav class="l-navigation" role="navigation">
    <ul class="l-navigation__body u-flex--gtXS">
      <li class="l-navigation__item">
        <a class="l-navigation__link" href="/">home</a>
      </li>
      <li class="l-navigation__item">
        <a class="l-navigation__link" href="/about/">about</a>
      </li>
      <li class="l-navigation__item">
        <a class="l-navigation__link" href="/service/">service</a>
      </li>
      <li class="l-navigation__item">
        <a class="l-navigation__link" href="/company/">company</a>
      </li>
    </ul>
  </nav>
</div>
```
```jade
//- Pug

button.c-nav-trigger.c-nav-trigger--ltLG(data-mq-trigger='["sp-nav","slide"]')
  span.u-screen-reader-text ナビゲーションを開閉する

.c-nav-target.c-nav-target--ltLG(data-mq-target='["sp-nav"]')
  nav(role="navigation").l-navigation
    ul.l-navigation__body.u-flex--gtXS
      li.l-navigation__item
        a.l-navigation__link(href="/") home
      li.l-navigation__item
        a.l-navigation__link(href="/about/") about
      li.l-navigation__item
        a.l-navigation__link(href="/service/") service
      li.l-navigation__item
        a.l-navigation__link(href="/company/") company
```

**[data-mq-target] 属性**

ターゲットの `[data-mq-target]` 属性の第1引数はトリガー要素との関連付けに使用します。そのため、トリガー要素の `[data-mq-trigger]` 属性の第1引数と同じ値を指定してください。

*/

.c-nav-target {

  @include mq {
    height: 100vh;
    display: none;
  };
}
