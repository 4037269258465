/*
---
name: Page Header
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Component
---

Page Header は、主に下層ページのページヘッダーに使用します。

```html
<div class="c-page-header">
  <div class="c-page-header__item c-container">
    <div class="c-page-header__en-title">About</div>
    <div class="c-page-header__detail">
      <h1 class="c-page-header__title">REINDEER とは？</h1>
      <p class="c-page-header__lead">REINDEER は戦略に応じたクリエイティブソリューションを提供します。</p>
    </div>
  </div>
</div>
```
```jade
//- Pug
.c-page-header
  .c-page-header__item.c-container
    .c-page-header__en-title About
    .c-page-header__detail
      h1.c-page-header__title REINDEER とは？
      p.c-page-header__lead REINDEER は戦略に応じたクリエイティブソリューションを提供します。
```

[Hero Image](#Hero Image) と併せて使用する場合は、 `.c-page-header` の子要素として `.c-page-header__item` に続けて記載してください。その際、 `.c-page-header__image` クラスのボックスで Wrap する必要があります。

```html
<div class="c-page-header">
  <div class="c-page-header__item c-container">
    <div class="c-page-header__en-title">About</div>
    <div class="c-page-header__detail">
      <h1 class="c-page-header__title">REINDEER とは？</h1>
      <p class="c-page-header__lead">REINDEER は戦略に応じたクリエイティブソリューションを提供します。</p>
    </div>
  </div>
  <div class="c-page-header__image">
    <figure class="c-hero-image"><img class="c-hero-image__item" src="/_assets/img/hero-image/img-about.jpg"></figure>
  </div>
</div>
```
```jade
//- Pug
.c-page-header
  .c-page-header__item.c-container
    .c-page-header__en-title About
    .c-page-header__detail
      h1.c-page-header__title REINDEER とは？
      p.c-page-header__lead REINDEER は戦略に応じたクリエイティブソリューションを提供します。
  .c-page-header__image
    figure.c-hero-image
      img.c-hero-image__item(src="/_assets/img/hero-image/img-about.jpg")
```

`.c-page-header__en-title` は、主に英語表記のラベルとして使用します。このブロックを使用しない場合、は `.c-page-header__detail` は中央配置されます。

`.c-page-header__detail` のテキストをセンタリングする場合は `.c-page-header__detail` と併せて `--center` の Modifier をマルチクラスとして指定します。

```html
<div class="c-page-header">
  <div class="c-page-header__item c-container">
    <div class="c-page-header__detail c-page-header__detail--center">
      <h1 class="c-page-header__title c-page-header__title--large">REINDEER とは？</h1>
      <p class="c-page-header__lead">REINDEER は戦略に応じたクリエイティブソリューションを提供します。</p>
    </div>
  </div>
  <div class="c-page-header__image">
    <figure class="c-hero-image"><img class="c-hero-image__item" src="/_assets/img/hero-image/img-about.jpg"></figure>
  </div>
</div>
```
*/

.c-page-header {
  background: $white;
  position: relative;
  overflow: hidden;

  &--shift-to-bottom {
    margin-top: $headerH;

    @include inXS {
      margin-top: $headerH + 3em;
    };
  }

  &--index-hero {
    background: url('../img/index/hero-180812.jpg');
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    position: relative;
    z-index: 1;

    @include ltXS {
      background-size: auto 100%;
      background-attachment: scroll;
    };

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background: $truewhite;
      @include opacity(.8);
      z-index: 2;
    }
  }

  &__item {
    @include flex(ce,ce,false);
    min-height: 12em;
    padding: 6em 3em 10em;
    position: relative;
    z-index: 10;

    &--viewport {
      height: 100vh;

      @include ltXS {
        max-height: 160vw;
        padding: 6em 3em;
      };
    }

    @include ltXXS {
      @include flex(ce,ce);
    };
  }

  &__en-title {
    @include fs(60);
    font-weight: 200;
    white-space: nowrap;
    text-transform: uppercase;
  }

  &__detail {

    @include flex(ce,ce);

    @include ltXXS {
      margin-bottom: 1em;
    };

    &--center {
      text-align: center;
      margin-left: 0;
    }
  }

  &__symbol {
    width: 24em;
    margin-right: 3em;

    @include ltXS {
      width: 16em;
      margin-right: 0;
      margin-bottom: 2em;
    };

    img {
      display: block;
    }
  }

  &__body {}

  &__title {
    @include fs(20);
    font-weight: 500;

    &--large {
      line-height: 1em;
      @include fs(80);
      font-weight: 900;

      @include ltXXS {
        @include fs(60);
      };
    }
  }

  &__lead {
    margin-top: 2em;
    @include fs(20);
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 8;
  }

  @include gtSM {
    [data-onload] {
      opacity: 0;
      transition: opacity 3s;

      &.animated {
        opacity: 1;
      }
    }
  };
}
