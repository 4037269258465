/*
---
name: Colors
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Foundation
---

Colors における各変数には

- Color set
- UI color
- Gray-scale color

の3つのカテゴリに分けて、色を指定しています。

### Color set

Color set ではテーマで使用する4つのカラーセットパターンを指定します。いずれの色には優劣や優先度などのレベルは存在せず、それぞれがフラットな立ち位置で振る舞います。また、各カラーをベースとした明度（高・低）と彩度（高・低）のバリエーションが生成されます。

<table>
  <tr>
    <th></th>
    <th>lighten</th>
    <th>darken</th>
    <th>saturate</th>
    <th>desaturate</th>
  </tr>
  <tr>
    <td><i class="u-cs01">●</i>$cs01</td>
    <td><i class="u-cs01Lt">●</i>$cs01Lt</td>
    <td><i class="u-cs01Dk">●</i>$cs01Dk</td>
    <td><i class="u-cs01St">●</i>$cs01St</td>
    <td><i class="u-cs01Ds">●</i>$cs01Ds</td>
  </tr>
  <tr>
    <td><i class="u-cs02">●</i>$cs02</td>
    <td><i class="u-cs02Lt">●</i>$cs02Lt</td>
    <td><i class="u-cs02Dk">●</i>$cs02Dk</td>
    <td><i class="u-cs02St">●</i>$cs02St</td>
    <td><i class="u-cs02Ds">●</i>$cs02Ds</td>
  </tr>
  <tr>
    <td><i class="u-cs03">●</i>$cs03</td>
    <td><i class="u-cs03Lt">●</i>$cs03Lt</td>
    <td><i class="u-cs03Dk">●</i>$cs03Dk</td>
    <td><i class="u-cs03St">●</i>$cs03St</td>
    <td><i class="u-cs03Ds">●</i>$cs03Ds</td>
  </tr>
  <tr>
    <td><i class="u-cs04">●</i>$cs04</td>
    <td><i class="u-cs04Lt">●</i>$cs04Lt</td>
    <td><i class="u-cs04Dk">●</i>$cs04Dk</td>
    <td><i class="u-cs04St">●</i>$cs04St</td>
    <td><i class="u-cs04Ds">●</i>$cs04Ds</td>
  </tr>
</table>

Color set で定義された色相をインターフェース上で使用する場合は、同一の Component で平均的に使用することが良いでしょう。異なる Component ごとに使用してしまうと単純に色相が散在してしまう状態となるため、秩序のない印象を与える要因となります。

3〜4種類で収められる大カテゴリや主要な投稿タイプのアーカイブやカテゴリバッジなどで使用するなどです。

5種類以上の色相が必要になった場合は変数を追加することもできますが、無秩序に色相が散在することになるので、できれば単一色にしトーンを単純化しましょう。

### UI color

UI color ではインターフェースで使用する3つの UI カラーパターンを指定します。基本となる色であるため、CI やブランディングマニュアルに準じた色相とする場合が多いでしょう。UI color におけるカラー変数にはそれぞれ以下の様な役割があります。

- **$base** :<br>
最も大きな面積を占め、余白や背景などに用いることが多い色です。$main, $accent のカラーを引き立てる主張の弱い色相である必要があります。
- **$main** :<br>
UI のカラーリングのベースとなる色相です。プロダクトの印象を決定づける主役の色であるため、多くは CI マニュアルの指定の色となるでしょう。
- **$accent** :<br>
$base, $main とは異なり、ユーザの目を引く目立つ色相です。全体の 5％ 程度の使用に留める必要があります。

<table>
  <tr>
    <th></th>
    <th>lighten</th>
    <th>darken</th>
    <th>saturate</th>
    <th>desaturate</th>
  </tr>
  <tr>
    <td><i class="u-base">●</i>$base</td>
    <td><i class="u-baseLt">●</i>$baseLt</td>
    <td><i class="u-baseDk">●</i>$baseDk</td>
    <td><i class="u-baseSt">●</i>$baseSt</td>
    <td><i class="u-baseDs">●</i>$baseDs</td>
  </tr>
  <tr>
    <td><i class="u-main">●</i>$main</td>
    <td><i class="u-mainLt">●</i>$mainLt</td>
    <td><i class="u-mainDk">●</i>$mainDk</td>
    <td><i class="u-mainSt">●</i>$mainSt</td>
    <td><i class="u-mainDs">●</i>$mainDs</td>
  </tr>
  <tr>
    <td><i class="u-accent">●</i>$accent</td>
    <td><i class="u-accentLt">●</i>$accentLt</td>
    <td><i class="u-accentDk">●</i>$accentDk</td>
    <td><i class="u-accentSt">●</i>$accentSt</td>
    <td><i class="u-accentDs">●</i>$accentDs</td>
  </tr>
</table>

### Gray-scale color

Gray-scale color では主張のない装飾に使用するグレースケール・カラーを指定します。 **$grey-base** は 50% グレーの基準色であり、この色を基準に 12 種類のグレースケール・カラーのパターンを生成します。

#### $grey-base

$standard-gray, $blue-gray, $brown-gray の3色から **$grey-base** に指定する色を選択します。

<table>
<tr>
  <td><i class="u-grey-base">●</i>$grey-base</td>
</tr>
  <tr>
    <td><i class="u-standard-gray">●</i>$standard-gray</td>
    <td><i class="u-blue-gray">●</i>$blue-gray</td>
    <td><i class="u-brown-gray">●</i>$brown-gray</td>
  </tr>
</table>

> - **$standard-gray** :<br>
> 一般的な50%グレーです。
> - **$blue-gray** :<br>
> 青色寄りのグレーです。
> - **$brown-gray** :<br>
> 赤色寄りのグレーです。

<table>
  <tr>
    <td><i class="u-truewhite">●</i>$truewhite</td>
    <td><i class="u-white">●</i>$white</td>
  </tr>
  <tr>
    <td><i class="u-lightsilver">●</i>$lightsilver</td>
    <td><i class="u-silver">●</i>$silver</td>
    <td><i class="u-darksilver">●</i>$darksilver</td>
  </tr>
  <tr>
    <td><i class="u-lightgrey">●</i>$lightgrey</td>
    <td><i class="u-grey">●</i>$grey</td>
    <td><i class="u-darkgrey">●</i>$darkgrey</td>
  </tr>
  <tr>
    <td><i class="u-lightcharcoal">●</i>$lightcharcoal</td>
    <td><i class="u-charcoal">●</i>$charcoal</td>
    <td><i class="u-darkcharcoal">●</i>$darkcharcoal</td>
  </tr>
  <tr>
    <td><i class="u-black">●</i>$black</td>
    <td><i class="u-trueblack">●</i>$trueblack</td>
  </tr>
</table>

※ WEB セーフカラーに関しては変数の定義は行わず、 [WEBセーフカラー](../Object/Utility/index.html#web-safe-colors) のユーティリティ・クラスを用意しています。

### Additional color

Additional color は Color set, UI color, Gray-scale color のいずれにも該当しない例外的な使用を行う色相です。

> 現在有効な Additional color はありません。必要になった場合は `./_development/_source/_scss/foundation/_colors.scss` にて変数を宣言してください。

*/

// --------------------------------------------------
// Color set
// --------------------------------------------------

// Ratio range
$range: 7.5%;

$cs01:                     #072753;
$cs01Lt:    lighten($cs01, $range);
$cs01Dk:     darken($cs01, $range);
$cs01St:   saturate($cs01, $range);
$cs01Ds: desaturate($cs01, $range);

$cs02:                     #27a5b6;
$cs02Lt:    lighten($cs02, $range);
$cs02Dk:     darken($cs02, $range);
$cs02St:   saturate($cs02, $range);
$cs02Ds: desaturate($cs02, $range);

$cs03:                     #53ada8;
$cs03Lt:    lighten($cs03, $range);
$cs03Dk:     darken($cs03, $range);
$cs03St:   saturate($cs03, $range);
$cs03Ds: desaturate($cs03, $range);

$cs04:                     #D81B60;
$cs04Lt:    lighten($cs04, $range);
$cs04Dk:     darken($cs04, $range);
$cs04St:   saturate($cs04, $range);
$cs04Ds: desaturate($cs04, $range);

// --------------------------------------------------
// UI color
// --------------------------------------------------

$base:                         #f4f4f4;
$baseLt:        lighten($base, $range);
$baseDk:         darken($base, $range);
$baseSt:       saturate($base, $range);
$baseDs:     desaturate($base, $range);

$main:                         #072753;
$mainLt:         lighten($main, $range);
$mainDk:          darken($main, $range);
$mainSt:        saturate($main, $range);
$mainDs:      desaturate($main, $range);

$accent:                       #53ada8;
$accentLt:    lighten($accent, $range);
$accentDk:     darken($accent, $range);
$accentSt:   saturate($accent, $range);
$accentDs: desaturate($accent, $range);

// --------------------------------------------------
// Gray-scale color
// --------------------------------------------------

$standard-gray:                 #7F7F7F;
$blue-gray:                     #727A8B;
$brown-gray:                    #827169;
$grey-base:              $standard-gray;

$truewhite:    lighten($grey-base, 50%);
$white:        lighten($grey-base, 45%);
$lightsilver:  lighten($grey-base, 40%);
$silver:       lighten($grey-base, 30%);
$darksilver:   lighten($grey-base, 20%);
$lightgrey:    lighten($grey-base, 10%);
$grey:                       $grey-base;
$darkgrey:      darken($grey-base, 10%);
$lightcharcoal: darken($grey-base, 20%);
$charcoal:      darken($grey-base, 30%);
$darkcharcoal:  darken($grey-base, 40%);
$black:         darken($grey-base, 45%);
$trueblack:     darken($grey-base, 50%);

// --------------------------------------------------
// Additional color
// --------------------------------------------------

$grad: linear-gradient(to right, $base 0%, $accent 100%);
