/*
---
name: Logo
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Component
---

サイト共通のロゴを `.c-logo` にて指定します。シンボルマークとロゴタイプを分離する場合、シンボルは `.c-logo__symbol` 、ロゴタイプは `.c-logo__text` とし、 `.c-logo__body` にて Wrap してください。

```html
<h1 class="c-logo">
  <a class="c-logo__body" href="/">
    <i class="glyph glyph-reindeer c-logo__symbol"></i>
    <span class="c-logo__text">REINDEER</span>
  </a>
</h1>
```
```jade
//- Pug
h1.c-logo
  a.c-logo__body(href="/")
    i.glyph.glyph-reindeer.c-logo__symbol
    span.c-logo__text REINDEER
```
*/

.c-logo {
  font-size: 16px;
  @include flex(ce,ce);
  height: 4em;
  letter-spacing: 0;
  margin-left: 1.5em;
  margin-right: 1.5em;

  & * {
    letter-spacing: 0;
  }

  @include ltXXS {
    height: 5em;
  };

  &__body {
    font-size: 28px;
    @include flex(ce,sb);
    height: 100%;
    color: currentColor;
    text-decoration: none;

    .is-entire-nav-target & {
      filter: brightness(1000%) grayscale(100%);
    }
  }

  &__symbol {
    margin-right: 4px;
    display: block;
    width: 16px;
    height: 21px;
    padding: 0;

    &--shift-to-top {
      margin-top: -5px;
    }

    img {
      display: block;
      width: inherit;
      height: inherit;
    }
  }

  &__text {
    font-weight: normal;
    white-space: nowrap;

    & > span {
      color: #544E45;
    }
  }

  // &__tag-line {
  //   font-size: 10px;
  //   width: 20em;
  //   margin-left: 2em;
  //   line-height: 1.5em;
  //
  //   @include ltSM {
  //     display: none;
  //   };
  // }
}
