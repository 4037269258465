/*
---
name: Align
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Utility
---

*/

// ----------------------------------------
//  Placement of images posted in WordPress
// ----------------------------------------

@include gtSM {

  img {
    height: auto;

    &.aligncenter {
      max-width: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 1em;
    }

    &.centered {
      max-width: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 1em;
    }

    &.alignright {
      max-width: 50%;
      padding: 4px;
      margin: 0 0 1em 1em;
      display: inline;
    }

    &.alignleft {
      max-width: 50%;
      padding: 4px;
      margin: 0 1em 1em 0;
      display: inline;
    }
  }

  .alignright {
    float: right;
  }

  .alignleft {
    float: left;
  }
};

@include ltXS {
  img {
    height: auto;
    &.aligncenter,
    &.centered,
    &.alignright,
    &.alignleft {
      max-width: 100%;
      display: block;
      margin: 10px auto;
    }
  }
};
