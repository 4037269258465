/*
---
name: Container
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Component
---

**Container** は各ブレイクポイントごとに適度な幅が指定されたボックス・コンテナーで、コンテンツおよび Component の Wrap 要素として使用します。


```html
<div class="c-container" style="background: DimGray;">
  Your Content or Component here...
</div>
```

Container は下記のブレイクポイントごとの横幅を維持した状態で中央配置されます。ただし、横幅の指定された要素の配下にある場合は、その要素の横幅を超える事はありません。

|Range|Container Width|
|-|-|
|**XL : **1600px ~ ∞|1470px|
|**LG : **1200 ~ 1599px|1170px|
|**MD : **992 ~ 1199px|970px|
|**SM : **768 ~ 991px|740px|
|**XS : **480 ~ 767px|100%|
|**XXS : **0 ~ 479px|100%|

※ XS および XXS サイズでは `padding-left`・`padding-right` に `2%` が指定されます。

### Container Wrap

**Container Wrap** は、他の Container や Component を包含する場合に `flex:` プロパティが有効になり、子要素がカラム表示されるコンポーネントです。主に Sidebar のあるレイアウトに有効です。

```html
<div class="c-container-wrap">

  <section class="c-container c-content c-editor-content">
    <h2>Main Content Title.</h2>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
  </section>

  <aside class="l-sidebar" role="complementary">
    <h3 class="l-sidebar__title">Sidebar Title</h3>
    <nav class="p-page-list">
    <h4 class="p-page-list__title"><a class="p-page-list__label" href="/">Page List</a></h4>
      <ul class="p-page-list__body">
        <li class="p-page-list__item"><a class="p-page-list__content" href="/">Page Links...</a></li>
        <li class="p-page-list__item"><a class="p-page-list__content" href="/">Page Links...</a></li>
        <li class="p-page-list__item"><a class="p-page-list__content" href="/">Page Links...</a></li>
      </ul>
    </nav>
    <p class="l-sidebar__text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
  </aside>
</div>
```
```jade
//- Pug
.c-container-wrap
  section.c-container.c-content.c-editor-content
    h2 Main Content Title.
    p
      | Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
  aside.l-sidebar(role="complementary")
    h3.l-sidebar__title Sidebar Title
    nav.p-page-list
    h4.p-page-list__title Page List
      a.p-page-list__label(href="/") Page List
      ul.p-page-list__body
        li.p-page-list__item
          a.p-page-list__content(href="/") Page Links...
        li.p-page-list__item
          a.p-page-list__content(href="/") Page Links...
        li.p-page-list__item
          a.p-page-list__content(href="/") Page Links...
    p.l-sidebar__text
      | Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
```

Container Wrap は Container と同様に、下記のブレイクポイントごとの横幅を維持した状態で中央配置されます。ただし、横幅の指定された要素の配下にある場合は、その要素の横幅を超える事はありません。

|Range|Container Width|
|-|-|
|**XL : **1600px ~ ∞|1470px|
|**LG : **1200 ~ 1599px|1170px|
|**MD : **992 ~ 1199px|970px|
|**SM : **768 ~ 991px|740px|
|**XS : **480 ~ 767px|100%|
|**XXS : **0 ~ 479px|100%|

Container と異なり、XS および XXS サイズでの `padding-left`・`padding-right` は `0` が指定されます。これは、 Container を包含する可能性を考慮したものです。


`.c-container-wrap` クラスと併せて `.c-container-wrap--reverse` Modifier を追加することで `flex-direction:` プロパティを `row-reverse` へ変更することが可能です。

```html
<div class="c-container-wrap c-container-wrap--reverse">

  <section class="c-container c-content c-editor-content">
    <h2>Main Content Title.</h2>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
  </section>

  <aside class="l-sidebar" role="complementary">
    <h3 class="l-sidebar__title">Sidebar Title</h3>
    <nav class="p-page-list">
    <h4 class="p-page-list__title"><a class="p-page-list__label" href="/">Page List</a></h4>
      <ul class="p-page-list__body">
        <li class="p-page-list__item"><a class="p-page-list__content" href="/">Page Links...</a></li>
        <li class="p-page-list__item"><a class="p-page-list__content" href="/">Page Links...</a></li>
        <li class="p-page-list__item"><a class="p-page-list__content" href="/">Page Links...</a></li>
      </ul>
    </nav>
    <p class="l-sidebar__text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
  </aside>
</div>
```
```jade
//- Pug
.c-container-wrap.c-container-wrap--reverse
  section.c-container.c-content.c-editor-content
    h2 Main Content Title.
    p
      | Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
  aside.l-sidebar(role="complementary")
    h3.l-sidebar__title Sidebar Title
    nav.p-page-list
    h4.p-page-list__title Page List
      a.p-page-list__label(href="/") Page List
      ul.p-page-list__body
        li.p-page-list__item
          a.p-page-list__content(href="/") Page Links...
        li.p-page-list__item
          a.p-page-list__content(href="/") Page Links...
        li.p-page-list__item
          a.p-page-list__content(href="/") Page Links...
    p.l-sidebar__text
      | Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
```
*/

.c-container {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  // @include ltXL {width: 1470px;};
  @include ltXL {width: 1170px;};

  @include ltLG {width: 1170px;};

  @include ltMD {width: 970px;};

  @include ltSM {width: 740px;};

  @include ltXS {
    box-sizing: border-box;
    width: 100%;
    padding-left: 6%;
    padding-right: 6%;
  };

  // サイドバーが有効のときのコンテナ
  &-wrap &:nth-last-child(2) {
    max-width: calc( 100% - 16em - 4em );

    @include ltSM {
      max-width: calc( 100% - 25% - 6% );
    };

    @include ltXS {
      max-width: 100%;
    };
  }

  &-wrap {
    @extend .c-container ;
    display: flex;

    &--reverse {
      flex-direction: row-reverse;
    }

    @include ltXS {
      flex-wrap: wrap;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
