/*
---
name: Relative
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Project
---
*/

.p-relative {
  @include flex(fs,sb);

  @include ltSM {
    // width: 48%;
  };

  &__item {
    width: 24%;

    @include ltSM {
      width: 48%;
      margin-bottom: 3em;
    };

    @include ltXS {
      width: 100%;
      margin-bottom: 3em;
    };
  }

  &__thumb {
    margin: 0;
    height: 12em;

    @include ltXS {
      height: 40vw;
      max-height: 20em;
    };

    @include ltXXS {
      height: 16em;

    };

    img {
      width: 100%;
      height: 100%;
      display: block;
      @include object-fit;
    }
  }

  &__detail {
    padding: .0 1em;
    background: $truewhite;
    letter-spacing: 0;
  }

  &__title {
    @include fs(13);
    border-bottom: 1px solid rgba($grey,.2);
    min-height: 5em;
    margin-bottom: .5em;
    line-height: 1.4em;
    @include flex(ce,fs);

    a {
      color: currentColor;
      text-decoration: none;
    }
  }

  &__category {
    @include fs(12);

    dl {
      @include flex(ce,fs);
      min-height: 2.5em;
    }

    dt {
      @include fs(10);
      background: rgba($grey,.1);
      padding: .2em;
      width: 7em;
      text-align: center;
      border-radius: 1em;
    }

    dd {
      margin-left: 1em;
      width: calc( 100% - 8em );
      line-height: 1.2em;
    }
  }

  &__duration {
    @include fs(10.5);

    @include ltMD {
      @include fs(12);
    };

    span {
      @include ltMD {
        @include fs(10.5);
        display: block;
        font-weight: 600;
      };
    }
  }

  &__name {
    border-top: 1px solid rgba($grey,.2);
    margin-top: .5em;
    padding: .5em 0;
    @include fs(14);
    @include flex(ce,fs,false);
    line-height: 1.2em;

    img {
      min-width: 3em;
      max-width: 3em;
      height: 3em;
    }
  }

  &__button {
    display: block;
    text-decoration: none;
    margin-top: .5em;;
    padding: .5em;
    text-align: center;
    text-transform: uppercase;
    color: $truewhite;
    background: $trueblack;

    &:hover {
      background: $cs02;
      color: white;
      border-color: transparent;
    }
  }
}

.p-relative-container {
  @include fs(16);
  background: url(../img/index/greenhouse-cowork-568024-unsplash.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  margin-top: 2em;
  padding: 4em 0 5em;

  &__header {
    @include fs(30);
    margin-bottom: .5em;
    font-weight: 400;
    color: $truewhite;
  }

}
