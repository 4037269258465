/*
---
name: PageTop Button
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Component
---
ページ上部にスクロールする要素のデフォルト・スタイルです。

```html
<a class="c-pagetop-button" href="#pagetop">
  <span class="u-screen-reader-text">ページ上部へ移動</span>
</a>
```
```jade
//- Pug
a.c-pagetop-button(href="#pagetop")
  span.u-screen-reader-text ページ上部へ移動
```
*/

.c-pagetop-button {
  display: block;
  width: 3.6em;
  height: 3.6em;
  position: fixed;
  bottom: 1em;
  right: -5em;
  text-align: center;
  color: rgba($cs02,.8);
  border-radius: 50%;
  transition-duration: .3s;
  z-index: 1050;

  @include ltSM {
    @include gradbg($cs02,$cs03);
    color: $truewhite;
  };

  @include ltXS {
    z-index: 100;
  };

  @include hover {
    background: $accent;
    color: $truewhite;
  };

  &.is-active {
    right: 1em;
  }

  &::before {
    content: "";
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-top: 1.4em;
    border-left: 2px solid;
    border-top: 2px solid;
    transform: rotate(45deg);
  }
}
