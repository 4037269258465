/*
---
name: Colors
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Utility
---

### User defined colors

ユーザ定義色を color プロパティにて使用する場合は、`.u-{variable-name}` クラスを、background-color プロパティにて使用する場合は、`.u-bg-{variable-name}` クラスを付与してください。

#### Color set

<table>
  <tr>
    <th></th>
    <th>lighten</th>
    <th>darken</th>
    <th>saturate</th>
    <th>desaturate</th>
  </tr>
  <tr>
    <td><i class="u-cs01">●</i>u-cs01</td>
    <td><i class="u-cs01Lt">●</i>u-cs01Lt</td>
    <td><i class="u-cs01Dk">●</i>u-cs01Dk</td>
    <td><i class="u-cs01St">●</i>u-cs01St</td>
    <td><i class="u-cs01Ds">●</i>u-cs01Ds</td>
  </tr>
  <tr>
    <td><i class="u-cs02">●</i>u-cs02</td>
    <td><i class="u-cs02Lt">●</i>u-cs02Lt</td>
    <td><i class="u-cs02Dk">●</i>u-cs02Dk</td>
    <td><i class="u-cs02St">●</i>u-cs02St</td>
    <td><i class="u-cs02Ds">●</i>u-cs02Ds</td>
  </tr>
  <tr>
    <td><i class="u-cs03">●</i>u-cs03</td>
    <td><i class="u-cs03Lt">●</i>u-cs03Lt</td>
    <td><i class="u-cs03Dk">●</i>u-cs03Dk</td>
    <td><i class="u-cs03St">●</i>u-cs03St</td>
    <td><i class="u-cs03Ds">●</i>u-cs03Ds</td>
  </tr>
  <tr>
    <td><i class="u-cs04">●</i>u-cs04</td>
    <td><i class="u-cs04Lt">●</i>u-cs04Lt</td>
    <td><i class="u-cs04Dk">●</i>u-cs04Dk</td>
    <td><i class="u-cs04St">●</i>u-cs04St</td>
    <td><i class="u-cs04Ds">●</i>u-cs04Ds</td>
  </tr>
</table>

<table>
  <tr>
    <th></th>
    <th>lighten</th>
    <th>darken</th>
    <th>saturate</th>
    <th>desaturate</th>
  </tr>
  <tr>
    <td><i class="u-bg-cs01">　</i>u-bg-cs01</td>
    <td><i class="u-bg-cs01Lt">　</i>u-bg-cs01Lt</td>
    <td><i class="u-bg-cs01Dk">　</i>u-bg-cs01Dk</td>
    <td><i class="u-bg-cs01St">　</i>u-bg-cs01St</td>
    <td><i class="u-bg-cs01Ds">　</i>u-bg-cs01Ds</td>
  </tr>
  <tr>
    <td><i class="u-bg-cs02">　</i>u-bg-cs02</td>
    <td><i class="u-bg-cs02Lt">　</i>u-bg-cs02Lt</td>
    <td><i class="u-bg-cs02Dk">　</i>u-bg-cs02Dk</td>
    <td><i class="u-bg-cs02St">　</i>u-bg-cs02St</td>
    <td><i class="u-bg-cs02Ds">　</i>u-bg-cs02Ds</td>
  </tr>
  <tr>
    <td><i class="u-bg-cs03">　</i>u-bg-cs03</td>
    <td><i class="u-bg-cs03Lt">　</i>u-bg-cs03Lt</td>
    <td><i class="u-bg-cs03Dk">　</i>u-bg-cs03Dk</td>
    <td><i class="u-bg-cs03St">　</i>u-bg-cs03St</td>
    <td><i class="u-bg-cs03Ds">　</i>u-bg-cs03Ds</td>
  </tr>
  <tr>
    <td><i class="u-bg-cs04">　</i>u-bg-cs04</td>
    <td><i class="u-bg-cs04Lt">　</i>u-bg-cs04Lt</td>
    <td><i class="u-bg-cs04Dk">　</i>u-bg-cs04Dk</td>
    <td><i class="u-bg-cs04St">　</i>u-bg-cs04St</td>
    <td><i class="u-bg-cs04Ds">　</i>u-bg-cs04Ds</td>
  </tr>
</table>

#### UI color

<table>
  <tr>
    <th></th>
    <th>lighten</th>
    <th>darken</th>
    <th>saturate</th>
    <th>desaturate</th>
  </tr>
  <tr>
    <td><i class="u-base">●</i>u-base</td>
    <td><i class="u-baseLt">●</i>u-baseLt</td>
    <td><i class="u-baseDk">●</i>u-baseDk</td>
    <td><i class="u-baseSt">●</i>u-baseSt</td>
    <td><i class="u-baseDs">●</i>u-baseDs</td>
  </tr>
  <tr>
    <td><i class="u-main">●</i>u-main</td>
    <td><i class="u-mainLt">●</i>u-mainLt</td>
    <td><i class="u-mainDk">●</i>u-mainDk</td>
    <td><i class="u-mainSt">●</i>u-mainSt</td>
    <td><i class="u-mainDs">●</i>u-mainDs</td>
  </tr>
  <tr>
    <td><i class="u-accent">●</i>u-accent</td>
    <td><i class="u-accentLt">●</i>u-accentLt</td>
    <td><i class="u-accentDk">●</i>u-accentDk</td>
    <td><i class="u-accentSt">●</i>u-accentSt</td>
    <td><i class="u-accentDs">●</i>u-accentDs</td>
  </tr>
</table>

<table>
  <tr>
    <th></th>
    <th>lighten</th>
    <th>darken</th>
    <th>saturate</th>
    <th>desaturate</th>
  </tr>
  <tr>
    <td><i class="u-bg-base">　</i>u-bg-base</td>
    <td><i class="u-bg-baseLt">　</i>u-bg-baseLt</td>
    <td><i class="u-bg-baseDk">　</i>u-bg-baseDk</td>
    <td><i class="u-bg-baseSt">　</i>u-bg-baseSt</td>
    <td><i class="u-bg-baseDs">　</i>u-bg-baseDs</td>
  </tr>
  <tr>
    <td><i class="u-bg-main">　</i>u-bg-main</td>
    <td><i class="u-bg-mainLt">　</i>u-bg-mainLt</td>
    <td><i class="u-bg-mainDk">　</i>u-bg-mainDk</td>
    <td><i class="u-bg-mainSt">　</i>u-bg-mainSt</td>
    <td><i class="u-bg-mainDs">　</i>u-bg-mainDs</td>
  </tr>
  <tr>
    <td><i class="u-bg-accent">　</i>u-bg-accent</td>
    <td><i class="u-bg-accentLt">　</i>u-bg-accentLt</td>
    <td><i class="u-bg-accentDk">　</i>u-bg-accentDk</td>
    <td><i class="u-bg-accentSt">　</i>u-bg-accentSt</td>
    <td><i class="u-bg-accentDs">　</i>u-bg-accentDs</td>
  </tr>
</table>

#### Gray-scale color

##### u-grey-base

<table>
<tr>
  <td><i class="u-grey-base">●</i>u-grey-base</td>
</tr>
  <tr>
    <td><i class="u-standard-gray">●</i>u-standard-gray</td>
    <td><i class="u-blue-gray">●</i>u-blue-gray</td>
    <td><i class="u-brown-gray">●</i>u-brown-gray</td>
  </tr>
</table>

<table>
  <tr>
    <td><i class="u-bg-grey-base">　</i>u-bg-grey-base</td>
  </tr>
  <tr>
    <td><i class="u-bg-standard-gray">　</i>u-bg-standard-gray</td>
    <td><i class="u-bg-blue-gray">　</i>u-bg-blue-gray</td>
    <td><i class="u-bg-brown-gray">　</i>u-bg-brown-gray</td>
  </tr>
</table>

##### Gray-scale

<table>
  <tr>
    <td><i class="u-truewhite">●</i>u-truewhite</td>
    <td><i class="u-white">●</i>u-white</td>
  </tr>
  <tr>
    <td><i class="u-lightsilver">●</i>u-lightsilver</td>
    <td><i class="u-silver">●</i>u-silver</td>
    <td><i class="u-darksilver">●</i>u-darksilver</td>
  </tr>
  <tr>
    <td><i class="u-lightgrey">●</i>u-lightgrey</td>
    <td><i class="u-grey">●</i>u-grey</td>
    <td><i class="u-darkgrey">●</i>u-darkgrey</td>
  </tr>
  <tr>
    <td><i class="u-lightcharcoal">●</i>u-lightcharcoal</td>
    <td><i class="u-charcoal">●</i>u-charcoal</td>
    <td><i class="u-darkcharcoal">●</i>u-darkcharcoal</td>
  </tr>
  <tr>
    <td><i class="u-black">●</i>u-black</td>
    <td><i class="u-trueblack">●</i>u-trueblack</td>
  </tr>
</table>

<table>
  <tr>
    <td><i class="u-bg-truewhite">　</i>u-bg-truewhite</td>
    <td><i class="u-bg-white">　</i>u-bg-white</td>
  </tr>
  <tr>
    <td><i class="u-bg-lightsilver">　</i>u-bg-lightsilver</td>
    <td><i class="u-bg-silver">　</i>u-bg-silver</td>
    <td><i class="u-bg-darksilver">　</i>u-bg-darksilver</td>
  </tr>
  <tr>
    <td><i class="u-bg-lightgrey">　</i>u-bg-lightgrey</td>
    <td><i class="u-bg-grey">　</i>u-bg-grey</td>
    <td><i class="u-bg-darkgrey">　</i>u-bg-darkgrey</td>
  </tr>
  <tr>
    <td><i class="u-bg-lightcharcoal">　</i>u-bg-lightcharcoal</td>
    <td><i class="u-bg-charcoal">　</i>u-bg-charcoal</td>
    <td><i class="u-bg-darkcharcoal">　</i>u-bg-darkcharcoal</td>
  </tr>
  <tr>
    <td><i class="u-bg-black">　</i>u-bg-black</td>
    <td><i class="u-bg-trueblack">　</i>u-bg-trueblack</td>
  </tr>
</table>

### Web safe colors

WEB セーフカラーをユーティリティクラスで指定可能です。

#### color プロパティ

color プロパティにて使用する場合は、`.u-{color-name}` クラスを付与してください。

<table>
  <tr>
    <td><i class="u-LightPink">●</i>u-LightPink</td>
    <td><i class="u-Pink">●</i>u-Pink</td>
    <td><i class="u-Crimson">●</i>u-Crimson</td>
  </tr>
  <tr>
    <td><i class="u-LavenderBlush">●</i>u-LavenderBlush</td>
    <td><i class="u-PaleVioletRed">●</i>u-PaleVioletRed</td>
    <td><i class="u-HotPink">●</i>u-HotPink</td>
  </tr>
  <tr>
    <td><i class="u-DeepPink">●</i>u-DeepPink</td>
    <td><i class="u-MediumVioletRed">●</i>u-MediumVioletRed</td>
    <td><i class="u-Orchid">●</i>u-Orchid</td>
  </tr>
  <tr>
    <td><i class="u-Thistle">●</i>u-Thistle</td>
    <td><i class="u-Plum">●</i>u-Plum</td>
    <td><i class="u-Violet">●</i>u-Violet</td>
  </tr>
  <tr>
    <td><i class="u-Magenta">●</i>u-Magenta</td>
    <td><i class="u-Fuchsia">●</i>u-Fuchsia</td>
    <td><i class="u-DarkMagenta">●</i>u-DarkMagenta</td>
  </tr>
  <tr>
    <td><i class="u-Purple">●</i>u-Purple</td>
    <td><i class="u-MediumOrchid">●</i>u-MediumOrchid</td>
    <td><i class="u-DarkViolet">●</i>u-DarkViolet</td>
  </tr>
  <tr>
    <td><i class="u-DarkOrchid">●</i>u-DarkOrchid</td>
    <td><i class="u-Indigo">●</i>u-Indigo</td>
    <td><i class="u-BlueViolet">●</i>u-BlueViolet</td>
  </tr>
  <tr>
    <td><i class="u-MediumPurple">●</i>u-MediumPurple</td>
    <td><i class="u-MediumSlateBlue">●</i>u-MediumSlateBlue</td>
    <td><i class="u-SlateBlue">●</i>u-SlateBlue</td>
  </tr>
  <tr>
    <td><i class="u-DarkSlateBlue">●</i>u-DarkSlateBlue</td>
    <td><i class="u-Lavender">●</i>u-Lavender</td>
    <td><i class="u-GhostWhite">●</i>u-GhostWhite</td>
  </tr>
  <tr>
    <td><i class="u-Blue">●</i>u-Blue</td>
    <td><i class="u-MediumBlue">●</i>u-MediumBlue</td>
    <td><i class="u-MidnightBlue">●</i>u-MidnightBlue</td>
  </tr>
  <tr>
    <td><i class="u-DarkBlue">●</i>u-DarkBlue</td>
    <td><i class="u-Navy">●</i>u-Navy</td>
    <td><i class="u-RoyalBlue">●</i>u-RoyalBlue</td>
  </tr>
  <tr>
    <td><i class="u-CornflowerBlue">●</i>u-CornflowerBlue</td>
    <td><i class="u-LightSteelBlue">●</i>u-LightSteelBlue</td>
    <td><i class="u-LightSlateGray">●</i>u-LightSlateGray</td>
  </tr>
  <tr>
    <td><i class="u-SlateGray">●</i>u-SlateGray</td>
    <td><i class="u-DodgerBlue">●</i>u-DodgerBlue</td>
    <td><i class="u-AliceBlue">●</i>u-AliceBlue</td>
  </tr>
  <tr>
    <td><i class="u-SteelBlue">●</i>u-SteelBlue</td>
    <td><i class="u-LightSkyBlue">●</i>u-LightSkyBlue</td>
    <td><i class="u-SkyBlue">●</i>u-SkyBlue</td>
  </tr>
  <tr>
    <td><i class="u-DeepSkyBlue">●</i>u-DeepSkyBlue</td>
    <td><i class="u-LightBlue">●</i>u-LightBlue</td>
    <td><i class="u-PowderBlue">●</i>u-PowderBlue</td>
  </tr>
  <tr>
    <td><i class="u-CadetBlue">●</i>u-CadetBlue</td>
    <td><i class="u-Azure">●</i>u-Azure</td>
    <td><i class="u-LightCyan">●</i>u-LightCyan</td>
  </tr>
  <tr>
    <td><i class="u-PaleTurquoise">●</i>u-PaleTurquoise</td>
    <td><i class="u-Cyan">●</i>u-Cyan</td>
    <td><i class="u-Aqua">●</i>u-Aqua</td>
  </tr>
  <tr>
    <td><i class="u-DarkTurquoise">●</i>u-DarkTurquoise</td>
    <td><i class="u-DarkSlateGray">●</i>u-DarkSlateGray</td>
    <td><i class="u-DarkCyan">●</i>u-DarkCyan</td>
  </tr>
  <tr>
    <td><i class="u-Teal">●</i>u-Teal</td>
    <td><i class="u-MediumTurquoise">●</i>u-MediumTurquoise</td>
    <td><i class="u-LightSeaGreen">●</i>u-LightSeaGreen</td>
  </tr>
  <tr>
    <td><i class="u-Turquoise">●</i>u-Turquoise</td>
    <td><i class="u-Aquamarine">●</i>u-Aquamarine</td>
    <td><i class="u-MediumAquamarine">●</i>u-MediumAquamarine</td>
  </tr>
  <tr>
    <td><i class="u-MediumSpringGreen">●</i>u-MediumSpringGreen</td>
    <td><i class="u-MintCream">●</i>u-MintCream</td>
    <td><i class="u-SpringGreen">●</i>u-SpringGreen</td>
  </tr>
  <tr>
    <td><i class="u-MediumSeaGreen">●</i>u-MediumSeaGreen</td>
    <td><i class="u-SeaGreen">●</i>u-SeaGreen</td>
    <td><i class="u-Honeydew">●</i>u-Honeydew</td>
  </tr>
  <tr>
    <td><i class="u-LightGreen">●</i>u-LightGreen</td>
    <td><i class="u-PaleGreen">●</i>u-PaleGreen</td>
    <td><i class="u-DarkSeaGreen">●</i>u-DarkSeaGreen</td>
  </tr>
  <tr>
    <td><i class="u-LimeGreen">●</i>u-LimeGreen</td>
    <td><i class="u-Lime">●</i>u-Lime</td>
    <td><i class="u-ForestGreen">●</i>u-ForestGreen</td>
  </tr>
  <tr>
    <td><i class="u-Green">●</i>u-Green</td>
    <td><i class="u-DarkGreen">●</i>u-DarkGreen</td>
    <td><i class="u-Chartreuse">●</i>u-Chartreuse</td>
  </tr>
  <tr>
    <td><i class="u-LawnGreen">●</i>u-LawnGreen</td>
    <td><i class="u-GreenYellow">●</i>u-GreenYellow</td>
    <td><i class="u-DarkOliveGreen">●</i>u-DarkOliveGreen</td>
  </tr>
  <tr>
    <td><i class="u-YellowGreen">●</i>u-YellowGreen</td>
    <td><i class="u-OliveDrab">●</i>u-OliveDrab</td>
    <td><i class="u-Beige">●</i>u-Beige</td>
  </tr>
  <tr>
    <td><i class="u-LightGoldenrodYellow">●</i>u-LightGoldenrodYellow</td>
    <td><i class="u-Ivory">●</i>u-Ivory</td>
    <td><i class="u-LightYellow">●</i>u-LightYellow</td>
  </tr>
  <tr>
    <td><i class="u-Yellow">●</i>u-Yellow</td>
    <td><i class="u-Olive">●</i>u-Olive</td>
    <td><i class="u-DarkKhaki">●</i>u-DarkKhaki</td>
  </tr>
  <tr>
    <td><i class="u-LemonChiffon">●</i>u-LemonChiffon</td>
    <td><i class="u-PaleGoldenrod">●</i>u-PaleGoldenrod</td>
    <td><i class="u-Khaki">●</i>u-Khaki</td>
  </tr>
  <tr>
    <td><i class="u-Gold">●</i>u-Gold</td>
    <td><i class="u-Cornsilk">●</i>u-Cornsilk</td>
    <td><i class="u-Goldenrod">●</i>u-Goldenrod</td>
  </tr>
  <tr>
    <td><i class="u-DarkGoldenrod">●</i>u-DarkGoldenrod</td>
    <td><i class="u-FloralWhite">●</i>u-FloralWhite</td>
    <td><i class="u-OldLace">●</i>u-OldLace</td>
  </tr>
  <tr>
    <td><i class="u-Wheat">●</i>u-Wheat</td>
    <td><i class="u-Moccasin">●</i>u-Moccasin</td>
    <td><i class="u-Orange">●</i>u-Orange</td>
  </tr>
  <tr>
    <td><i class="u-PapayaWhip">●</i>u-PapayaWhip</td>
    <td><i class="u-BlanchedAlmond">●</i>u-BlanchedAlmond</td>
    <td><i class="u-NavajoWhite">●</i>u-NavajoWhite</td>
  </tr>
  <tr>
    <td><i class="u-AntiqueWhite">●</i>u-AntiqueWhite</td>
    <td><i class="u-Tan">●</i>u-Tan</td>
    <td><i class="u-BurlyWood">●</i>u-BurlyWood</td>
  </tr>
  <tr>
    <td><i class="u-Bisque">●</i>u-Bisque</td>
    <td><i class="u-DarkOrange">●</i>u-DarkOrange</td>
    <td><i class="u-Linen">●</i>u-Linen</td>
  </tr>
  <tr>
    <td><i class="u-Peru">●</i>u-Peru</td>
    <td><i class="u-PeachPuff">●</i>u-PeachPuff</td>
    <td><i class="u-SandyBrown">●</i>u-SandyBrown</td>
  </tr>
  <tr>
    <td><i class="u-Chocolate">●</i>u-Chocolate</td>
    <td><i class="u-SaddleBrown">●</i>u-SaddleBrown</td>
    <td><i class="u-Seashell">●</i>u-Seashell</td>
  </tr>
  <tr>
    <td><i class="u-Sienna">●</i>u-Sienna</td>
    <td><i class="u-LightSalmon">●</i>u-LightSalmon</td>
    <td><i class="u-Coral">●</i>u-Coral</td>
  </tr>
  <tr>
    <td><i class="u-OrangeRed">●</i>u-OrangeRed</td>
    <td><i class="u-DarkSalmon">●</i>u-DarkSalmon</td>
    <td><i class="u-Tomato">●</i>u-Tomato</td>
  </tr>
  <tr>
    <td><i class="u-MistyRose">●</i>u-MistyRose</td>
    <td><i class="u-Salmon">●</i>u-Salmon</td>
    <td><i class="u-Snow">●</i>u-Snow</td>
  </tr>
  <tr>
    <td><i class="u-LightCoral">●</i>u-LightCoral</td>
    <td><i class="u-RosyBrown">●</i>u-RosyBrown</td>
    <td><i class="u-IndianRed">●</i>u-IndianRed</td>
  </tr>
  <tr>
    <td><i class="u-Red">●</i>u-Red</td>
    <td><i class="u-Brown">●</i>u-Brown</td>
    <td><i class="u-FireBrick">●</i>u-FireBrick</td>
  </tr>
  <tr>
    <td><i class="u-DarkRed">●</i>u-DarkRed</td>
    <td><i class="u-Maroon">●</i>u-Maroon</td>
    <td><i class="u-White">●</i>u-White</td>
  </tr>
  <tr>
    <td><i class="u-WhiteSmoke">●</i>u-WhiteSmoke</td>
    <td><i class="u-Gainsboro">●</i>u-Gainsboro</td>
    <td><i class="u-LightGrey">●</i>u-LightGrey</td>
  </tr>
  <tr>
    <td><i class="u-Silver">●</i>u-Silver</td>
    <td><i class="u-DarkGray">●</i>u-DarkGray</td>
    <td><i class="u-Gray">●</i>u-Gray</td>
  </tr>
  <tr>
    <td><i class="u-DimGray">●</i>u-DimGray</td>
    <td><i class="u-Black">●</i>u-Black</td>
  </tr>
</table>

#### background-color プロパティ

background-color プロパティにて使用する場合は、`.u-bg-{color-name}` クラスを付与してください。

<table>
  <tr>
    <td><i class="u-bg-LightPink">　</i>u-bg-LightPink</td>
    <td><i class="u-bg-Pink">　</i>u-bg-Pink</td>
    <td><i class="u-bg-Crimson">　</i>u-bg-Crimson</td>
  </tr>
  <tr>
    <td><i class="u-bg-LavenderBlush">　</i>u-bg-LavenderBlush</td>
    <td><i class="u-bg-PaleVioletRed">　</i>u-bg-PaleVioletRed</td>
    <td><i class="u-bg-HotPink">　</i>u-bg-HotPink</td>
  </tr>
  <tr>
    <td><i class="u-bg-DeepPink">　</i>u-bg-DeepPink</td>
    <td><i class="u-bg-MediumVioletRed">　</i>u-bg-MediumVioletRed</td>
    <td><i class="u-bg-Orchid">　</i>u-bg-Orchid</td>
  </tr>
  <tr>
    <td><i class="u-bg-Thistle">　</i>u-bg-Thistle</td>
    <td><i class="u-bg-Plum">　</i>u-bg-Plum</td>
    <td><i class="u-bg-Violet">　</i>u-bg-Violet</td>
  </tr>
  <tr>
    <td><i class="u-bg-Magenta">　</i>u-bg-Magenta</td>
    <td><i class="u-bg-Fuchsia">　</i>u-bg-Fuchsia</td>
    <td><i class="u-bg-DarkMagenta">　</i>u-bg-DarkMagenta</td>
  </tr>
  <tr>
    <td><i class="u-bg-Purple">　</i>u-bg-Purple</td>
    <td><i class="u-bg-MediumOrchid">　</i>u-bg-MediumOrchid</td>
    <td><i class="u-bg-DarkViolet">　</i>u-bg-DarkViolet</td>
  </tr>
  <tr>
    <td><i class="u-bg-DarkOrchid">　</i>u-bg-DarkOrchid</td>
    <td><i class="u-bg-Indigo">　</i>u-bg-Indigo</td>
    <td><i class="u-bg-BlueViolet">　</i>u-bg-BlueViolet</td>
  </tr>
  <tr>
    <td><i class="u-bg-MediumPurple">　</i>u-bg-MediumPurple</td>
    <td><i class="u-bg-MediumSlateBlue">　</i>u-bg-MediumSlateBlue</td>
    <td><i class="u-bg-SlateBlue">　</i>u-bg-SlateBlue</td>
  </tr>
  <tr>
    <td><i class="u-bg-DarkSlateBlue">　</i>u-bg-DarkSlateBlue</td>
    <td><i class="u-bg-Lavender">　</i>u-bg-Lavender</td>
    <td><i class="u-bg-GhostWhite">　</i>u-bg-GhostWhite</td>
  </tr>
  <tr>
    <td><i class="u-bg-Blue">　</i>u-bg-Blue</td>
    <td><i class="u-bg-MediumBlue">　</i>u-bg-MediumBlue</td>
    <td><i class="u-bg-MidnightBlue">　</i>u-bg-MidnightBlue</td>
  </tr>
  <tr>
    <td><i class="u-bg-DarkBlue">　</i>u-bg-DarkBlue</td>
    <td><i class="u-bg-Navy">　</i>u-bg-Navy</td>
    <td><i class="u-bg-RoyalBlue">　</i>u-bg-RoyalBlue</td>
  </tr>
  <tr>
    <td><i class="u-bg-CornflowerBlue">　</i>u-bg-CornflowerBlue</td>
    <td><i class="u-bg-LightSteelBlue">　</i>u-bg-LightSteelBlue</td>
    <td><i class="u-bg-LightSlateGray">　</i>u-bg-LightSlateGray</td>
  </tr>
  <tr>
    <td><i class="u-bg-SlateGray">　</i>u-bg-SlateGray</td>
    <td><i class="u-bg-DodgerBlue">　</i>u-bg-DodgerBlue</td>
    <td><i class="u-bg-AliceBlue">　</i>u-bg-AliceBlue</td>
  </tr>
  <tr>
    <td><i class="u-bg-SteelBlue">　</i>u-bg-SteelBlue</td>
    <td><i class="u-bg-LightSkyBlue">　</i>u-bg-LightSkyBlue</td>
    <td><i class="u-bg-SkyBlue">　</i>u-bg-SkyBlue</td>
  </tr>
  <tr>
    <td><i class="u-bg-DeepSkyBlue">　</i>u-bg-DeepSkyBlue</td>
    <td><i class="u-bg-LightBlue">　</i>u-bg-LightBlue</td>
    <td><i class="u-bg-PowderBlue">　</i>u-bg-PowderBlue</td>
  </tr>
  <tr>
    <td><i class="u-bg-CadetBlue">　</i>u-bg-CadetBlue</td>
    <td><i class="u-bg-Azure">　</i>u-bg-Azure</td>
    <td><i class="u-bg-LightCyan">　</i>u-bg-LightCyan</td>
  </tr>
  <tr>
    <td><i class="u-bg-PaleTurquoise">　</i>u-bg-PaleTurquoise</td>
    <td><i class="u-bg-Cyan">　</i>u-bg-Cyan</td>
    <td><i class="u-bg-Aqua">　</i>u-bg-Aqua</td>
  </tr>
  <tr>
    <td><i class="u-bg-DarkTurquoise">　</i>u-bg-DarkTurquoise</td>
    <td><i class="u-bg-DarkSlateGray">　</i>u-bg-DarkSlateGray</td>
    <td><i class="u-bg-DarkCyan">　</i>u-bg-DarkCyan</td>
  </tr>
  <tr>
    <td><i class="u-bg-Teal">　</i>u-bg-Teal</td>
    <td><i class="u-bg-MediumTurquoise">　</i>u-bg-MediumTurquoise</td>
    <td><i class="u-bg-LightSeaGreen">　</i>u-bg-LightSeaGreen</td>
  </tr>
  <tr>
    <td><i class="u-bg-Turquoise">　</i>u-bg-Turquoise</td>
    <td><i class="u-bg-Aquamarine">　</i>u-bg-Aquamarine</td>
    <td><i class="u-bg-MediumAquamarine">　</i>u-bg-MediumAquamarine</td>
  </tr>
  <tr>
    <td><i class="u-bg-MediumSpringGreen">　</i>u-bg-MediumSpringGreen</td>
    <td><i class="u-bg-MintCream">　</i>u-bg-MintCream</td>
    <td><i class="u-bg-SpringGreen">　</i>u-bg-SpringGreen</td>
  </tr>
  <tr>
    <td><i class="u-bg-MediumSeaGreen">　</i>u-bg-MediumSeaGreen</td>
    <td><i class="u-bg-SeaGreen">　</i>u-bg-SeaGreen</td>
    <td><i class="u-bg-Honeydew">　</i>u-bg-Honeydew</td>
  </tr>
  <tr>
    <td><i class="u-bg-LightGreen">　</i>u-bg-LightGreen</td>
    <td><i class="u-bg-PaleGreen">　</i>u-bg-PaleGreen</td>
    <td><i class="u-bg-DarkSeaGreen">　</i>u-bg-DarkSeaGreen</td>
  </tr>
  <tr>
    <td><i class="u-bg-LimeGreen">　</i>u-bg-LimeGreen</td>
    <td><i class="u-bg-Lime">　</i>u-bg-Lime</td>
    <td><i class="u-bg-ForestGreen">　</i>u-bg-ForestGreen</td>
  </tr>
  <tr>
    <td><i class="u-bg-Green">　</i>u-bg-Green</td>
    <td><i class="u-bg-DarkGreen">　</i>u-bg-DarkGreen</td>
    <td><i class="u-bg-Chartreuse">　</i>u-bg-Chartreuse</td>
  </tr>
  <tr>
    <td><i class="u-bg-LawnGreen">　</i>u-bg-LawnGreen</td>
    <td><i class="u-bg-GreenYellow">　</i>u-bg-GreenYellow</td>
    <td><i class="u-bg-DarkOliveGreen">　</i>u-bg-DarkOliveGreen</td>
  </tr>
  <tr>
    <td><i class="u-bg-YellowGreen">　</i>u-bg-YellowGreen</td>
    <td><i class="u-bg-OliveDrab">　</i>u-bg-OliveDrab</td>
    <td><i class="u-bg-Beige">　</i>u-bg-Beige</td>
  </tr>
  <tr>
    <td><i class="u-bg-LightGoldenrodYellow">　</i>u-bg-LightGoldenrodYellow</td>
    <td><i class="u-bg-Ivory">　</i>u-bg-Ivory</td>
    <td><i class="u-bg-LightYellow">　</i>u-bg-LightYellow</td>
  </tr>
  <tr>
    <td><i class="u-bg-Yellow">　</i>u-bg-Yellow</td>
    <td><i class="u-bg-Olive">　</i>u-bg-Olive</td>
    <td><i class="u-bg-DarkKhaki">　</i>u-bg-DarkKhaki</td>
  </tr>
  <tr>
    <td><i class="u-bg-LemonChiffon">　</i>u-bg-LemonChiffon</td>
    <td><i class="u-bg-PaleGoldenrod">　</i>u-bg-PaleGoldenrod</td>
    <td><i class="u-bg-Khaki">　</i>u-bg-Khaki</td>
  </tr>
  <tr>
    <td><i class="u-bg-Gold">　</i>u-bg-Gold</td>
    <td><i class="u-bg-Cornsilk">　</i>u-bg-Cornsilk</td>
    <td><i class="u-bg-Goldenrod">　</i>u-bg-Goldenrod</td>
  </tr>
  <tr>
    <td><i class="u-bg-DarkGoldenrod">　</i>u-bg-DarkGoldenrod</td>
    <td><i class="u-bg-FloralWhite">　</i>u-bg-FloralWhite</td>
    <td><i class="u-bg-OldLace">　</i>u-bg-OldLace</td>
  </tr>
  <tr>
    <td><i class="u-bg-Wheat">　</i>u-bg-Wheat</td>
    <td><i class="u-bg-Moccasin">　</i>u-bg-Moccasin</td>
    <td><i class="u-bg-Orange">　</i>u-bg-Orange</td>
  </tr>
  <tr>
    <td><i class="u-bg-PapayaWhip">　</i>u-bg-PapayaWhip</td>
    <td><i class="u-bg-BlanchedAlmond">　</i>u-bg-BlanchedAlmond</td>
    <td><i class="u-bg-NavajoWhite">　</i>u-bg-NavajoWhite</td>
  </tr>
  <tr>
    <td><i class="u-bg-AntiqueWhite">　</i>u-bg-AntiqueWhite</td>
    <td><i class="u-bg-Tan">　</i>u-bg-Tan</td>
    <td><i class="u-bg-BurlyWood">　</i>u-bg-BurlyWood</td>
  </tr>
  <tr>
    <td><i class="u-bg-Bisque">　</i>u-bg-Bisque</td>
    <td><i class="u-bg-DarkOrange">　</i>u-bg-DarkOrange</td>
    <td><i class="u-bg-Linen">　</i>u-bg-Linen</td>
  </tr>
  <tr>
    <td><i class="u-bg-Peru">　</i>u-bg-Peru</td>
    <td><i class="u-bg-PeachPuff">　</i>u-bg-PeachPuff</td>
    <td><i class="u-bg-SandyBrown">　</i>u-bg-SandyBrown</td>
  </tr>
  <tr>
    <td><i class="u-bg-Chocolate">　</i>u-bg-Chocolate</td>
    <td><i class="u-bg-SaddleBrown">　</i>u-bg-SaddleBrown</td>
    <td><i class="u-bg-Seashell">　</i>u-bg-Seashell</td>
  </tr>
  <tr>
    <td><i class="u-bg-Sienna">　</i>u-bg-Sienna</td>
    <td><i class="u-bg-LightSalmon">　</i>u-bg-LightSalmon</td>
    <td><i class="u-bg-Coral">　</i>u-bg-Coral</td>
  </tr>
  <tr>
    <td><i class="u-bg-OrangeRed">　</i>u-bg-OrangeRed</td>
    <td><i class="u-bg-DarkSalmon">　</i>u-bg-DarkSalmon</td>
    <td><i class="u-bg-Tomato">　</i>u-bg-Tomato</td>
  </tr>
  <tr>
    <td><i class="u-bg-MistyRose">　</i>u-bg-MistyRose</td>
    <td><i class="u-bg-Salmon">　</i>u-bg-Salmon</td>
    <td><i class="u-bg-Snow">　</i>u-bg-Snow</td>
  </tr>
  <tr>
    <td><i class="u-bg-LightCoral">　</i>u-bg-LightCoral</td>
    <td><i class="u-bg-RosyBrown">　</i>u-bg-RosyBrown</td>
    <td><i class="u-bg-IndianRed">　</i>u-bg-IndianRed</td>
  </tr>
  <tr>
    <td><i class="u-bg-Red">　</i>u-bg-Red</td>
    <td><i class="u-bg-Brown">　</i>u-bg-Brown</td>
    <td><i class="u-bg-FireBrick">　</i>u-bg-FireBrick</td>
  </tr>
  <tr>
    <td><i class="u-bg-DarkRed">　</i>u-bg-DarkRed</td>
    <td><i class="u-bg-Maroon">　</i>u-bg-Maroon</td>
    <td><i class="u-bg-White">　</i>u-bg-White</td>
  </tr>
  <tr>
    <td><i class="u-bg-WhiteSmoke">　</i>u-bg-WhiteSmoke</td>
    <td><i class="u-bg-Gainsboro">　</i>u-bg-Gainsboro</td>
    <td><i class="u-bg-LightGrey">　</i>u-bg-LightGrey</td>
  </tr>
  <tr>
    <td><i class="u-bg-Silver">　</i>u-bg-Silver</td>
    <td><i class="u-bg-DarkGray">　</i>u-bg-DarkGray</td>
    <td><i class="u-bg-Gray">　</i>u-bg-Gray</td>
  </tr>
  <tr>
    <td><i class="u-bg-DimGray">　</i>u-bg-DimGray</td>
    <td><i class="u-bg-Black">　</i>u-bg-Black</td>
  </tr>
</table>

### オプショナル

**.u-transparent**

`color:` プロパティが `transparent` になります。

```html
<button class="u-transparent">Click Me</button>
```
```jade
//- Pug
button(u-transparent) Click Me
```

**.u-bg-transparent**

`background-color:` プロパティが `transparent` になります。

```html
<button class="u-bg-transparent">Click Me</button>
```
```jade
//- Pug
button(u-bg-transparent) Click Me
```

*/

// --------------------------------------------------
// User defined colors
// --------------------------------------------------

.u {
  &-cs01          {color:          $cs01 !important;}
  &-cs01Lt        {color:        $cs01Lt !important;}
  &-cs01Dk        {color:        $cs01Dk !important;}
  &-cs01St        {color:        $cs01St !important;}
  &-cs01Ds        {color:        $cs01Ds !important;}
  &-cs02          {color:          $cs02 !important;}
  &-cs02Lt        {color:        $cs02Lt !important;}
  &-cs02Dk        {color:        $cs02Dk !important;}
  &-cs02St        {color:        $cs02St !important;}
  &-cs02Ds        {color:        $cs02Ds !important;}
  &-cs03          {color:          $cs03 !important;}
  &-cs03Lt        {color:        $cs03Lt !important;}
  &-cs03Dk        {color:        $cs03Dk !important;}
  &-cs03St        {color:        $cs03St !important;}
  &-cs03Ds        {color:        $cs03Ds !important;}
  &-cs04          {color:          $cs04 !important;}
  &-cs04Lt        {color:        $cs04Lt !important;}
  &-cs04Dk        {color:        $cs04Dk !important;}
  &-cs04St        {color:        $cs04St !important;}
  &-cs04Ds        {color:        $cs04Ds !important;}
  &-base          {color:          $base !important;}
  &-baseLt        {color:        $baseLt !important;}
  &-baseDk        {color:        $baseDk !important;}
  &-baseSt        {color:        $baseSt !important;}
  &-baseDs        {color:        $baseDs !important;}
  &-main          {color:          $main !important;}
  &-mainLt        {color:        $mainLt !important;}
  &-mainDk        {color:        $mainDk !important;}
  &-mainSt        {color:        $mainSt !important;}
  &-mainDs        {color:        $mainDs !important;}
  &-accent        {color:        $accent !important;}
  &-accentLt      {color:      $accentLt !important;}
  &-accentDk      {color:      $accentDk !important;}
  &-accentSt      {color:      $accentSt !important;}
  &-accentDs      {color:      $accentDs !important;}
  &-standard-gray {color: $standard-gray !important;}
  &-blue-gray     {color:     $blue-gray !important;}
  &-brown-gray    {color:    $brown-gray !important;}
  &-grey-base     {color:     $grey-base !important;}
  &-truewhite     {color:     $truewhite !important;}
  &-white         {color:         $white !important;}
  &-lightsilver   {color:   $lightsilver !important;}
  &-silver        {color:        $silver !important;}
  &-darksilver    {color:    $darksilver !important;}
  &-lightgrey     {color:     $lightgrey !important;}
  &-grey          {color:          $grey !important;}
  &-darkgrey      {color:      $darkgrey !important;}
  &-lightcharcoal {color: $lightcharcoal !important;}
  &-charcoal      {color:      $charcoal !important;}
  &-darkcharcoal  {color:  $darkcharcoal !important;}
  &-black         {color:         $black !important;}
  &-trueblack     {color:     $trueblack !important;}
}

.u {
  &-bg-cs01          {background-color:          $cs01 !important;}
  &-bg-cs01Lt        {background-color:        $cs01Lt !important;}
  &-bg-cs01Dk        {background-color:        $cs01Dk !important;}
  &-bg-cs01St        {background-color:        $cs01St !important;}
  &-bg-cs01Ds        {background-color:        $cs01Ds !important;}
  &-bg-cs02          {background-color:          $cs02 !important;}
  &-bg-cs02Lt        {background-color:        $cs02Lt !important;}
  &-bg-cs02Dk        {background-color:        $cs02Dk !important;}
  &-bg-cs02St        {background-color:        $cs02St !important;}
  &-bg-cs02Ds        {background-color:        $cs02Ds !important;}
  &-bg-cs03          {background-color:          $cs03 !important;}
  &-bg-cs03Lt        {background-color:        $cs03Lt !important;}
  &-bg-cs03Dk        {background-color:        $cs03Dk !important;}
  &-bg-cs03St        {background-color:        $cs03St !important;}
  &-bg-cs03Ds        {background-color:        $cs03Ds !important;}
  &-bg-cs04          {background-color:          $cs04 !important;}
  &-bg-cs04Lt        {background-color:        $cs04Lt !important;}
  &-bg-cs04Dk        {background-color:        $cs04Dk !important;}
  &-bg-cs04St        {background-color:        $cs04St !important;}
  &-bg-cs04Ds        {background-color:        $cs04Ds !important;}
  &-bg-base          {background-color:          $base !important;}
  &-bg-baseLt        {background-color:        $baseLt !important;}
  &-bg-baseDk        {background-color:        $baseDk !important;}
  &-bg-baseSt        {background-color:        $baseSt !important;}
  &-bg-baseDs        {background-color:        $baseDs !important;}
  &-bg-main          {background-color:          $main !important;}
  &-bg-mainLt        {background-color:        $mainLt !important;}
  &-bg-mainDk        {background-color:        $mainDk !important;}
  &-bg-mainSt        {background-color:        $mainSt !important;}
  &-bg-mainDs        {background-color:        $mainDs !important;}
  &-bg-accent        {background-color:        $accent !important;}
  &-bg-accentLt      {background-color:      $accentLt !important;}
  &-bg-accentDk      {background-color:      $accentDk !important;}
  &-bg-accentSt      {background-color:      $accentSt !important;}
  &-bg-accentDs      {background-color:      $accentDs !important;}
  &-bg-standard-gray {background-color: $standard-gray !important;}
  &-bg-blue-gray     {background-color:     $blue-gray !important;}
  &-bg-brown-gray    {background-color:    $brown-gray !important;}
  &-bg-grey-base     {background-color:     $grey-base !important;}
  &-bg-truewhite     {background-color:     $truewhite !important;}
  &-bg-white         {background-color:         $white !important;}
  &-bg-lightsilver   {background-color:   $lightsilver !important;}
  &-bg-silver        {background-color:        $silver !important;}
  &-bg-darksilver    {background-color:    $darksilver !important;}
  &-bg-lightgrey     {background-color:     $lightgrey !important;}
  &-bg-grey          {background-color:          $grey !important;}
  &-bg-darkgrey      {background-color:      $darkgrey !important;}
  &-bg-lightcharcoal {background-color: $lightcharcoal !important;}
  &-bg-charcoal      {background-color:      $charcoal !important;}
  &-bg-darkcharcoal  {background-color:  $darkcharcoal !important;}
  &-bg-black         {background-color:         $black !important;}
  &-bg-trueblack     {background-color:     $trueblack !important;}
}

// --------------------------------------------------
// web safe colors
// --------------------------------------------------

$u-colors: (
  -LightPink:            #FFB6C1,
  -Pink:                 #FFC0CB,
  -Crimson:              #DC143C,
  -LavenderBlush:        #FFF0F5,
  -PaleVioletRed:        #DB7093,
  -HotPink:              #FF69B4,
  -DeepPink:             #FF1493,
  -MediumVioletRed:      #C71585,
  -Orchid:               #DA70D6,
  -Thistle:              #D8BFD8,
  -Plum:                 #DDA0DD,
  -Violet:               #EE82EE,
  -Magenta:              #FF00FF,
  -Fuchsia:              #FF00FF,
  -DarkMagenta:          #8B008B,
  -Purple:               #800080,
  -MediumOrchid:         #BA55D3,
  -DarkViolet:           #9400D3,
  -DarkOrchid:           #9932CC,
  -Indigo:               #4B0082,
  -BlueViolet:           #8A2BE2,
  -MediumPurple:         #9370DB,
  -MediumSlateBlue:      #7B68EE,
  -SlateBlue:            #6A5ACD,
  -DarkSlateBlue:        #483D8B,
  -Lavender:             #E6E6FA,
  -GhostWhite:           #F8F8FF,
  -Blue:                 #0000FF,
  -MediumBlue:           #0000CD,
  -MidnightBlue:         #191970,
  -DarkBlue:             #00008B,
  -Navy:                 #000080,
  -RoyalBlue:            #4169E1,
  -CornflowerBlue:       #6495ED,
  -LightSteelBlue:       #B0C4DE,
  -LightSlateGray:       #778899,
  -SlateGray:            #708090,
  -DodgerBlue:           #1E90FF,
  -AliceBlue:            #F0F8FF,
  -SteelBlue:            #4682B4,
  -LightSkyBlue:         #87CEFA,
  -SkyBlue:              #87CEEB,
  -DeepSkyBlue:          #00BFFF,
  -LightBlue:            #ADD8E6,
  -PowderBlue:           #B0E0E6,
  -CadetBlue:            #5F9EA0,
  -Azure:                #F0FFFF,
  -LightCyan:            #E0FFFF,
  -PaleTurquoise:        #AFEEEE,
  -Cyan:                 #00FFFF,
  -Aqua:                 #00FFFF,
  -DarkTurquoise:        #00CED1,
  -DarkSlateGray:        #2F4F4F,
  -DarkCyan:             #008B8B,
  -Teal:                 #008080,
  -MediumTurquoise:      #48D1CC,
  -LightSeaGreen:        #20B2AA,
  -Turquoise:            #40E0D0,
  -Aquamarine:           #7FFFD4,
  -MediumAquamarine:     #66CDAA,
  -MediumSpringGreen:    #00FA9A,
  -MintCream:            #F5FFFA,
  -SpringGreen:          #00FF7F,
  -MediumSeaGreen:       #3CB371,
  -SeaGreen:             #2E8B57,
  -Honeydew:             #F0FFF0,
  -LightGreen:           #90EE90,
  -PaleGreen:            #98FB98,
  -DarkSeaGreen:         #8FBC8F,
  -LimeGreen:            #32CD32,
  -Lime:                 #00FF00,
  -ForestGreen:          #228B22,
  -Green:                #008000,
  -DarkGreen:            #006400,
  -Chartreuse:           #7FFF00,
  -LawnGreen:            #7CFC00,
  -GreenYellow:          #ADFF2F,
  -DarkOliveGreen:       #556B2F,
  -YellowGreen:          #9ACD32,
  -OliveDrab:            #6B8E23,
  -Beige:                #F5F5DC,
  -LightGoldenrodYellow: #FAFAD2,
  -Ivory:                #FFFFF0,
  -LightYellow:          #FFFFE0,
  -Yellow:               #FFFF00,
  -Olive:                #808000,
  -DarkKhaki:            #BDB76B,
  -LemonChiffon:         #FFFACD,
  -PaleGoldenrod:        #EEE8AA,
  -Khaki:                #F0E68C,
  -Gold:                 #FFD700,
  -Cornsilk:             #FFF8DC,
  -Goldenrod:            #DAA520,
  -DarkGoldenrod:        #B8860B,
  -FloralWhite:          #FFFAF0,
  -OldLace:              #FDF5E6,
  -Wheat:                #F5DEB3,
  -Moccasin:             #FFE4B5,
  -Orange:               #FFA500,
  -PapayaWhip:           #FFEFD5,
  -BlanchedAlmond:       #FFEBCD,
  -NavajoWhite:          #FFDEAD,
  -AntiqueWhite:         #FAEBD7,
  -Tan:                  #D2B48C,
  -BurlyWood:            #DEB887,
  -Bisque:               #FFE4C4,
  -DarkOrange:           #FF8C00,
  -Linen:                #FAF0E6,
  -Peru:                 #CD853F,
  -PeachPuff:            #FFDAB9,
  -SandyBrown:           #F4A460,
  -Chocolate:            #D2691E,
  -SaddleBrown:          #8B4513,
  -Seashell:             #FFF5EE,
  -Sienna:               #A0522D,
  -LightSalmon:          #FFA07A,
  -Coral:                #FF7F50,
  -OrangeRed:            #FF4500,
  -DarkSalmon:           #E9967A,
  -Tomato:               #FF6347,
  -MistyRose:            #FFE4E1,
  -Salmon:               #FA8072,
  -Snow:                 #FFFAFA,
  -LightCoral:           #F08080,
  -RosyBrown:            #BC8F8F,
  -IndianRed:            #CD5C5C,
  -Red:                  #FF0000,
  -Brown:                #A52A2A,
  -FireBrick:            #B22222,
  -DarkRed:              #8B0000,
  -Maroon:               #800000,
  -White:                #FFFFFF,
  -WhiteSmoke:           #F5F5F5,
  -Gainsboro:            #DCDCDC,
  -LightGrey:            #D3D3D3,
  -Silver:               #C0C0C0,
  -DarkGray:             #A9A9A9,
  -Gray:                 #808080,
  -DimGray:              #696969,
  -Black:                #000000,
);

@each $key, $color in $u-colors {
  .u#{$key} {
    color: $color;
  }
}

@each $key, $color in $u-colors {
  .u-bg#{$key} {
    background-color: $color;
  }
}

// --------------------------------------------------
// Optional
// --------------------------------------------------

.u {
  &-transparent {
    color: transparent !important;
  }
}

.u-bg {
  &-transparent {
    background-color: transparent !important;
  }

  &-grad {
    @include gradbg;
  }
}
