/*
---
name: Company
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Project
---
*/

.p-company {
  position: relative;
  z-index: 1;
  padding: 4em 0;

  &__inner {
    background: $truewhite;
    box-shadow: 0 1em 4em rgba($trueblack,.1);
    padding: 4em;
    @include flex(ce,ce);

    @include ltXS {
      width: calc( 100% - 4em );
      margin-left: 2em;
      margin-right: 2em;
      padding: 2em 4em;
    };
  }

  &__table {
    border-collapse: separate;
    border-spacing: 0 1em;

    tr {
      @include ltXS {
        display: block;
        width: 100%;
      };
    }

    th, td {
      text-align: left;
      padding: 0;
    }

    th {
      width: 20%;
      min-width: 10em;
      color: $main;
      padding-top: .5em;
      padding-bottom: .5em;

      @include ltXS {
        display: block;
        width: 100%;
      };
    }

    td {
      width: 80%;
      max-width: calc( 100% - 10em );
      border-left: 2px solid rgba($accentLt,.5);
      padding-left: 2em;
      color: $darkgrey;
      @include fs(14);

      @include ltXS {
        display: block;
        width: 100%;
        max-width: 100%;
      };

      b {
        font-weight: bold;
      }
    }
  }
}
