/*
---
name: Structure
category:
  - Structure
---

FLOCSS では次の3つのレイヤーと、 **Object** レイヤーの子レイヤーで構成されます。

> 1. Foundation - reset/normalize/base...
> 1. Layout - header/main/sidebar/footer...
> 1. Object
>   1. Component - grid/button/form/media...
>   1. Project - articles/ranking/promo...
>   1. Utility - clearfix/display/margin...

レイヤーに関しては [Layer](../Documentation/CSS-Architecture/Layer/index.html) のドキュメントを参照ください。

### ファイル・ディレクトリ構成

基本原則のレイヤー構成に従い、下記のような構成を前提とします。

```
├── foundation
│			├── _base.scss
│			└── _reset.scss
├── layout
│			├── _footer.scss
│			├── _header.scss
│			├── _main.scss
│			└── _sidebar.scss
├── object
│			├── component
│			│			├── _button.scss
│			│			├── _dialog.scss
│			│			├── _grid.scss
│			│			└── _media.scss
│			├── project
│			│			├── _articles.scss
│			│			├── _comments.scss
│			│			├── _gallery.scss
│			│			└── _profile.scss
│			└── utility
│							├── _align.scss
│							├── _clearfix.scss
│							├── _margin.scss
│							├── _position.scss
│							├── _size.scss
│							└── _text.scss
└── theme.scss
```

### SCSS ファイルの統括管理

`theme.scss` では、パーシャル SCSS ファイルのインポートを統括して行います。各パーシャルファイル内でのインポートはファイルの所在の追跡が困難になるため原則禁止とします。また、変数および関数の宣言ファイルやカスケーディングの都合上、 **レイヤーの序列を前後させてはいけません。**

ただし、序列を想定したカスケーディングを行わない **Object** レイヤー内のモジュール類は、理論上カスケーディングが同等のレベルであるため、これらのモジュールの読み込み序列については許容します。sass の Glob を使用している場合は Object レイヤーのみ <code>@import "object/component/&#042;&#042;/&#042;";</code> の様に統括して読み込みを行うことが許容されます。ただしこの場合、 **component** 、**project** 、**utility** の各レイヤーの序列は変更してはなりません。そのため <code>@import "object/&#042;&#042;/&#042;";</code> といったインポートの統括は **禁止** とします。

カスケーディングに関しては、 [Cascading](../Documentation/CSS-Architecture/Cascading/index.html) のドキュメントを参照ください。

*/

// ========================================
// Include third-party styles.
// ========================================

// @import "//fonts.googleapis.com/earlyaccess/mplus1p.css";
@import "//fonts.googleapis.com/earlyaccess/roundedmplus1c.css";
// @import "//fonts.googleapis.com/earlyaccess/notosansjapanese.css";
@import "//fonts.googleapis.com/earlyaccess/sawarabimincho.css";
@import "//fonts.googleapis.com/css?family=EB+Garamond:600,700,800";
@import "//fonts.googleapis.com/css?family=Lato:400,700,900";

// ========================================
// Foundation
// ========================================

@import "foundation/colors";
@import "foundation/typography";
@import "foundation/normalize";
@import "foundation/media-queries";
@import "foundation/user-agent";
@import "foundation/mixins";
@import "foundation/base";
@import "foundation/fontawesome";
@import "foundation/slick";
@include gtMD {
	@import "foundation/animate";
};
@import "foundation/mplus-1p";

// ========================================
// Layout
// ========================================

@import "layout/header";
@import "layout/footer";
@import "layout/navigation";
@import "layout/main";
@import "layout/sidebar";

// ========================================
// Object
// ========================================

@import "object/component/_breadcrumb.scss";
@import "object/component/_button.scss";
@import "object/component/_carousel.scss";
@import "object/component/_collapse.scss";
@import "object/component/_container.scss";
@import "object/component/_content.scss";
@import "object/component/_copyright.scss";
@import "object/component/_dialog.scss";
@import "object/component/_editor-content.scss";
@import "object/component/_grid.scss";
@import "object/component/_header-mirror.scss";
@import "object/component/_hero-image.scss";
@import "object/component/_logo.scss";
@import "object/component/_media.scss";
@import "object/component/_nav-target.scss";
@import "object/component/_nav-trigger.scss";
@import "object/component/_navbelow.scss";
@import "object/component/_over.scss";
@import "object/component/_page-header.scss";
@import "object/component/_pagetop-button.scss";
@import "object/component/_post-archive.scss";
@import "object/component/_scroll-box.scss";
@import "object/component/_slick.scss";
@import "object/component/_tab.scss";
@import "object/project/_404.scss";
@import "object/project/_about.scss";
@import "object/project/_archive.scss";
@import "object/project/_article.scss";
@import "object/project/_closing.scss";
@import "object/project/_company.scss";
@import "object/project/_contact.scss";
@import "object/project/_faq-collapse.scss";
@import "object/project/_floating-box.scss";
@import "object/project/_fluid.scss";
@import "object/project/_gallery.scss";
@import "object/project/_infotable.scss";
@import "object/project/_journals.scss";
@import "object/project/_keyvisual.scss";
@import "object/project/_message.scss";
@import "object/project/_page-list.scss";
@import "object/project/_page-navi.scss";
@import "object/project/_product.scss";
@import "object/project/_profile.scss";
@import "object/project/_recruit-nav.scss";
@import "object/project/_recruit.scss";
@import "object/project/_relative.scss";
@import "object/project/_sitemaps.scss";
@import "object/project/_tagcloud.scss";
@import "object/utility/_align.scss";
@import "object/utility/_animate_mod.scss";
@import "object/utility/_cft.scss";
@import "object/utility/_clearfix.scss";
@import "object/utility/_colors.scss";
@import "object/utility/_flex.scss";
@import "object/utility/_font-style.scss";
@import "object/utility/_glyph.scss";
@import "object/utility/_heading.scss";
@import "object/utility/_hidden.scss";
@import "object/utility/_margin.scss";
@import "object/utility/_objectFit.scss";
@import "object/utility/_position.scss";
@import "object/utility/_screen-reader-text.scss";
@import "object/utility/_size.scss";
@import "object/utility/_text.scss";
@import "object/utility/_typography.scss";
@import "object/utility/_uppercase.scss";
@import "object/utility/_visible.scss";

.sd-pulldown__trigger {
  // background: #000;
}
.sd-pulldown__target {
  // @include chrome {
  //   background: purple;
  // };
  @include ios {
    background: purple;
  };
  @include android {
    background: green;
  };
}
