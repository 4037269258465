/*
---
name: Typography
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Foundation
---

### Font family

ローカルフォントのみを参照するケースと WEB フォントのデフォルトセットを利用するケースを考慮して変数を 6 種類用意しています。

#### ローカルフォント

ユーザのローカルフォントを参照する指定として、以下の様に定義しています。

```sass
$sansSerif:
  'Yu Gothic',
  YuGothic,
  '游ゴシック',
  '游ゴシック体',
  'メイリオ',
  Meiryo,
  'ヒラギノ角ゴ Pro W3',
  'Hiragino Kaku Gothic Pro',
  'ＭＳ Ｐゴシック',
  sans-serif;

$serif:
  "游明朝",
  YuMincho,
  "ヒラギノ明朝 ProN W3",
  "HiraMinProN-W3",
  "HG明朝E",
  "ＭＳ Ｐ明朝",
  "MS PMincho",
  "MS 明朝",
  "Sawarabi Mincho",
  serif;

$monospace:
  Monaco,
  Consolas,
  'Courier New',
  Courier,
  monospace;
```

上記のいずれもユーザのローカルフォントを参照するものであるため、どのフォントが表示されるかは各環境に任されるものとします。

#### WEB フォント

WEB フォントは下記の4種類をデフォルトセットとして CDN より取得しています。

> - [Mplus 1p](https://fonts.googleapis.com/earlyaccess/mplus1p.css)
> - [Noto Sans Japanese](https://fonts.googleapis.com/earlyaccess/notosansjapanese.css)
> - [Sawarabi Mincho](https://fonts.googleapis.com/earlyaccess/sawarabimincho.css)
> - [EB Garamond](https://fonts.googleapis.com/css?family=EB+Garamond:600,700,800)

それぞれの WEB フォントのフォントファミリーに基づき、下記の様に変数の定義を行っています。

```sass
$Mplus: 'Mplus 1p', $sansSerif;
$Noto: 'Noto Sans Japanese', $sansSerif;
$Sawarabi: "Sawarabi Mincho", $serif;
$EB: 'EB Garamond', $serif;
```

> *Note:*  <br>
WEB フォントを新たに追加する場合、 `./_development/_source/_scss/theme.scss` にて CDN をインクルードします。CDN ではなくプロジェクトに格納する場合は、フォントファミリー名称のパーシャル SCSS ファイルを作成し、 `./_development/_source/_scss/foundation/` へ、 フォントファイルは `./_development/_source/_fonts/` へ配置を行ってください。

### Typography に関する mixins

Typography に関する mixins は、定義したフォントセットとの関連性の確立のため、同一ファイル内で閉鎖的に定義するものとしています。

#### Letter spacing

カーニングのブレイクポイントでの微調整が可能となります。

```scss
@mixin ls($ls:.1) {
  letter-spacing: #{1.0*$ls}em;
  @include ltMD { letter-spacing: #{.8*$ls}em; };
  @include ltSM { letter-spacing: #{.4*$ls}em; };
  @include ltXS { letter-spacing: #{.3*$ls}em; };
  @include ltXXS { letter-spacing: #{.2*$ls}em; };
}
```

#### Font family

フォントファミリーを指定すると同時に、フォントファミリーごとにフォントウェイト・カーニングの調整が可能となります。

```scss
@mixin sansSerif($weight:500, $ls:.1) {
  font-family: $sansSerif;
  font-weight: $weight;
  letter-spacing: #{$ls}em;
};

@mixin serif($weight:400, $ls:.2) {
  font-family: $serif;
  font-weight: $weight;
  letter-spacing: #{$ls}em;
};

@mixin monospace($weight:400, $ls:0) {
  font-family: $monospace;
  font-weight: $weight;
  letter-spacing: #{$ls}em;
};

@mixin Mplus($weight:400, $ls:.1) {
  font-family: $Mplus;
  font-weight: $weight;
  @include ls($ls);
};

@mixin Noto($weight:400, $ls:.1) {
  font-family: $Noto;
  font-weight: $weight;
  letter-spacing: #{$ls}em;
};

@mixin Sawarabi($weight:500, $ls:.2) {
  font-family: $Sawarabi;
  font-weight: $weight;
  letter-spacing: #{$ls}em;
};

@mixin EB($weight:500, $ls:.2) {
  font-family: $EB;
  font-weight: $weight;
  letter-spacing: #{$ls}em;
};
```
#### フォントサイズの rem 指定と IE 6 ハック

IE 6 での フォントサイズの rem 指定対策が可能となります。（ただし、 IE 6 では MD サイズ以下のブレイクポイントには対応できません。）

```scss
@mixin fs($size, $base: 10) {
  font-size: $size + px;
  font-size: ($size / $base) + rem;
}
```
下記の様に指定が可能となります。
```scss
p {
  @include fs(20);
}

// 変換後
p {
  font-size: 16px;
  font-size: 1.6rem;
}
```

*/

// --------------------------------------------------
// variables
// --------------------------------------------------

$sansSerif: 'Yu Gothic', YuGothic, '游ゴシック', '游ゴシック体', 'メイリオ', Meiryo, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'ＭＳ Ｐゴシック', sans-serif;
$serif: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", "Sawarabi Mincho", serif;
$monospace: Monaco, Consolas, 'Courier New', Courier, monospace;
$Mplus: 'Mplus 1p', $sansSerif;
$RMplus: 'Rounded Mplus 1c', $sansSerif;
$Noto: 'Noto Sans Japanese', $sansSerif;
$Lato: 'Lato', $sansSerif;
$Sawarabi: "Sawarabi Mincho", $serif;
$EB: 'EB Garamond', $serif;

// --------------------------------------------------
// mixins
// --------------------------------------------------

@mixin ls($ls:.08) {
  letter-spacing: #{1.0*$ls}em;
  @include ltMD { letter-spacing: #{.8*$ls}em; };
  @include ltSM { letter-spacing: #{.4*$ls}em; };
  @include ltXS { letter-spacing: #{.3*$ls}em; };
  @include ltXXS { letter-spacing: #{.2*$ls}em; };
}

@mixin sansSerif($weight:500, $ls:.08) {
  font-family: $sansSerif;
  font-weight: $weight;
  letter-spacing: #{$ls}em;
};

@mixin serif($weight:400, $ls:.16) {
  font-family: $serif;
  font-weight: $weight;
  letter-spacing: #{$ls}em;
};

@mixin monospace($weight:400, $ls:0) {
  font-family: $monospace;
  font-weight: $weight;
  letter-spacing: #{$ls}em;
};

@mixin Mplus($weight:500, $ls:.08) {
  font-family: $Mplus;
  font-weight: $weight;
  @include ls($ls);
};

@mixin RMplus($weight:500, $ls:.08) {
  font-family: $RMplus;
  font-weight: $weight;
  @include ls($ls);
};

@mixin Noto($weight:400, $ls:.08) {
  font-family: $Noto;
  font-weight: $weight;
  letter-spacing: #{$ls}em;
};

@mixin Lato($weight:400, $ls:.08) {
  font-family: $Lato;
  // font-weight: $weight;
  letter-spacing: #{$ls}em;
};

@mixin Sawarabi($weight:500, $ls:.16) {
  font-family: $Sawarabi;
  font-weight: $weight;
  letter-spacing: #{$ls}em;
};

@mixin EB($weight:500, $ls:.16) {
  font-family: $EB;
  font-weight: $weight;
  letter-spacing: #{$ls}em;
};

@mixin fs($size, $base: 10) {
  font-size: $size + px;
  font-size: ($size / $base) + rem;
}
