/*
---
name: Post Archive
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Project
---

*/

// ----------------------------------------
// Post Archive
// ----------------------------------------
.c-post-archive {
  color: $lightcharcoal;

  &__item {
    padding: 1em 0;
    border-bottom: 1px solid rgba($grey,.2);
    @include flex(ce);
  }

  &__heading {
    @include fs(24);
    padding: .5em 0;
    border-bottom: 1px solid rgba($grey,.2);
  }

  &__thumb {
    width: 10em;
    height: 7em;
    margin-right: 1em;

    @include ltXXS {
      width: 100%;
      height: 50vw;
      margin-right: 0;
      margin-bottom: .5em;
    };

    img {
      width: 100%;
      height: 100%;
      @include object-fit;
    }
  }

  &__body {
    width: calc( 100% - 10em - 1em);

    @include ltXXS {
      width: 100%;
    };
  }

  &__postinfo {
    @include flex(ce);
    color: $grey;
  }

  &__date {
    @include fs(14);
    @include flex(ce);
  }

  &__date-body {
    @include fs(12);
    margin-left: .2em;
    font-weight: 700;
  }

  &__title {
    @include fs(24);
    font-weight: 400;

    & > a {
      color: currentColor;
      font-weight: 400;
    }
  }

  &__cat {
    margin-left: 1em;
    @include fs(14);
    text-transform: uppercase;
    @include flex(ce);

    ul {
      margin-left: .5em;
      @include flex(ce);
    }
  }

  &__tag {
    @include fs(12);
    color: $grey;
    @include flex(ce);

    a {
      display: inline-block;
      padding: 0 1em;
      border: 1px solid $lightsilver;
      border-radius: 1em;
      background: $white;
      margin: .2em;
      color: currentColor;

      @include hover {
        background: $cs03;
        color: $truewhite;
        border-color: $accent;
      };

      &::before {
        content: '#';
      }
    }
  }

  &__tag-label {
    @include fs(13);
    text-transform: uppercase;
  }

  &__tag-body {}

}
