/*
---
name: Message
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Project
---
*/

.p-message {
  background: url('/_assets/img/index/bg-contact.jpg');
  background-size: cover;
  background-attachment: fixed;
  padding: 4em 0;
  text-align: center;
  position: relative;
  color: inherit;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    background: rgba($white,.9);
  }

  &__inner {

    p {
      @include ltXS {
        text-align: left;
        padding-left: 2em;
        padding-right: 2em;
      };
    }
  }

  &__button {
    margin-top: 2em;
    color: $truewhite;
    border-color: rgba($truewhite,.5);

    @include hover {
      color: $main;
      background: $truewhite;
      border-color: $truewhite;
    };
  }
}
