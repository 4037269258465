/*
---
name: Journals
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Project
---
 *
ほげほげ説明文
 *
```css
.p-articles {
  color: aaa;
  display: block;
}
```

*
ほげほげな説明文
*
```css
.p-articles {
  &--hoge {
    なんか
  }
}
```
*/

.p-journals {
  @include fs(16);
  padding-bottom: 1.5em;

  @include ltMD {
    padding-bottom: 1em;
  };

  &__item {
    width: 25%;
  }

  &__thumb {
    margin: 0 0 2px;

    img {
      display: block;
      width: 100%;
      height: 12em;
      @include object-fit;

      @include ltXS {
        height: 25vw;
      };

      @include ltXXS {
        height: 50vw;
      };
    }
  }

  &__detail {
    background: white;
    color: $charcoal;
    padding: .6em 1.2em;
    margin-bottom: 2px;
  }

  &__category {
    text-transform: uppercase;
    @include fs(12);
    font-weight: 500;
    color: $cs02 !important;
    text-decoration: none;
  }

  &__title {
    @include fs(12);

    a {
      color: currentColor;
      text-decoration: none;
    }
  }

  &__time {
    display: block;
    @include fs(10);
    background: white;
    color: $grey;
    padding: .6em 1.6em;
    font-weight: 600;
  }

  &__button {
    color: currentColor;
    text-decoration: none;
    text-transform: uppercase;
    display: block;
    background: $trueblack;
    padding: 1.6em 1.2em;
    font-weight: 500;
    @include fs(12);
    position: relative;

    &::after {
      box-sizing: border-box;
      @include fs(10);
      content: "";
      display: block;
      width: 1em;
      height: 1em;
      position: absolute;
      top: calc( 50% - .5em );
      right: 1.4em;
      transform: rotate(45deg);
      z-index: 1;
      border: 1px solid transparent;
      border-top-color: currentColor;
      border-right-color: currentColor;
      @include opacity(.7);
    }

    @include hover {
      background: $accent;
      color: $truewhite;
    };
  }
}

.p-journals-container {
  background: url(../img/index/greenhouse-cowork-568024-unsplash.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  color: $truewhite;
  padding-top: 2em;
  padding-bottom: 3em;

  &__header {
    @include fs(36);
    margin-bottom: .8em;
    font-weight: 400;
    text-transform: uppercase;
  }
}
