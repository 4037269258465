/*
---
name: Product
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Project
---
*/

.p-product {
  padding: 4em 0;
  text-align: center;
}

.p-product-introduce {
  @include flex(fs,ce);
  text-align: left;

  &__image {
    width: 100%;
    margin-left: -50%;

    @include ltXS {
      margin-left: 0;
      padding-left: 2em;
      padding-right: 2em;
    };

    img {
      width: 100%;
    }
  }

  &__detail {
    width: 50%;
    padding-top: 4em;

    @include ltXS {
      width: calc( 100% - 4em );
      margin-left: 2em;
      margin-right: 2em;
      padding-top: 0;
      padding-left: 2em;
      padding-right: 2em;
    };
  }

  &__name {

    img {
      max-width: 30em;
    }

    small {
      display: block;
    }
  }

  &__concept {
    @include fs(26);
    margin: 1em 0;

    em {
      font-style: italic;
      color: #53ada8;
      @include fs(20);
    }
  }

  &__button {
    margin-top: 2em;
    color: $main;
    border-color: $main;

    @include hover {
      color: $truewhite;
      background: $main;
      border-color: $main;
    };

    @include ltXS {
      text-align: center;
      display: block;
      width: 14em;
      margin-left: auto;
      margin-right: auto;
    };
  }
}
