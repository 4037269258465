/*
---
name: Faq Collapse
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Project
---
*/

.p-faq-collapse {

  &__title {}

  &__num {
    color: $accent;
    margin-right: .5em;
    font-weight: 600;
  }

  &__detail {
    padding: .2em 1em;
    @include fs(14);
  }
}
