/*
---
name: Sitemaps
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Project
---

*/

.p-sitemaps {

  &__heading {
    padding: .5em 0;
    @include fs(20);
    text-transform: uppercase;
    border-bottom: 2px solid rgba($accent,.8);
    font-weight: 700;

    > a {
      color: currentColor;
    }
  }

  &__row {
    @include flex(fs,sb);
  }

  &__block {
    margin-bottom: 1em;
  }
}

.p-sitemaps-column {
  width: 24%;

  &__title {
    padding: .5em 0;
    border-bottom: 2px solid rgba($grey,.2);
  }

  &__label {
    @include fs(13);
    font-weight: 700;
  }

  &__body {}

  &__item {
    @include fs(13);
    border-bottom: 1px solid rgba($grey,.2);
  }

  &__notice {
    display: block;
    padding: .5em 0;
  }

  &__content {
    display: block;
    padding: .5em 0;
    color: currentColor;

    @include fas-before('\f0da',.5) {
      margin-right: .5em;
    };
  }
}

.p-sitemaps-categories {
  @include flex(fs);
  padding: .5em 0;

  @include ltXS {
    padding: 0;
  };

  &__item {
    @include fs(13);
    margin-right: 1em;

    @include ltXS {
      width: 100%;
      padding: 1em 0;
      border-bottom: 1px solid rgba($grey,.2);
    };
  }

  &__content {
    display: block;
    color: currentColor;

    @include fas-before('\f0da',.5) {
      margin-right: .5em;
    };
  }
}
