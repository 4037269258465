/*
---
name: About
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Project
---
*/

.p-about {

  &__section {
    margin: 4em 0;
  }

  &__headding {
    border: none !important;
    text-align: center;
    @include opacity(.5);

    &--xl {}
  }

  &__logo {
    width: 50%;
    margin: 2em auto;
  }

  &__catch-phrase {
    $parent: &;
    text-align: center;
    @include fs(30);
    margin-bottom: 1.5em;

    em {
      @include opacity(.5);
    }

    &::after {
      content: "";
      display: block;
      width: .5em;
      height: .5em;
      background: $accent;
      transform: rotate(45deg);
      margin: 1em auto;
    }

    &--small {
      @extend #{$parent};
      @include fs(24);

      &::after {
        content: none;
        display: none;
      }
    }
  }

  &__cft {
    color: $main;
    font-weight: bold;
    display: inline-block;
    vertical-align: middle;
    font-size: 110%;
    padding: 0 .2em .1em;
  }

  &__mockup {}

  &__detail-container {
    background: $truewhite;
    padding: 1em 8%;
  }

  &__detail-title {
    text-align: center;

    &::before {
      content: none;
      display: none;
    }

    &::after {
      content: "";
      display: block;
      width: .5em;
      height: .5em;
      background: $accent;
      transform: rotate(45deg);
      margin: 1em auto;
    }
  }

  &__detail-label {
    display: block;
    margin-bottom: .5em;
    @include opacity(.5);
  }

  &__detail-lead {
    margin-bottom: 1em;
  }

  &__closing {
    text-align: center;

    a {
      @include fs(20);
      border: none;
      background: $accent;
      color: $truewhite;
      display: inline-block;
      padding: 1em 1.5em;
      padding-right: 4em;
      border-radius: 2em;
      position: relative;

      &[target=_blank]::after {
        content: none;
        display: none;
      }

      @include hover {
        color: $truewhite;
        background: $cs02;
      };

      i:first-child {
        margin-right: .5em;
      }

      .fa-arrow-right {
        position: absolute;
        display: block;
        background: rgba($truewhite,.2);
        border-radius: 50%;
        width: 2.6em;
        height: 2.6em;
        line-height: 2.6em;
        top: calc( 50% - 1.3em );
        right: .5em;
      }
    }
  }

}
