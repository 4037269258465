.c-navbelow {
	margin-top: 2em;
	background: $truewhite;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1em 0;
	text-transform: uppercase;
	text-align: center;

	@include ltXS {
		padding: 0;
	};

	a {
		// color: $base;
		display: flex;
		align-items: center;
		justify-content: center;

		&:hover,&:focus {
			// color: $sub;
		}

		@include ltXS {
			padding: 1em;
		};
	}

	&__item {
		display: block;
		width: 100%;
		line-height: 1em;

		&--prev a::before {
			content: "";
			width: .5em;
			height: .5em;
			display: inline-block;
			border-top: 1px solid;
			border-left: 1px solid;
			transform: rotate(-45deg);
			margin-right: 1em;

			@include ltXS {
				margin-right: .5em;
			};
		}

		&--navList {
			border-left: .3em solid rgba($grey,.1);
			border-right: .3em solid rgba($grey,.1);

			@include ltXS {
				border-left-color: $truewhite;
				border-right-color: $truewhite;
			};
		}

		&--next a::after {
			content: "";
			width: .5em;
			height: .5em;
			display: inline-block;
			border-top: 1px solid;
			border-right: 1px solid;
			transform: rotate(45deg);
			margin-left: 1em;

			@include ltXS {
				margin-left: .5em;
			};
		}
	}
}
