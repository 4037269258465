/*
---
# このファイルは './_development/_source/_fonts/_icons/_lib/_glyphs.scss' にて編集してください。
name: Glyph
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Utility
---
#### Basic Use

CSS スタイルのプレフィックスとアイコンの名前を使用して、アイコンフォントを配置することができます。

Glyph は、インライン要素で使用するように設計されています。簡潔性のために `<i>` タグが好ましいですが、`<span>` を使用することも可能です。

```html
<i class="glyph glyph-preset01"></i>
<span class="glyph glyph-preset02"></span>
```

または、自動で割り当てられている Unicode を16進数の数値文字参照で記述することで文字としての出力も可能です。ただし、この場合は対象の要素へ Glyph のフォントファミリー`glyphs`を指定する必要があります。その場合は `font-family: "glyphs";` を指定するか、対象の要素へ `glyph` クラスを付与してください。

> この方法では擬似クラスではなく**意味をなさないアイコンを文字としてレンダリングする**こととなるため、文書として簡潔なコンテンツではなくなる可能性があります。すなわち、アクセシビリティの低下、SEOへの影響が考えられます。
>
> 限られたセグメントで利用されるWEBアプリケーションやWEBシステムなど、文書としての簡潔性が求められない場面以外では、この方法は推奨しません。

```html
<p style="font-family: 'glyphs';">&#XE001;</p>
<p class="glyph">&#XE002;</p>
```


Glyph は自動的に CSS のサイズと色を継承します。

```html
<div style="font-size:3em; color:LightGreen">
  <i class="glyph glyph-preset01"></i> text...
</div>
```

#### Additional Styling

##### Icon Sizes

```html
<i class="glyph glyph-preset01 glyph-xs"></i>
<i class="glyph glyph-preset01 glyph-sm"></i>
<i class="glyph glyph-preset01 glyph-lg"></i>
<i class="glyph glyph-preset01 glyph-2x"></i>
<i class="glyph glyph-preset01 glyph-3x"></i>
<i class="glyph glyph-preset01 glyph-5x"></i>
<i class="glyph glyph-preset01 glyph-7x"></i>
<i class="glyph glyph-preset01 glyph-10x"></i>
```

|Class|Size|
|-|-|
|`glyph-xs`|.75em|
|`glyph-sm`|.875em|
|`glyph-lg`|1.33em, also applies `vertical-align: -25%`|
|`glyph-2x` through `glyph-10x`|2em through 10em|

##### Fixed Width Icons

`glyph-fw` を使用してアイコンを同じ固定幅に設定します。アイコンの幅を変更すると、縦の位置合わせが狂ってしまいます。 `nav` リストやリストグループのようなものに特に便利です。この例では、アイコンの背景色を追加して固定幅を確認しています。

```html
<div><i class="glyph glyph-preset01 glyph-fw" style="background:DarkSlateGray"></i> text...</div>
<div><i class="glyph glyph-preset02 glyph-fw" style="background:DarkSlateGray"></i> text...</div>
<div><i class="glyph glyph-preset03 glyph-fw" style="background:DarkSlateGray"></i> text...</div>
```

##### Animated Icons

`glyph-spin` クラスを使用してアイコンを回転させることが出来ます。または、`glyph-pulse` を使用して8つのステップで回転させることも出来ます。

```html
<div class="glyph-3x">
  <i class="glyph glyph-preset01 glyph-spin"></i>
  <i class="glyph glyph-preset02 glyph-spin"></i>
  <i class="glyph glyph-preset03 glyph-spin"></i>
  <i class="glyph glyph-preset02 glyph-pulse"></i>
</div>
```
`glyph-pulse` のステップ数を変更したい場合は、`glyph-pulse-6` の様にサフィックスを付与してください。2〜10ステップが可能です。

```html
<div class="glyph-3x">
  <i class="glyph glyph-preset03 glyph-pulse-2"></i>
  <i class="glyph glyph-preset03 glyph-pulse-3"></i>
  <i class="glyph glyph-preset03 glyph-pulse-4"></i>
  <i class="glyph glyph-preset03 glyph-pulse-5"></i>
  <i class="glyph glyph-preset03 glyph-pulse-7"></i>
  <i class="glyph glyph-preset03 glyph-pulse-10"></i>
</div>
```
回転速度を変更したい場合は、`glyph-speed-*` の様にしてください。速度は1〜10まで指定可能です。デフォルトは **4** の回転速度にあたります。

```html
<div class="glyph-3x">
  <i class="glyph glyph-preset03 glyph-spin glyph-speed-1"></i>
  <i class="glyph glyph-preset03 glyph-spin glyph-speed-4"></i>
  <i class="glyph glyph-preset03 glyph-spin glyph-speed-7"></i>
  <i class="glyph glyph-preset03 glyph-spin glyph-speed-10"></i>
</div>
```

*/

@font-face {
  font-family: "glyphs";
  src: url('../fonts/glyph/glyphs.eot');
  src: url('../fonts/glyph/glyphs.eot?#iefix') format('eot'),
    url('../fonts/glyph/glyphs.woff2') format('woff2'),
    url('../fonts/glyph/glyphs.woff') format('woff'),
    url('../fonts/glyph/glyphs.ttf') format('truetype'),
    url('../fonts/glyph/glyphs.svg#glyphs') format('svg');
  font-weight: normal;
  font-style: normal;
}

.glyph {
  font-family: "glyphs";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

// ----------------------------------------
// Icon Sizes
// ----------------------------------------

// makes the font 33% larger relative to the icon container

.glyph-lg {
  font-size: (4em / 3);
  line-height: (3em / 4);
  vertical-align: -.0667em;
}

.glyph-xs {
  font-size: .75em;
}

.glyph-sm {
  font-size: .875em;
}

@for $i from 1 through 10 {
  .glyph-#{$i}x {
    font-size: $i * 1em;
  }
}

// ----------------------------------------
// Fixed Width Icons
// ----------------------------------------
.glyph-fw {
  text-align: center;
  width: (20em / 16);
}

// ----------------------------------------
// Animated Icons
// ----------------------------------------

.glyph-spin {
  animation: fa-spin 2s infinite linear;
}

.glyph-pulse {
  animation: fa-spin 1s infinite steps(8);
}

@for $i from 2 through 20 {
  .glyph-pulse-#{$i} {
    animation: fa-spin 1s infinite steps(#{$i});
  }
}
@for $i from 1 through 20 {
  .glyph-spin-#{$i} {
    animation: fa-spin #{8/$i}s infinite linear;
  }
}
@for $i from 1 through 20 {
  .glyph-speed-#{$i} {
    animation-duration: #{8/$i}s;
  }
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



.glyph-preset01::before { content: "\E001" }
.glyph-preset02::before { content: "\E002" }
.glyph-preset03::before { content: "\E003" }
.glyph-arrow-right::before { content: "\2192" }
.glyph-external::before { content: "\2197" }
.glyph-brand-logo::before { content: "\F000" }
.glyph-alarm-clock-round::before { content: "\F001" }
.glyph-home::before { content: "\F00A" }
.glyph-facebook::before { content: "\F011" }
.glyph-line::before { content: "\F012" }
.glyph-twitter::before { content: "\F013" }
