/*
---
name: Button
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Component
---

*/

.c-button-container {
  &--left {text-align: left;}
  &--center {text-align: center;}
  &--right {text-align: right;}
}

.c-button {
  display: inline-block;
  border: 2px solid rgba($grey,.2);
  padding: .5em 3em;
  border-radius: 2em;
  position: relative;
  overflow: hidden;
  // @include ease;

  @include hover {
    transform: scale(1.08);
  };

  &__text {
    position: relative;
    z-index: 1;
  }

  &__glyph {
    @include fs(18);
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -.5em;
    right: 1em;
    color: $accentLt;
  }
}
