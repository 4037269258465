/*
---
name: Page Navi
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Project
---
*/

.p-page-navi-container {
  padding: 2em 0;
}

.p-page-navi {
  color: $darkgrey;
  width: 100%;
  line-height: 1.6;
  display: flex;
  justify-content: center;

  .wp-pagenavi {
    $root: &;

    @include flex(ce,fs);

    @include ltXXS {
      @include flex(ce,sb);
    };

    span,
    a {
      @include flex(ce,ce);
      background: rgba($grey,.1);
      height: 3em;
      min-width: 3em;
      padding: 0 1em;
      margin: .2em 0;
    }

    a {
      text-decoration: none;

      @include hover {
        background: $accent;
        color: $white;
        text-decoration: none;
      };
    }

    .pages {

      @include ltXS {
        display: none;
      };
    }

    .current {
      background: $accent;
      color: $white;
      font-weight: 600;
    }

    .first,
    .last {
      @include ltXXS {
        width: 100%;
      };
    }

    .first,
    .last,
    .page,
    .nextpostslink,
    .previouspostslink {
      color: $accent;
    }

    .larger {}

    .smaller {}

    .extend {
      color: inherit;
    }

    .nextpostslink {}

    .previouspostslink {}
  }
}
