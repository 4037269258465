/*
---
name: Hero Image
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Component
---

**Hero image** は、主に下層ページのページヘッダーへヒーロー画像として挿入する Component です。 [Page Header](#Page Header) と併せて使用されます。

```html
<div class="c-page-header__image">
  <figure class="c-hero-image"><img class="c-hero-image__item" src="/_assets/img/hero-image/img-about.jpg"></figure>
</div>
```
```jade
//- Pug
.c-page-header__image
  figure.c-hero-image
    img.c-hero-image__item(src="/_assets/img/hero-image/img-about.jpg")
```

下層ページのページヘッダーへヒーロー画像として挿入する場合は下記の様に `.c-page-header` の子要素として `.c-page-header__item` に続けて記載してください。その際、 `.c-page-header__image` クラスのボックスで Wrap する必要があります。

```html
<div class="c-page-header">
  <div class="c-page-header__item c-container">
    <div class="c-page-header__en-title">About</div>
    <div class="c-page-header__detail">
      <h1 class="c-page-header__title">REINDEER とは？</h1>
      <p class="c-page-header__lead">REINDEER は戦略に応じたクリエイティブソリューションを提供します。</p>
    </div>
  </div>
  <div class="c-page-header__image">
    <figure class="c-hero-image"><img class="c-hero-image__item" src="/_assets/img/hero-image/img-about.jpg"></figure>
  </div>
</div>
```
```jade
//- Pug
.c-page-header
  .c-page-header__item.c-container
    .c-page-header__en-title About
    .c-page-header__detail
      h1.c-page-header__title REINDEER とは？
      p.c-page-header__lead REINDEER は戦略に応じたクリエイティブソリューションを提供します。
  .c-page-header__image
    figure.c-hero-image
      img.c-hero-image__item(src="/_assets/img/hero-image/img-about.jpg")
```

*/

.c-hero-image {
  height: 100%;

  &__item {
    width: 100%;
    height: 100%;
    display: block;
    @include object-fit;
  }
}
