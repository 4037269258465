/*
---
name: Typography
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Utility
---

### フォントファミリー

フォントファミリーのユーティリティ・クラスでは、システムフォントを参照するものと、WEBフォントを使用するものがあります。

システムフォントを参照するものについては、いずれもユーザのローカルフォントを参照するものであるため、どのフォントが表示されるかは各環境に任されるものとします。

```html
<table class="pre-table">
  <tr>
    <th colspan="4">Refer from System Font</th>
  </tr>
  <tr class="u-sansSerif">
    <th>Sans Serif</th>
    <td>`.u-sansSerif`</td>
    <td>ABCDE abcde 12345</td>
    <td>あいうえお 安以宇衣於</td>
  </tr>
  <tr class="u-serif">
    <th>serif</th>
    <td>`.u-serif`</td>
    <td>ABCDE abcde 12345</td>
    <td>あいうえお 安以宇衣於</td>
  </tr>
  <tr class="u-monospace">
    <th>monospace</th>
    <td>`.u-monospace`</td>
    <td>ABCDE abcde 12345</td>
    <td>あいうえお 安以宇衣於</td>
  </tr>
  <tr>
    <th colspan="4">Use Web Fonts</th>
  </tr>
  <tr class="u-Mplus">
    <th>Mplus 1p</th>
    <td>`.u-Mplus`</td>
    <td>ABCDE abcde 12345</td>
    <td>あいうえお 安以宇衣於</td>
  </tr>
  <tr class="u-Noto">
    <th>Noto Sans Japanese</th>
    <td>`.u-Noto`</td>
    <td>ABCDE abcde 12345</td>
    <td>あいうえお 安以宇衣於</td>
  </tr>
  <tr class="u-Sawarabi">
    <th>Sawarabi Mincho</th>
    <td>`.u-Sawarabi`</td>
    <td>ABCDE abcde 12345</td>
    <td>あいうえお 安以宇衣於</td>
  </tr>
  <tr class="u-EB">
    <th>EB Garamond</th>
    <td>`.u-EB`</td>
    <td>ABCDE abcde 12345</td>
    <td>あいうえお 安以宇衣於</td>
  </tr>
</table>
```

### グラデーションテキスト

※ webkit 系のブラウザのみ。それ以外は左側の色の単色となります。

```html
<span class="u-gradtypes">Text.</span>
```
*/

.u {
  &-sansSerif {
    @include sansSerif;
  }
  &-serif {
    @include serif;
  }
  &-monospace {
    @include monospace;
  }
  &-Mplus {
    @include Mplus;
  }
  &-Noto {
    @include Noto;
  }
  &-Lato {
    @include Lato;
  }
  &-Sawarabi {
    @include Sawarabi;
  }
  &-EB {
    @include EB;
  }
  &-gradtypes {
    @include gradtypes;
  }
  &-gradbg {
    @include gradbg;
  }
}
