/*
---
name: Recruit
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Project
---
*/

.p-recruit {
  margin-top: 2em;

  &__item {
    background: $truewhite;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 3em;
    border: 1px solid rgba($grey,.2);
    padding: 2em;
    position: relative;

    @include ltXS {
      margin-top: 5em;
      padding-top: 3em;
    };
  }

  &__thumb {
    width: 50%;
    margin: 0 0 1em;

    img {
      width: 100%;
      height: 100%;
      display: block;
      @include object-fit;
    }

    @include ltXS {
      width: 6em;
      height: 6em;
      border-radius: 50%;
      overflow: hidden;
      position: absolute;
      top: -3em;
      left: calc( 50% - 3em );
      z-index: 10;
    };
  }

  &__detail {
    width: 50%;
    color: #333;
    padding: .2em 1.2em;
    margin-bottom: 2px;

    @include ltXS {
      width: 100%;
    };
  }

  &__category {
    font-weight: 500;
    margin-top: 1em;

    dl {
      display: flex;
      align-items: center;
      margin: .5em 0;
    }

    dt {
      text-align: center;
      @include fs(14);
      width: 7em;
      background: rgba($grey,.1);
      padding: 0;
      border-radius: 1em;
    }

    dd {
      width: calc( 100% - 8em );
      margin-left: 2%;
      margin-right: 3%;
      line-height: 1.2;

      a {
        color: currentColor;

        @include hover {
          color: $accent;
        };
      }
    }
  }

  &__title {
    @include fs(20);
    padding-bottom: .5em;
    border-bottom: 1px solid rgba($grey,.2);
    margin-bottom: 0;

    a {
      color: currentColor;
      text-decoration: none;
    }
  }

  &__duration {
    display: block;
    @include fs(14);
    background: $truewhite;
    color: #666;
    padding: 1em 0;
    font-weight: 600;
  }

  &__name {
    padding: .8em 0;
    @include fs(18);
    font-weight: 500;
    border-top: 1px solid rgba($grey,.2);
    display: flex;
    align-items: center;
    line-height: 1.2;

    img {
      min-width: 3em;
      max-width: 3em;
      max-height: 3em;
      margin-right: 1em;
    }

    a {
      color: currentColor;

      @include hover {
        color: $accent;
      };
    }
  }

  &__content {
    width: 100%;
    margin-bottom: 1em;
  }

  &__trigger {
    display: block;
    background: $charcoal;
    color: $truewhite;
    cursor: pointer;
    text-align: center;
    padding: .8em;
    position: relative;

    @include hover {
      @include opacity(.7);
    };

    &::after {
      box-sizing: border-box;
      @include fs(10);
      content: "";
      display: block;
      width: 1em;
      height: 1em;
      position: absolute;
      top: calc( 50% - .75em );
      right: 2.4em;
      transform: rotate(135deg);
      z-index: 1;
      border: 1px solid transparent;
      border-top-color: currentColor;
      border-right-color: currentColor;
      @include opacity(.7);
      @include ease;
    }

    &.is-active::after {
      transform: rotate(-45deg);
      top: calc( 50% - .25em );
    }
  }

  &__target {
    // display: none;

    > *:first-child {
      margin-top: 2em;
    }
  }

  &__button {
    @include fs(18);
    text-align: center;
    width: 100%;
    height: 3em;
    line-height: 3em;
    display: block;
    color: $truewhite;
    text-decoration: none;
    padding: 0;
    position: relative;
    @include gradbg;
    @include ease();
    overflow: hidden;
    border: 1px solid transparent;

    span {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      background: $truewhite;
      transform: scale(0);
      @include ease(.2s);
      @include opacity(0);
      z-index: 1;
    }

    &:hover {
      border-color: $cs02;
      color: $cs02;

      &::before {
        @include opacity(1);
        transform: scale(2.5);
      }
    }
  }
}
