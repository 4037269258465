/*
---
name: Header
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Layout
---

サイトのグローバルヘッダのベーシックスタイルです。 `.l-header` はページ上部に固定表示されます。

```html
<header class="l-header" role="banner">
  <div class="l-header__body u-flex--ce-sb--gtSM u-flex--ce-ce--inXS">
    <h1 class="c-logo">
      <a class="c-logo__body" href="/">
        <i class="glyph glyph-reindeer c-logo__symbol"></i>
        <span class="c-logo__text">REINDEER</span>
      </a>
    </h1>
    <button class="c-nav-trigger c-nav-trigger--last-short c-nav-trigger--ltXXS" data-mq-trigger="['nav','slide']">
      <span class="u-screen-reader-text">ナビゲーションを開閉する</span>
    </button>
    <div class="c-nav-target c-nav-target--ltXXS" data-mq-target="['nav']">
      <nav class="l-navigation" role="navigation">
        <ul class="l-navigation__body u-flex--gtXS">
          <li class="l-navigation__item">
            <a class="l-navigation__link" href="/">home</a>
          </li>
          <li class="l-navigation__item">
            <a class="l-navigation__link" href="/about/">about</a>
          </li>
          <li class="l-navigation__item">
            <a class="l-navigation__link" href="/service/">service</a>
          </li>
          <li class="l-navigation__item">
            <a class="l-navigation__link" href="/company/">company</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</header>
```

```jade
//- Pug
header(role="banner").l-header
  .l-header__body.u-flex--ce-sb--gtSM.u-flex--ce-ce--inXS
    h1.c-logo
      a.c-logo__body(href="/")
        i.glyph.glyph-reindeer.c-logo__symbol
        span.c-logo__text REINDEER
    button.c-nav-trigger.c-nav-trigger--last-short.c-nav-trigger--ltXXS(data-mq-trigger='["nav","slide"]')
      span.u-screen-reader-text ナビゲーションを開閉する
    .c-nav-target.c-nav-target--ltXXS(data-mq-target='["nav"]')
      nav(role="navigation").l-navigation
        ul.l-navigation__body.u-flex--gtXS
          li.l-navigation__item
            a.l-navigation__link(href="/") home
          li.l-navigation__item
            a.l-navigation__link(href="/about/") about
          li.l-navigation__item
            a.l-navigation__link(href="/service/") service
          li.l-navigation__item
            a.l-navigation__link(href="/company/") company
```
*/

$headerH: 4.5em;

.l-header {
  $this: &;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background: transparent;
  color: $main;
  @include ease;

  a {
    @include hover {
      color: currentColor;
    };
  }

  &__body {
    min-height: $headerH;
    padding: 1em 2em;
    @include ease;

    @include ltXS {
      padding: .4em 0;
    };

    @include ltXXS {
      padding: 0;
    };
  }

  &--scrolled {
    background: $truewhite;
    box-shadow: 0 0 4em rgba($trueblack,.1);

    @include gtSM {
      #{$this}__body {
        padding: 0em 2em;
      }
    };

    @include ltXS {
      background: transparent;
      box-shadow: none;
    };
  }

  // @include ltSM {
  //   .is-entire-nav-target & {
  //     background: $truewhite;
  //   }
  // };

}

@media screen and (max-width: 600px) {
  #wpadminbar {
    @include admin-bar {
      position: fixed;
    }
  }
}
