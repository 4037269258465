/*
---
name: Profile
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Project
---

*/

.p-profile {
  $root: &;
  @include fs(16);
  display: flex;
  flex-wrap: wrap;
  margin-top: 2em;

  &__mouseon-content {
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 11;
    text-align: center;
    @include flex(ce,ce,true);
    visibility: hidden;
    @include ease;

    > span.glyph {
      margin-top: auto;
      @include gradtypes;
      @include fs(30);
      @include opacity(0);

      &::before {
        width: 100%;
        display: block;
        content: "↗";
      }
    }

    &::after {
      @include gradtypes;
      margin-bottom: auto;
      content: "View More";
      display: block;
      font-weight: bold;
      width: 100%;
      text-transform: uppercase;
      @include opacity(0);
    }
  }

  &__item {
    width: 32%;
    margin-right: 2%;
    margin-bottom: 2em;
    cursor: pointer;
    height: 32em;
    overflow: hidden;
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 4em;
      left: 0;
      bottom: 0;
      @include gradbg(rgba($base,0),$base,bottom);
      text-align: center;

      @include ltSM {
        line-height: 8em;
        content: "─　View More　─";
        display: inline-block;
        font-weight: bold;
        text-transform: uppercase;
        border-bottom: 1em solid $base;
      };
    }

    @include hover {
      #{$root}__mouseon-content {
        visibility: visible;
        background: rgba($base,.8);
        transform: scale(1.1);

        > span.glyph {
          @include opacity(1);
        }

        &::after {
          @include opacity(1);
        }
      }
    };

    @include ltSM {
      width: 49%;

      &:nth-of-type(2n) { margin-right: 0; }
    };

    &:nth-of-type(3n) {
      margin-right: 0;

      @include ltSM { margin-right: 2%; };
    }

    @include ltXXS {
      width: 100%;

      &:nth-of-type(2n) { margin-right: 0; }
      &:nth-of-type(3n) { margin-right: 0; }
    };
  }

  &__header {
    position: relative;
  }

  &__name {
    @include flex(ce);
    font-weight: 500;
    color: $truewhite;
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;
    padding: 1em;
    width: 100%;
    @include gradbg(transparent, rgba($trueblack,.5), bottom)

    > span {
      @include fs(24);
      display: block;
      line-height: 1.4;

      .small {
        @include fs(16);
        display: block;
      }
    }

    > img {
      width: 5em;
      height: 5em;
      display: block;
      @include object-fit;
      border-radius: 50%;
      margin-right: 1em;

      @include ltSM {
        width: 4em;
        height: 4em;
      };
    }

  }

  &__thumb {
    margin: 0;
    position: relative;
    z-index: 1;
    height: 16em;

    @include ltXS {
      height: 30vw;
    };

    @include ltXXS {
      height: 50vw;
      min-height: 20em;
    };

    img {
      width: 100%;
      height: 100%;
      display: block;
      @include object-fit
    }
  }

  &__lead {
    @include fs(18);
    border-bottom: 2px solid rgba($grey,.2);
    margin-bottom: .8em;
    line-height: 1.2em;
    min-height: 4.5em;
    @include flex(ce);
  }

  &__text {
    @include fs(12);
    // @include fs(10);
  }
}

.p-profile-modal {
  display: none;

  @mixin object {
    position: relative;
    z-index: 1030;
    background: $truewhite;
    width: calc( 100% - 20rem );
    margin-left: 10rem;
    margin-right: 10rem;
    padding-left: 4rem;
    padding-right: 4rem;

    @include ltXS {
      width: calc( 100% - 20% );
      margin-left: 10%;
      margin-right: 10%;
      padding-left: 4%;
      padding-right: 4%;
    };

    @include ltXXS {
      width: calc( 100% - 10% );
      margin-left: 5%;
      margin-right: 5%;
    };
  }

  $root: '.p-profile';
  position: fixed;
  @include gradbg(rgba($cs01,.9),rgba($cs03,.9));
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1010;

  @include admin-bar {
    top: 32px;
    height: calc( 100vh - 32px );

    @media screen and ( max-width: 782px ) {
      top: 46px;
      height: calc( 100vh - 46px );
    }
  };

  @include ltXS {
    background: $base;
  };

  &__button {
    @include fs(10);
    text-transform: uppercase;
    font-weight: 600;
    position: absolute;
    top: 4em;
    right: 2em;
    padding: 3.5em 1em .5em;
    color: $truewhite;
    cursor: pointer;
    z-index: 1040;
    background: #000;
    @include ease;
    user-select: none;

    @include ltXS {
      top: 1em;
      right: 1em;
    };

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 2em;
      left: 1em;
      width: calc( 100% - 2em );
      height: 1px;
      background: currentColor;
      @include ease;
    }

    &::before {
      transform: rotate(30deg);
    }

    &::after {
      transform: rotate(-30deg);
    }

    @include hover {
      box-shadow: 0 0 10px rgba($trueblack,.2);
      transform: scale(1.1);
    };
  }

  &__side-prev,
  &__side-next {
    background: $trueblack;
    position: fixed;
    z-index: 1040;
    top: 50%;
    width: 3em;
    height: 6em;
    border-radius: 0 3em 3em 0;
    color: transparent;
    margin-top: -3em;
    line-height: 6em;
    @include fs(14);
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    @include ease;
    cursor: pointer;
    user-select: none;

    @include ltXS {
      display: none;
    };

    @include hover {
      width: 6em;
      color: $white;
    };

    &::before {
      content: "";
      display: block;
      width: 1em;
      height: 1em;
      transform: rotate(45deg);
      border-bottom: 1px solid $truewhite;
      border-left: 1px solid $truewhite;
      position: absolute;
      z-index: 1040;
      top: calc( 50% - .5em );
      left: 1em;
    }
  }

  &__side-prev {
    left: -6em;
    padding-left: 1em;

    &.is-visible {
      left: 0;
    }
  }

  &__side-next {
    padding-right: 1em;
    border-radius: 3em 0 0 3em;
    right: -6em;

    &::before {
      transform: rotate(-135deg);
      left: auto;
      right: 1em;
    }

    &.is-visible {
      right: 0;
    }
  }

  &__footer {
    @include object;
    @include flex(ce,sb);
    margin-top: 2em;
    margin-bottom: 2em;
    background: transparent;
  }

  &__prev-button,
  &__close-button,
  &__next-button {
    text-transform: uppercase;
    font-weight: 600;
    padding: .5em 2em;
    cursor: pointer;
    background: $trueblack;
    color: $truewhite;
    @include ease;
    user-select: none;

    @include hover {
      box-shadow: 0 0 10px rgba($trueblack,.2);
      transform: scale(1.05);
    };
  }

  &__prev-button,
  &__next-button {
    visibility: hidden;

    &.is-visible {
      visibility: visible;
    }
  }

  &__prev-button {}

  &__close-button {}

  &__next-button {}

  &__body {
    position: relative;
    background: rgba($charcoal,.9);
    background: rgba($white,1);
    height: calc( 100% - 4em );
    box-shadow: 0 0 2em rgba($trueblack,.2);
    margin-top: 2em;
    overflow-y: scroll;
    padding: 0;

    @include admin-bar {

      @media screen and ( max-width: 782px ) {

        @include ltXS {
          height: calc( 100% - 46px );
        };
      }
    };

    @include ltXS {
      margin-top: 0;
      height: 100%;
      box-shadow: none;
    };
  }

  #{$root}__mouseon-content {
    display: none;
  }

  #{$root}__item {
    width: 100%;
    height: auto;
  }

  #{$root}__header {}

  #{$root}__name {
    @include object;
    margin-top: 10em;
    color: inherit;
    display: block;

    @include ltXS {
      margin-top: 20vw;
    };

    @include ltXXS {
      margin-top: 15em;
    };

    > span {
      padding-top: 3em;
      text-align: center;

      .small {}
    }

    > img {
      width: 8em;
      height: 8em;
      box-shadow: 0 0 10px rgba($trueblack,.1);
      position: absolute;
      left: calc( 50% - 4em );
      top: -4em;
    }

  }

  #{$root}__thumb {
    @extend .c-container ;
    position: fixed;
    top: 2em;
    z-index: 1020;
    padding: 0;

    @include admin-bar {
      top: calc( 2em + 32px );

      @media screen and ( max-width: 782px ) {
        top: calc( 2em + 46px );

        @include ltXS {
          top: 46px;
        };
      }
    };

    @include safari {
      position: absolute;
      top: -10em;

      @include ltXS {
        top: -15em;
      };
    };

    @include ltXS {
      top: 0;
    };

    img {
      @include object-fit;
    }
  }

  #{$root}__lead {
    @include object;
    margin-bottom: 2px;
    border-bottom: none;

    @include gtSM {
      text-align: center;
      @include flex(ce,ce);
    };
  }

  #{$root}__text {
    @include object;
    padding-top: 2em;
    padding-bottom: 2em;
  }
}
