/*
---
name: Main
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Layout
---

メインコンテンツの Wrapper 要素です。

```html
<main class="l-main" role="main">

  Main Content Here...

  <a class="c-pagetop-button" href="#pagetop"><span class="u-screen-reader-text">ページ上部へ移動</span></a>
</main>
```
```jade
//- Pug
main.l-main(role="main")

  | Main Content Here...

  a.c-pagetop-button(href="#pagetop")
    span.u-screen-reader-text ページ上部へ移動
```

ヘッダーと重なる場合など、ヘッダーの高さの分のパディングをメインコンテンツ上部に指定したい場合は `.l-main--shift-to-bottom`  Modifier を使用してください。

``` html
<main class="l-main l-main--shift-to-bottom" role="main">...</main>
```
```jade
//- Pug
main.l-main.l-main--shift-to-bottom(role="main")
  | ...
```

*/

.l-main {
  min-height: 50vh;
  position: relative;

  &--shift-to-bottom {
    padding-top: $headerH;
  }
}
