/*
---
name: Copyright
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Component
---

**Copyright** は主にフッターなどのコンテント・インフォメーション・ブロックに配置する著作権表記です。

```html
<div class="c-copyright">
  <span class="c-copyright__item">©
    <span class="c-copyright__text" data-begin-year="2016">
      <a class="c-copyright__link" href="/">REINDEER</a> Inc.
    </span>
  </span>
</div>
```
```jade
//- Pug
span.c-copyright__item ©
  span.c-copyright__text(data-begin-year='2017')
    a.c-copyright__link(href='/') REINDEER
    |  Inc.
```

**[data-begin-year] 属性**

`[data-begin-year]` 属性には、著作物としての権利が発生した年を西暦で指定します。JS により、この要素内に `{権利発生年}-{現在の年}` の形式でテキストが生成されます。

権利発生年が現在の年と同じ場合、または大きい場合は `{権利発生年}` のみ表示されます。

```html
<div class="c-copyright">
  <span class="c-copyright__item">©
    <span class="c-copyright__text" data-begin-year="2017">
      <a class="c-copyright__link" href="/">REINDEER</a> Inc.
    </span>
  </span>
</div>

<div class="c-copyright">
  <span class="c-copyright__item">©
    <span class="c-copyright__text" data-begin-year="2018">
      <a class="c-copyright__link" href="/">REINDEER</a> Inc.
    </span>
  </span>
</div>

<div class="c-copyright">
  <span class="c-copyright__item">©
    <span class="c-copyright__text" data-begin-year="2019">
      <a class="c-copyright__link" href="/">REINDEER</a> Inc.
    </span>
  </span>
</div>

<div class="c-copyright">
  <span class="c-copyright__item">©
    <span class="c-copyright__text" data-begin-year="2020">
      <a class="c-copyright__link" href="/">REINDEER</a> Inc.
    </span>
  </span>
</div>

<div class="c-copyright">
  <span class="c-copyright__item">©
    <span class="c-copyright__text" data-begin-year="2021">
      <a class="c-copyright__link" href="/">REINDEER</a> Inc.
    </span>
  </span>
</div>
```



*/

.c-copyright {
  text-align: center;
  margin-top: 4em;
  margin-left: auto;
  margin-right: auto;
  background: $mainDk;

  @include ltXS {
    background: inherit;
  };

  &__item {
    @include fs(12);
    font-weight: 700;
    display: inline-block;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
  }

  &__link {
    @include opacity(.8);
    color: currentColor;
    text-decoration: underline;
    font-weight: bolder;
  }
}
