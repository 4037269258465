/*
---
name: Font-style
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Utility
---
*/

.u-font-style {
  &__italic {
    font-style: italic;
  }
}
