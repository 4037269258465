/*
---
name: Floating Box
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Project
---
*/

.p-floating-box {
  background: $truewhite;
  padding: 2em 4em 4em;
  margin-top: -4em;
  position: relative;
  z-index: 11;
  box-shadow: 0 1em 4em rgba($trueblack,.1);
  text-align: center;

  @include ltXS {
    width: calc( 100% - 4em );
    margin-left: 2em;
    margin-right: 2em;
    padding: 2em 2em 4em;
  };

  &__concept {
    @include fs(40);
    color: #53ada8;
    margin-bottom: 1em;
  }

  &__lead {
    @include fs(20);
  }
}
