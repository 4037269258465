/*
---
name: Nav Trigger
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Component
---

**Nav Trigger** はナビゲーションの表示・非表示を切り替える際のトリガー要素で、 [Nav Target](#Nav Target) と併せて使用されます。

`.c-nav-trigger` クラスと併せてブレイクポイントのサフィックスをマルチクラスで指定する必要があります。<br>
例えば、ブレイクポイントが SM 以下の場合にトリガーを非表示にしたい場合は、 `.c-nav-trigger--ltSM` となります。<br>
Nav Target が表示されている状態の時、 Nav Trigger には、JS により `.is-active` クラスが付与されます。非表示の状態になると `.is-active` クラスが外れます。

また、ブラウザのリサイズを行った際に、有効になっているブレイクポイントの範囲外にある状態では `.is-active` クラスが付与されます。逆に、有効になっているブレイクポイントの範囲内にある状態では `.is-active` クラスが削除されます。

```html
<button class="c-nav-trigger c-nav-trigger--ltLG" data-mq-trigger="[&quot;nav&quot;,&quot;slide&quot;]"><span class="u-screen-reader-text">ナビゲーションを開閉する</span></button>

<div class="c-nav-target c-nav-target--ltLG" data-mq-target="[&quot;nav&quot;]">
  <nav class="l-navigation" role="navigation">
    <ul class="l-navigation__body u-flex--gtXS">
      <li class="l-navigation__item">
        <a class="l-navigation__link" href="/">home</a>
      </li>
      <li class="l-navigation__item">
        <a class="l-navigation__link" href="/about/">about</a>
      </li>
      <li class="l-navigation__item">
        <a class="l-navigation__link" href="/service/">service</a>
      </li>
      <li class="l-navigation__item">
        <a class="l-navigation__link" href="/company/">company</a>
      </li>
    </ul>
  </nav>
</div>
```
```jade
//- Pug
button.c-nav-trigger.c-nav-trigger--ltLG(data-mq-trigger='["nav","slide"]')
  span.u-screen-reader-text ナビゲーションを開閉する

.c-nav-target.c-nav-target--ltLG(data-mq-target='["nav"]')
  nav(role="navigation").l-navigation
    ul.l-navigation__body.u-flex--gtXS
      li.l-navigation__item
        a.l-navigation__link(href="/") home
      li.l-navigation__item
        a.l-navigation__link(href="/about/") about
      li.l-navigation__item
        a.l-navigation__link(href="/service/") service
      li.l-navigation__item
        a.l-navigation__link(href="/company/") company
```

**[data-mq-trigger] 属性**

ターゲットの `[data-mq-trigger]` 属性の第1引数はターゲット要素との関連付けに使用します。そのため、ターゲット要素の `[data-mq-target]` 属性の第1引数と同じ値を指定してください。

また、第2引数に slide および fade を指定することで、ターゲット要素を表示する際のアニメーションを指定する事ができます。

**アクティブの状態での Invalid Scroll**

`[data-mq-trigger]` をクリックした際、 `<body>` 要素に `.is-entire-nav-target` クラスが付与されます。これにより、ページのスクロールが無効となります。<br>
これを解除したい場合は JS を修正するのではなく、 `./_development/_source/_scss/foundation/_base.scss` の SCSS ファイルの該当の記述を以下の様に修正します。

```scss
// Invalid Scroll を完全に無効にする場合はコメントアウトを行ってください。
.is-entire-nav-target {
  // height: 100vh;
  // overflow: hidden;
}
```
```scss
// ブレイクポイントを指定して invalid-scroll を解除する場合は下記のように修正してください。
.is-entire-nav-target {
  @include ltSM { // SM サイズ以下のときに invalid-scroll を有効
    height: 100vh;
    overflow: hidden;
  };
}
```

**Button style**

```html
Default:
<button class="c-nav-trigger c-nav-trigger--ltLG" data-mq-trigger="[&quot;nav01&quot;,&quot;slide&quot;]"><span class="u-screen-reader-text">ナビゲーションを開閉する</span></button>

Triple Lines:
<button class="c-nav-trigger c-nav-trigger--triple c-nav-trigger--ltLG" data-mq-trigger="[&quot;nav02&quot;,&quot;slide&quot;]"><span class="u-screen-reader-text">ナビゲーションを開閉する</span></button>

last Short:
<button class="c-nav-trigger c-nav-trigger--last-short c-nav-trigger--ltLG" data-mq-trigger="[&quot;nav03&quot;,&quot;slide&quot;]"><span class="u-screen-reader-text">ナビゲーションを開閉する</span></button>
```
```jade
//- Pug
| Default:
button.c-nav-trigger.c-nav-trigger--ltXXS(data-mq-trigger='["nav01","slide"]')
  span.u-screen-reader-text ナビゲーションを開閉する

| Triple Lines:
button.c-nav-trigger.c-nav-trigger--triple.c-nav-trigger--ltXXS(data-mq-trigger='["nav02","slide"]')
  span.u-screen-reader-text ナビゲーションを開閉する

| last Short:
button.c-nav-trigger.c-nav-trigger--last-short.c-nav-trigger--ltXXS(data-mq-trigger='["nav03","slide"]')
  span.u-screen-reader-text ナビゲーションを開閉する
```

*/

.c-nav-trigger {
  font-size: inherit;
  position: absolute;
  top: 20px;
  right: 1.5em;
  width: 3em;
  height: 3em;
  border: none;
  background: transparent;

  // Default Bar Styling
  // --------------------
  $btnW: 2em;
  $btnH: .6em;
  $bdrW: 2px;
  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    width: $btnW;
    top: 50%;
    left: 50%;
    margin-left: #{-.5*$btnW};
    @include ease;
  }

  @include mqReverse {
    transform: scale(.5);
    @include opacity(0);
    right: 30%;
  };

  & {
    // Double line style
    // --------------------

    background: transparent;
    border-radius: 50%;

    &::before,
    &::after {
      width: $btnW;
      height: $bdrW;
      background: currentColor;
    }

    &::before {
      margin-top: calc( #{-.3*$btnH} - #{1.5*$bdrW} );
    }

    &::after {
      margin-top: calc( #{.3*$btnH} + #{.5*$bdrW} );
    }

    &.is-active {
      transform: rotate(180deg);

      &::before,
      &::after {
        margin-top: #{-.5*$bdrW};
        background: $truewhite !important;
      }

      &::before {
        transform: rotate(25deg);
      }

      &::after {
        transform: rotate(-25deg);
      }
    }
  }

  &--triple,
  &--last-short {
    // Triple line style
    // --------------------

    &::before {
      height: $btnH*.5;
      margin-top: calc( #{-.5*$btnH} - #{1.5*$bdrW} );
      border-top: $bdrW solid;
      border-bottom: $bdrW solid;
      background: transparent;
    }

    &::after {
      width: $btnW;
      height: $bdrW;
      margin-top: calc( #{.5*$btnH} + #{.5*$bdrW} );
      background: currentColor;
    }

    &.is-active {
      transform: rotate(180deg);

      &::before,
      &::after {
        border-width: 0;
        width: $btnW;
        height: $bdrW;
        background: currentColor;
        margin-top: #{-.5*$bdrW};
      }

      &::before {
        transform: rotate(30deg);
      }

      &::after {
        transform: rotate(-30deg);
        width: $btnW;
      }
    }
  }

  &--last-short {
    // last line short style
    // --------------------

    &::after {
      width: #{.5*$btnW};
    }
  }
}
