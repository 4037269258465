/*
---
name: Breadcrumb
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Component
---

Page Header は、主に下層ページのページヘッダーに使用します。

```html
<div class="c-page-header">
  <div class="c-page-header__item c-container">
    <div class="c-page-header__en-title">About</div>
    <div class="c-page-header__detail">
      <h1 class="c-page-header__title">REINDEER とは？</h1>
      <p class="c-page-header__lead">REINDEER は戦略に応じたクリエイティブソリューションを提供します。</p>
    </div>
  </div>
</div>
```
```jade
//- Pug
.c-page-header
  .c-page-header__item.c-container
    .c-page-header__en-title About
    .c-page-header__detail
      h1.c-page-header__title REINDEER とは？
      p.c-page-header__lead REINDEER は戦略に応じたクリエイティブソリューションを提供します。
```

[Hero Image](#Hero Image) と併せて使用する場合は、 `.c-page-header` の子要素として `.c-page-header__item` に続けて記載してください。その際、 `.c-page-header__image` クラスのボックスで Wrap する必要があります。

```html
<div class="c-page-header">
  <div class="c-page-header__item c-container">
    <div class="c-page-header__en-title">About</div>
    <div class="c-page-header__detail">
      <h1 class="c-page-header__title">REINDEER とは？</h1>
      <p class="c-page-header__lead">REINDEER は戦略に応じたクリエイティブソリューションを提供します。</p>
    </div>
  </div>
  <div class="c-page-header__image">
    <figure class="c-hero-image"><img class="c-hero-image__item" src="/_assets/img/hero-image/img-about.jpg"></figure>
  </div>
</div>
```
```jade
//- Pug
.c-page-header
  .c-page-header__item.c-container
    .c-page-header__en-title About
    .c-page-header__detail
      h1.c-page-header__title REINDEER とは？
      p.c-page-header__lead REINDEER は戦略に応じたクリエイティブソリューションを提供します。
  .c-page-header__image
    figure.c-hero-image
      img.c-hero-image__item(src="/_assets/img/hero-image/img-about.jpg")
```

`.c-page-header__en-title` は、主に英語表記のラベルとして使用します。このブロックを使用しない場合、は `.c-page-header__detail` は中央配置されます。

`.c-page-header__detail` のテキストをセンタリングする場合は `.c-page-header__detail` と併せて `--center` の Modifier をマルチクラスとして指定します。

```html
<div class="c-page-header">
  <div class="c-page-header__item c-container">
    <div class="c-page-header__detail c-page-header__detail--center">
      <h1 class="c-page-header__title c-page-header__title--large">REINDEER とは？</h1>
      <p class="c-page-header__lead">REINDEER は戦略に応じたクリエイティブソリューションを提供します。</p>
    </div>
  </div>
  <div class="c-page-header__image">
    <figure class="c-hero-image"><img class="c-hero-image__item" src="/_assets/img/hero-image/img-about.jpg"></figure>
  </div>
</div>
```
*/

.c-breadcrumb-container {}

.c-breadcrumb {
  padding: 1em 2em;
  @include flex(ce,fs,false);
  border-bottom: 1px solid rgba($grey,.1);

  @include ltXS {
    overflow-x: scroll;
  };

  &__item {
    @include fs(12);
    margin-right: 1em;
    @include flex(ce,fs,false);

    @include ltXS {
      white-space: nowrap;
    };

    &:not(:nth-of-type(1))::before {
      content: "";
      display: inline-block;
      min-width: .3em;
      height: .3em;
      transform: rotate(45deg);
      border-top: 2px solid currentColor;
      border-right: 2px solid currentColor;
      margin-right: 1.3em;
      @include opacity(.3);
    }

    &--dot {
      &::before {
        min-width: .3em;
        height: .3em;
        border-radius: 50%;
        border: none;
        background: currentColor;
      }
    }
  }

  &__link {
    color: currentColor;
    font-weight: 800;
    @include opacity(.7);

    &:hover {
      @include opacity(1);
    }

    > span {}
  }
}
