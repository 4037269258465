/*
---
name: Keyvisual
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Project
---
 *
ほげほげ説明文
 *
```css
.p-articles {
  color: aaa;
  display: block;
}
```

*
ほげほげな説明文
*
```css
.p-articles {
  &--hoge {
    なんか
  }
}
```
*/

.p-keyvisual {

  &__item {
    position: relative;

    @include ltXS {
      display: flex !important;
      flex-flow: column-reverse;
    };

  }

  &__detail {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: block;
    background: rgba($truewhite,.8);
    text-decoration: none;
    color: $charcoal;
    padding: 0;

    @include ltXS {
      position: static;
      background: $base;
    };

    @include hover {
      color: $charcoal;
    };
  }

  &__title {
    @include fs(18);
    margin-bottom: .5em;
    padding: 1em 2rem 0;

    @include gtSM {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    };

    small {
      @include fs(14);
      @include flex(ce,fs);
      margin-bottom: .5em;

      img {
        min-width: 2.4em;
        max-width: 2.4em;
        height: 2.4em;
        margin-right: .5em;
      }
    }
  }

  &__taxonomies {
    @include fs(12);
    font-weight: 500;
    float: left;
    padding: 0 2rem 1em;
    @include flex(ce,fs,true);
  }

  &__taxonomy {
    @include flex(ce,fs,false);
    padding-right: 1em;
    margin-bottom: .5em;
    min-height: 2.5em;

    @include ltXS {
      width: 50%;
    };

    @include ltXXS {
      width: 100%;
    };
  }

  &__tax-name {
    display: inline-block;
    background: rgba($trueblack,.2);
    border-radius: 1em;
    padding: 0 1em;
    color: $truewhite;

    @include ltXS {
      min-width: 7.5em;
      text-align: center;
    };
  }

  &__term-name {
    margin-left: .5em;
    line-height: 1.2em;
  }

  &__duration {
    @include fs(14);
    margin: 0;
    clear: both;
    float: right;
    color: $truewhite;
    padding: .2em 1em;
    background: rgba($trueblack,.8);

    @include ltXS {
      float: none;
      width: 100%;
      padding: 1em 1.5em;
    };
  }

  &__thumb {
    margin: 0;

    img {
      display: block;
      width: 100%;
      height: 600px;
      @include object-fit;

      @include ltLG {
        height: 35vw;
      };

      @include ltMD {
        height: 40vw;
      };

      @include ltSM {
        height: 45vw;
      };
    }
  }
}

.p-keyvisual-container {
  @include gradbg;
  color: $truewhite;
  @include fs(16);

  &--lower {
    margin-top: 4em;
  }
}

.p-keyvisual-search {
  padding-top: 2em;
  padding-bottom: 2em;

  &__header {
    @include fs(36);
    font-weight: 300;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 0;
  }

  &__body {
    @include flex(ce,sb);
  }

  &__item {
    width: (94%/4);
    margin-bottom: 1em;

    @include ltXS {
      width: 48%;
    };

    @include ltXXS {
      width: 100%;
    };

    &:nth-of-type(4n) {
      margin-right: 0;
    }

    p {
      @include fs(12);
      font-weight: 500;
      margin-bottom: 0;
    }
  }

  select {
    @include fs(12);
    appearance: button;
    border-radius: 0;
    display: block;
    width: 100%;
    border: none;
    background-color: $truewhite;
    background-image: url(../img/form-parts/_part-select.png);
    background-size: 18px 16px;
    background-repeat: no-repeat;
    background-position: right 6px center;
    font-family: inherit;
    padding: 0 3em 0 .8em;
    margin: .2em 0;
    height: 4em;
    line-height: 4em;
    cursor: pointer;
    @include ease;

    @include ltXS {
      font-size: 16px;
      $scale: 0.7;
      $adjustWidth: 100% * ( 1 / $scale );
      transform: scale($scale);
      width: $adjustWidth;
      margin-left: (100% - $adjustWidth)/2;
    };

    &:disabled {
      color: #999;
    }

    &:hover {
      border-color: $accent;
    }
  }
}
