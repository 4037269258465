/*
---
name: Uppercase
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Utility
---
*/

.u-uppercase {
  text-transform: uppercase;
}
