/*
---
name: Base
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Foundation
---

Base `./_development/_source/_scss/foundation/_base.scss` では、各要素のノーマライジング後にスタイルを再定義しています。<br>
ここでのスタイルの指定が要素セレクタと属性セレクタのデフォルトスタイルとなります。

Base 内では意図しないスタイルを防ぐため、下記の項目を基本原則とします。

- `position: absolute` および `position: fixed` を指定しない
- `float:` プロパティは使用しない
- `display: flex` および Flex、Flex-box、Column、Grid 関連のスタイルを指定しない
- フレージング・コンテンツへ `inline` および `inline-block` 以外の `display:` プロパティの変更は行わない
- フロー・コンテンツへの `block` 以外の `display:` プロパティの変更は行わない

### Universal element style

全ての要素に対してのスタイル指定です。

- Border Box

#### Border Box

当プロジェクトではグリッド・システムやカラムのサイズの調整、配置の都合上、全ての要素に対して `box-sizing: border-box` を指定しています。

```scss
* { box-sizing: border-box; }
```

ある Component 配下の下位のスタイルに何らかの影響を及ぼす可能性がある場合は、下記の様に `border-box` の指定を解除してください。

```scss
//  Component 配下の全要素に対して解除する場合
.selector * { box-sizing: content-box; }

//  Component 配下の特定の要素に対して解除する場合
.selector { box-sizing: content-box; }
```
*/

// --------------------------------------------------
// Basic styling
// --------------------------------------------------

html {
  $baseFontSize: 62.5%;
  font-size: $baseFontSize;
  height: 100%;

  @include ltMD {
    font-size: $baseFontSize * 0.84;
  };

  @include ltSM {
    font-size: $baseFontSize * 0.80;
  };

  @include ltXS {
    font-size: $baseFontSize * 0.75;
  };
}

body {
  @include Mplus;
  // @include RMplus;
  @include fs(16);
  line-height: 1.8;
  min-width: 320px;
  min-height: 100vh;
  margin: 0 auto !important;
  position: relative;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: auto;
  background: $base;
  color: $charcoal;

  &.is-entire-nav-target {
    height: 100vh;
    overflow: hidden;
  }

  &.is-invalid-scroll {
    height: 100vh;
    overflow: hidden;
  }
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

// --------------------------------------------------
// Flow content
// --------------------------------------------------

// --------------------------------------------------
// Phrasing content
// --------------------------------------------------

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  font-weight: bold;
  word-break: break-all;
  @include linear;

  @include hover {
    color: $cs01;
  };
}

strong {
  font-weight: bold;
}

small {
  font-size: 80%;
}

// --------------------------------------------------
// Form Element
// --------------------------------------------------

button,
input[type="button"],
input[type="submit"] {
  color: currentColor;
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  border: 1px solid;
  cursor: pointer;
  @include ease;
  @include fs(16);
  text-align: center;
}

input:focus,
textarea:focus,
keygen:focus,
select:focus { outline-offset: -2px; }
:focus { outline: none; }
a:focus { outline: none;text-decoration: none; }

main {

  // --------------------------------------------------
  // Only in Main Content
  // --------------------------------------------------

  a {
    color: $accent;

    @include hover {
      color: $accentLt;
    };
  }
}
