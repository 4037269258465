/*
---
name: Archive
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Project
---
 *
ほげほげ説明文
 *
```css
.p-articles {
  color: aaa;
  display: block;
}
```

*
ほげほげな説明文
*
```css
.p-articles {
  &--hoge {
    なんか
  }
}
```
*/

.p-archive {
  display: flex;
  flex-wrap: wrap;

  &__item {
    width: 49%;
    background: white;
    display: flex;
    flex-wrap: wrap;
    margin-right: 2%;
    margin-bottom: 1.5em;

    @include gtLG {
      &:nth-of-type(2n) {
        margin-right: 0;
      }
    };

    @include inMD {
      width: 32%;

      &:nth-of-type(3n) {
        margin-right: 0;
      }
    };

    @include inSM {
      &:nth-of-type(2n) {
        margin-right: 0;
      }
    };

    @include ltXS {
      width: 100%;
      margin-right: 0;
    };

  }

  &__thumb {
    width: 50%;
    height: 15em;
    margin: 0;

    @include ltMD {
      width: 100%;
    };

    @include ltXS {
      height: 20em;
    };

    @include ltXXS {
      height: 40vw;
    };

    img {
      width: 100%;
      height: 100%;
      display: block;
      @include object-fit;
    }
  }

  &__detail {
    width: 50%;
    color: #333;
    padding: .2em 1.2em;
    margin-bottom: 2px;
    letter-spacing: 0;

    @include ltMD {
      width: 100%;
    };
  }

  &__taxonomies {
    @include fs(10);
    font-weight: 500;
    margin-top: .5em;
  }

  &__taxonomy {
    display: flex;
    align-items: center;
    min-height: 2.5em;
  }

  &__tax-name {
    text-align: center;
    @include fs(10);
    width: 7em;
    border-radius: 1.2em;
    background: $lightsilver;
  }

  &__term-name {
    width: calc( 100% - 7em - 1em );
    margin-left: 1em;
    line-height: 1.2;

    > a { color: currentColor; }
  }

  &__title {
    @include fs(12);
    padding-top: .5em;
    padding-bottom: .5em;
    border-bottom: 1px solid rgba($grey,.2);
    margin-bottom: 0;

    > a { color: currentColor; }
  }

  &__duration {
    display: block;
    @include fs(10);
    background: white;
    color: #666;
    padding: 0;
    font-weight: 600;
  }

  &__name {
    margin-top: .5em;
    padding: .5em 0;
    @include fs(13);
    font-weight: 500;
    border-top: 1px solid rgba($grey,.2);
    @include flex(ce,fs,false);
    line-height: 1.2;

    > a { color: currentColor; }

    img {
      max-height: 2em;
      width: 2em;
      margin-right: .5em;
      min-width: 2em;
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    @include fs(12);

    li {
      width: calc(100%/3);
    }

    a {
      display: block;
      color: white;
      text-decoration: none;
      padding: 1em;
      position: relative;
      border: .5em solid transparent;

      &::after {
        box-sizing: border-box;
        @include fs(10);
        content: "";
        display: block;
        width: 1em;
        height: 1em;
        position: absolute;
        top: calc( 50% - .5em );
        right: 1.4em;
        transform: rotate(45deg);
        z-index: 1;
        border: 1px solid transparent;
        border-top-color: currentColor;
        border-right-color: currentColor;
        @include opacity(.7);
      }

      @include inMD {
        padding: 1em .5em;
        text-align: center;

        &::after {
          display: none;
        }
      };

      @include ltXXS {
        padding: 1em .5em;
        text-align: center;

        &::after {
          display: none;
        }
      };

      @include hover {
        color: $truewhite;
        border-color: rgba($truewhite,.3);
      };
    }

    li:nth-of-type(1) a {
      background: $cs01;
    }

    li:nth-of-type(2) a {
      background: $cs02;
    }

    li:nth-of-type(3) a {
      background: $cs03;
    }
  }
}


.p-archive-container {
  @include fs(16);
  padding-top: 2em;
  padding-bottom: 3em;
  color: $accent;

  &__header {
    @include fs(36);
    margin-bottom: .8em;
    font-weight: 400;
    text-transform: uppercase;
  }

  &__result {
    @include fs(20);
    color: #333;
  }
}
