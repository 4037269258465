$fontPath: "../fonts/mplus-1p";

@mixin font-face($weight,$name) {
  font-family: 'Mplus 1p';
  font-weight: $weight;
  src: url('#{$fontPath}/mplus-1p-#{$name}.eot');
  src: url('#{$fontPath}/mplus-1p-#{$name}.eot?#iefix') format('embedded-opentype'),
    url('#{$fontPath}/mplus-1p-#{$name}.woff') format('woff'),
    url('#{$fontPath}/mplus-1p-#{$name}.ttf') format('truetype');
}

@font-face {
  @include font-face(100,'thin');
}

@font-face {
  @include font-face(300,'light');
}

@font-face {
  @include font-face(400,'regular');
}

@font-face {
  @include font-face(500,'medium');
}

@font-face {
  @include font-face(700,'bold');
}

@font-face {
  @include font-face(800,'heavy');
}

@font-face {
  @include font-face(900,'black');
}
