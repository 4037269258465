/*
---
name: Media Queries
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Foundation
---

#### Breakpoints

プロジェクトで使用するブレイクポイントはビューポートの横幅を基準に 5 つ定義しています。（スタイル指定対象範囲: 6）<br>
これらのブレイクポイントを基準に Mixin やユーティリティ・クラスを作成する場合は、 `@mixin flex-ltXS {...}` や `.u-hidden-xxs {...}` 、 `.u-visible-gtSM {...}` などの様に、基準範囲を明示するためにサフィックスで追加すると良いでしょう。

**XXS** … SP の縦方向での閲覧時を想定

<table>
  <tr>
    <th></th>
    <th>Min</th>
    <th>Max</th>
  </tr>
  <tr>
    <th>Range</th>
    <td>0</td>
    <td>479px</td>
  </tr>
  <tr>
    <th>Variables</th>
    <td>-</td>
    <td>$xxsBreak</td>
  </tr>
</table>

**XS** … SP のサイズを想定

<table>
  <tr>
    <th></th>
    <th>Min</th>
    <th>Max</th>
  </tr>
  <tr>
    <th>Range</th>
    <td>480px</td>
    <td>767px</td>
  </tr>
  <tr>
    <th>Variables</th>
    <td>$xsMinSize</td>
    <td>$xsBreak</td>
  </tr>
</table>

**SM** … タブレットのサイズを想定

<table>
  <tr>
    <th></th>
    <th>Min</th>
    <th>Max</th>
  </tr>
  <tr>
    <th>Range</th>
    <td>768px</td>
    <td>991px</td>
  </tr>
  <tr>
    <th>Variables</th>
    <td>$smMinSize</td>
    <td>$smBreak</td>
  </tr>
</table>

**MD** … 低解像度PC（4:3ディスプレイ）を想定

<table>
  <tr>
    <th></th>
    <th>Min</th>
    <th>Max</th>
  </tr>
  <tr>
    <th>Range</th>
    <td>992px</td>
    <td>1199px</td>
  </tr>
  <tr>
    <th>Variables</th>
    <td>$mdMinSize</td>
    <td>$mdBreak</td>
  </tr>
</table>

**LG** … 一般的な PC のディスプレイを想定

<table>
  <tr>
    <th></th>
    <th>Min</th>
    <th>Max</th>
  </tr>
  <tr>
    <th>Range</th>
    <td>1200px</td>
    <td>1599px</td>
  </tr>
  <tr>
    <th>Variables</th>
    <td>$lgMinSize</td>
    <td>$lgBreak</td>
  </tr>
</table>

**XL** … 大型・高解像度のディスプレイを想定

<table>
  <tr>
    <th></th>
    <th>Min</th>
    <th>Max</th>
  </tr>
  <tr>
    <th>Range</th>
    <td>1600px</td>
    <td>∞</td>
  </tr>
  <tr>
    <th>Variables</th>
    <td>$xlMinSize</td>
    <td>-</td>
  </tr>
</table>

#### Mixins for Media Queries

下記の Mixin を使用することで、各ブレイクポイントを対象としたメディアクエリを簡単に記述できます。

##### 対象サイズ未満

```scss
// XXSサイズ未満
@include ltXXS {
  // content
}

// XSサイズ未満
@include ltXS {
  // content
}

// SMサイズ未満
@include ltSM {
  // content
}

// MDサイズ未満
@include ltMD {
  // content
}

// LGサイズ未満
@include ltLG {
  // content
}

// XLサイズ未満（ = 全てのサイズ ）
@include ltXL {
  // content
}
```

##### 対象サイズ以上

```scss
// XXSサイズ以上（ = 全てのサイズ ）
@include gtXXS {
  // content
}

// XSサイズ以上
@include gtXS {
  // content
}

// SMサイズ以上
@include gtSM {
  // content
}

// MDサイズ以上
@include gtMD {
  // content
}

// LGサイズ以上
@include gtLG {
  // content
}

// XLサイズ以上
@include gtXL {
  // content
}
```

##### 対象サイズ範囲内

```scss
// XXSサイズ範囲内
@include inXXS {
  // content
}

// XSサイズ範囲内
@include inXS {
  // content
}

// SMサイズ範囲内
@include inSM {
  // content
}

// MDサイズ範囲内
@include inMD {
  // content
}

// LGサイズ範囲内
@include inLG {
  // content
}

// XLサイズ範囲内
@include inXL {
  // content
}
```

##### ビューポートの向きの判別

```scss
// 横向き
@include landscape {
  // content
}

// 縦向き
@include portrait {
  // content
}
```

#### ユーティリティ・クラスおよび Mixin・変数の命名に関して

ブレイクポイントを使用したユーティリティ・クラスおよび Mixin 、変数を作成する場合は、対象となるブレイクポイントおよび範囲を明確にするために以下の項目を命名規則としています。

1. **範囲の指定** <br>
  - lt* : 対象のブレイクポイント以下（less than）
  - gt* : 対象のブレイクポイント以上（greater than）
  - in* : 対象の範囲内（in range）
1. **ブレイクポイントの指定** <br>
  範囲の指定のサフィックスに続けて、ブレイクポイントのサフィックスを **大文字で付与** します。

```scss
// e.g.
@mixin flex-ltXS {...}
.u-visible-gtSM {...}
```

> ※ただし、ユーティリティ・クラスの場合かつ対象の範囲内である場合のみ、範囲の指定である `in*` の省略と、ブレイクポイントのサフィックスを小文字で記すことを許容します。
>
> ```scss
> // e.g.
> // .u-hidden-inXXS {...} =>
> .u-hidden-xxs {...}
> ```

#### ユーティリティ・クラスのための Media Queries ループ

Media Queries ごとにループしたい場合、簡単にサフィックス付きのユーティリティ・クラスを生成できます。

###### mq

ブレイクポイントのサフィックスに対応するメディアクエリの範囲内にスタイルを指定する場合に使用します。

```scss
.u-hidden {
  @include mq {display: none;};
}
```
```scss
// => Compiled CSS
@media screen and (max-width: 479px) {
  .u-hidden--ltXXS { display: none }
}
@media screen and (max-width: 767px) {
  .u-hidden--ltXS { display: none }
}
@media screen and (max-width: 991px) {
  .u-hidden--ltSM { display: none }
}
@media screen and (max-width: 1199px) {
  .u-hidden--ltMD { display: none }
}
@media screen and (max-width: 1599px) {
  .u-hidden--ltLG { display: none }
}
.u-hidden--ltXL { display: none }
@media screen and (max-width: 479px) {
  .u-hidden--inXXS { display: none }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .u-hidden--inXS { display: none }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .u-hidden--inSM { display: none }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .u-hidden--inMD { display: none }
}
@media screen and (min-width: 1200px) and (max-width: 1599px) {
  .u-hidden--inLG { display: none }
}
@media screen and (min-width: 1600px) {
  .u-hidden--inXL { display: none }
}
.u-hidden--gtXXS { display: none }
@media screen and (min-width: 480px) {
  .u-hidden--gtXS { display: none }
}
@media screen and (min-width: 48em) {
  .u-hidden--gtSM { display: none }
}
@media screen and (min-width: 992px) {
  .u-hidden--gtMD { display: none }
}
@media screen and (min-width: 1200px) {
  .u-hidden--gtLG { display: none }
}
@media screen and (min-width: 1600px) {
  .u-hidden--gtXL { display: none }
}
```

###### mqReverse

ブレイクポイントのサフィックスに対応するメディアクエリの **範囲外**にスタイルを指定する場合に使用します。（ **mq** と反対の処理が行われます）

```scss
.u-visible {
  @include mqReverse {display: none;};
}
```
```scss
// => Compiled CSS
@media screen and (min-width: 480px) {
  .u-visible--ltXXS { display: none }
}
@media screen and (min-width: 48em) {
  .u-visible--ltXS { display: none }
}
@media screen and (min-width: 992px) {
  .u-visible--ltSM { display: none }
}
@media screen and (min-width: 1200px) {
  .u-visible--ltMD { display: none }
}
@media screen and (min-width: 1600px) {
  .u-visible--ltLG { display: none }
}
.u-visible--ltXL { display: none }

@media screen and (min-width: 480px) {
  .u-visible--inXXS { display: none }
}
@media screen and (max-width: 479px) {
  .u-visible--inXS { display: none }
}
@media screen and (min-width: 48em) {
  .u-visible--inXS { display: none }
}
@media screen and (max-width: 767px) {
  .u-visible--inSM { display: none }
}
@media screen and (min-width: 992px) {
  .u-visible--inSM { display: none }
}
@media screen and (max-width: 991px) {
  .u-visible--inMD { display: none }
}
@media screen and (min-width: 1200px) {
  .u-visible--inMD { display: none }
}
@media screen and (max-width: 1199px) {
  .u-visible--inLG { display: none }
}
@media screen and (min-width: 1600px) {
  .u-visible--inLG { display: none }
}
@media screen and (max-width: 1599px) {
  .u-visible--inXL { display: none }
}
.u-visible--gtXXS { display: none }

@media screen and (max-width: 479px) {
  .u-visible--gtXS { display: none }
}
@media screen and (max-width: 767px) {
  .u-visible--gtSM { display: none }
}
@media screen and (max-width: 991px) {
  .u-visible--gtMD { display: none }
}
@media screen and (max-width: 1199px) {
  .u-visible--gtLG { display: none }
}
@media screen and (max-width: 1599px) {
  .u-visible--gtXL { display: none }
}
```
*/

// ========================================
//  Variables
// ========================================

// ----------------------------------------
//  ブレイクポイント
// ----------------------------------------

$xsMinSize: 480px;
$smMinSize: 768px;
$mdMinSize: 992px;
$lgMinSize: 1200px;
$xlMinSize: 1600px;
$xxsBreak: $xsMinSize - 1px;
$xsBreak: $smMinSize - 1px;
$smBreak: $mdMinSize - 1px;
$mdBreak: $lgMinSize - 1px;
$lgBreak: $xlMinSize - 1px;

// ========================================
//  Mixins
// ========================================

// ----------------------------------------
// 対象サイズ未満のみ
// ----------------------------------------

@mixin ltXXS() {
  @media screen and (max-width: $xxsBreak) {
    @content;
  }
}
@mixin ltXS() {
  @media screen and (max-width: $xsBreak) {
    @content;
  }
}
@mixin ltSM() {
  @media screen and (max-width: $smBreak) {
    @content;
  }
}
@mixin ltMD() {
  @media screen and (max-width: $mdBreak) {
    @content;
  }
}
@mixin ltLG() {
  @media screen and (max-width: $lgBreak) {
    @content;
  }
}
@mixin ltXL() {
  @content;
}

// ----------------------------------------
// 対象サイズ以上のみ
// ----------------------------------------

@mixin gtXXS() {
  @content;
}
@mixin gtXS() {
  @media screen and (min-width: $xsMinSize) {
    @content;
  }
}
@mixin gtSM() {
  @media screen and (min-width: $smMinSize) {
    @content;
  }
}
@mixin gtMD() {
  @media screen and (min-width: $mdMinSize) {
    @content;
  }
}
@mixin gtLG() {
  @media screen and (min-width: $lgMinSize) {
    @content;
  }
}
@mixin gtXL() {
  @media screen and (min-width: $xlMinSize) {
    @content;
  }
}

// ----------------------------------------
// 対象サイズ範囲内のみ
// ----------------------------------------

@mixin inXXS() {
  @media screen and (max-width:$xxsBreak) {
    @content;
  }
}
@mixin inXS() {
  @media screen and (min-width:$xsMinSize) and (max-width:$xsBreak) {
    @content;
  }
}
@mixin inSM() {
  @media screen and (min-width:$smMinSize) and (max-width:$smBreak) {
    @content;
  }
}
@mixin inMD() {
  @media screen and (min-width:$mdMinSize) and (max-width:$mdBreak) {
    @content;
  }
}
@mixin inLG() {
  @media screen and (min-width:$lgMinSize) and (max-width:$lgBreak) {
    @content;
  }
}
@mixin inXL() {
  @media screen and (min-width:$xlMinSize) {
    @content;
  }
}

// ----------------------------------------
// Viewport の向き
// ----------------------------------------

@mixin landscape() {
  @media screen and (orientation: landscape) {@content;}
}
@mixin portrait() {
  @media screen and (orientation: portrait) {@content;}
}

// ========================================
// Media Queries for Utility
// ========================================

@mixin mq {
  &--ltXXS {@include ltXXS {@content;}}
  &--ltXS {@include ltXS {@content;}}
  &--ltSM {@include ltSM {@content;}}
  &--ltMD {@include ltMD {@content;}}
  &--ltLG {@include ltLG {@content;}}
  &--ltXL {@include ltXL {@content;}}

  &--inXXS {@include inXXS {@content;}}
  &--inXS {@include inXS {@content;}}
  &--inSM {@include inSM {@content;}}
  &--inMD {@include inMD {@content;}}
  &--inLG {@include inLG {@content;}}
  &--inXL {@include inXL {@content;}}

  &--gtXXS {@include gtXXS {@content;}}
  &--gtXS {@include gtXS {@content;}}
  &--gtSM {@include gtSM {@content;}}
  &--gtMD {@include gtMD {@content;}}
  &--gtLG {@include gtLG {@content;}}
  &--gtXL {@include gtXL {@content;}}
}

@mixin mqReverse {
	&--ltXXS {@include gtXS {@content;}}
	&--ltXS {@include gtSM {@content;}}
	&--ltSM {@include gtMD {@content;}}
	&--ltMD {@include gtLG {@content;}}
	&--ltLG {@include gtXL {@content;}}
	&--ltXL {@content;};

	&--inXXS {@include gtXS {@content;}}
	&--inXS {@include ltXXS {@content;};@include gtSM {@content;}}
	&--inSM {@include ltXS {@content;};@include gtMD {@content;}}
	&--inMD {@include ltSM {@content;};@include gtLG {@content;}}
	&--inLG {@include ltMD {@content;};@include gtXL {@content;}}
	&--inXL {@include ltLG {@content;}}

	&--gtXXS {@content;};
	&--gtXS {@include ltXXS {@content;}}
	&--gtSM {@include ltXS {@content;}}
	&--gtMD {@include ltSM {@content;}}
	&--gtLG {@include ltMD {@content;}}
	&--gtXL {@include ltLG {@content;}}
}
