/*
---
name: Media
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Component
---
*/

.c-header-mirror {

  @include ltSM {
    width: 100%;
    height: 6em;
    background: $truewhite;
    box-shadow: 0 0 4em rgba($trueblack,.1);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    @include opacity(0);
    @include ease;

    &.is-visible {
      @include opacity(1);
    }
  };
}
