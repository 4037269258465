/*
---
name: Mixins
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Foundation
---

### Gradient

#### グラデーションテキスト

webkit 系のブラウザのみ、テキストにグラデーションの指定が可能となります。<br>
※ webkit 以外は左側の色（第1引数）の単色での表現になります。

```scss
@mixin gradtypes($color1: $grad1, $color2: $grad2, $start: left) {
  color: $color1;
  background: -webkit-linear-gradient($start,$color1,$color2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
```

```html
<span class="u-gradtypes">Gradient text</span>
```
```scss
.u-gradtypes {
  @include gradtypes;
}
```

> 引数の指定によりカラーと方向の指定が可能です。
>
> ```scss
> @include gradtypes(red,blue);
> ```
> ```scss
> @include gradtypes(rgba(black,.2),transparent,top);
> ```

#### グラデーション背景

背景へのグラデーションの指定を行います。

```scss
@mixin gradbg($color1: $grad1, $color2: $grad2, $to:right) {
  background: linear-gradient(to $to, $color1 0%, $color2 100%);
}
```

```html
<span class="u-gradbg">Gradient BG</span>
```
```scss
.u-gradbg {
  @include gradbg;
}
```

> 引数の指定によりカラーと方向の指定が可能です。
>
> ```scss
> @include gradbg(red,blue);
> ```
> ```scss
> @include gradbg(rgba(black,.2),transparent,bottom);
> ```

#### Opacity 前方互換対応

Opacity プロパティの IE 前方互換対応を行います。

```scss
@mixin opacity($transparent) {
  opacity: $transparent;
  filter: alpha(opacity= $transparent * 100);
}
```
```scss
.selector {
  @include opacity(.3);
}
// => Compiled CSS
.selector {
  opacity: .3;
  filter: alpha(opacity=30);
}
```

#### Clearfix

float を使用した際の回り込み解除を親要素に対して行います。

```scss
@mixin clearfix {
  *zoom: 1;

  &::after {
    content: "";
    display: table;
    clear: both;
  }
}
```
```scss
.selector {
  @include clearfix;
}
// => Compiled CSS
.selector {
  *zoom: 1;
}
.selector::after {
  content: "";
  display: table;
  clear: both;
}
```
#### Object Fit 前方互換対応

object-fit プロパティの IE・Edge 前方互換対応を行います。

```scss
@mixin object-fit($type:cover) {
  object-fit: $type;
  font-family: 'object-fit: #{$type}';
}
```
```scss
.selector {
  @include object-fit;
}
// => Compiled CSS
.selector {
  object-fit: cover;
  font-family: 'object-fit: cover';
}
```
引数には `object-fit:` プロパティで指定できる値（ `cover` , `contain` , `fill` , `none` , `scale-down` ）が使用可能です。<br>
※ 何も指定しない場合は `cover` が指定されます。

```scss
.selector {
  @include object-fit(contain);
}
// => Compiled CSS
.selector {
  object-fit: contain;
  font-family: 'object-fit: contain';
}
```

#### 画像のアンチエイリアス処理の無効化

画像のアンチエイリアス処理を無効化します。

```scss
@mixin crisp {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
```
```scss
.selector_img {
  @include crisp;
}
```

#### 背景画像サイズの適合化

ボックスのサイズと背景画像サイズを一致させます。

```scss
@mixin bgs($width,$height) {
  width: $width;
  height: $height;
  background-size: $width $height;
}
```
#### Transition

Transition の指定を行います。

```scss
@mixin transition($time:.15s) {
  transition: $time all;
}

@mixin ease($time:.15s) {
  transition: $time all ease-in-out;
}

@mixin linear($time:.15s) {
  transition: $time all linear;
}
```
```scss
.selector1 {
  @include transition;
}
.selector2 {
  @include ease;
}
.selector3 {
  @include linear;
}
```

> 引数の指定により Transition Duration を指定可能。
>
> ```scss
> @include linear(.3s);
> ```

#### Hover

Hover の効果を MD ブレイクポイント以上で指定出来ます。

```scss
@mixin hover {
  &:hover {
    @include gtMD { @content; }
  }
}
```
```scss
.selector {
  @include hover {
    color: red;
  }
}
```

#### External アイコン有効化

`target="_blank"` を属性に持つリンク（ `<a>` 要素 ）に External アイコンを表示します。

```scss
@mixin external($ct:↗,$family:glyph) {
  &[target] {
    &::after {
      font-size: .8em;
      margin-left: .2em;
      margin-right: .2em;
    }
    @if $family == glyph {
      @include glyph-after($ct,.5);
    } @else if $family == far {
      @include far-after($ct,.5);
    } @else if $family == fab {
      @include fab-after($ct,.5);
    } @else if $family == fas {
      @include fas-after($ct,.5);
    }
  }
}
```
```scss
.selector {
  @include external;
}
```

第1引数に 16 進数の文字コードをエスケープして入力することで、 External アイコンを個別に変更できます。（または文字として入力できます）

```scss
.selector {
  @include external('\E001');
}
```
第2引数に `glyph` , `far` , `fab` , `fas` のいずれかを指定することで、アイコンフォントを変更できます。何も指定しなければ `glyph` が有効になります。

|Value|Font Family|
|-|-|
|glyph|[Glyphs](../Object/Utility/index.html#Glyph)|
|fas|Font Awesome 5 [Solid](https://fontawesome.com/icons?d=gallery&s=solid)|
|far|Font Awesome 5 [Regular](https://fontawesome.com/icons?d=gallery&s=regular)|
|fab|Font Awesome 5 [Brands](https://fontawesome.com/icons?d=gallery&s=brands)|



```scss
.selector {
  @include external('\f360',fas);
}
```

##### アイコンフォントを疑似要素で生成

任意のアイコンフォントを疑似要素で生成します。

|Mixin|Icon Font|Appeng For-|
|-|-|-|
|@include glyph-before;|Glyphs|::before|
|@include glyph-after;|Glyphs|::after|
|@include fas-before;|Font Awesome 5 Solid|::before|
|@include fas-after;|Font Awesome 5 Solid|::after|
|@include far-before;|Font Awesome 5 Regular|::before|
|@include far-after;|Font Awesome 5 Regular|::after|
|@include fab-before;|Font Awesome 5 Brands|::before|
|@include fab-after;|Font Awesome 5 Brands|::after|

第1引数に 16 進数の文字コードをエスケープして（または文字として）入力し、出力するアイコンを指定します。

```scss
.selector {
  @include glyph-before('\E001');
}
```

第2引数に `opacity:` プロパティを指定可能です。何も指定しなければ `1` が有効になります。

```scss
.selector {
  @include glyph-before('\E001',.3);
}
```

第3引数に `color:` プロパティを指定可能です。何も指定しなければ親要素の `color:` プロパティが継承されます。

```scss
.selector {
  @include glyph-before('\E001',.3,Turquoise);
}
```

アイコンの位置やサイズなど、スタイルを調整したい場合は、ブラケット `{ }` を追加することによりスタイルの調整が可能です。


```scss
.selector {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  @include glyph-before('\E001',.3,Turquoise){
    font-size: .8em;
    margin-left: .2em;
    margin-right: .2em;
  }
}
```

##### Flex Box

Flex Box を指定します。

```scss
@include flex($ai,$jc,$fw)
```

1. 第1引数 **$ai** : align-items（default:null）
指定できる値は下記の通りです。
  - **ce** : flex-start
  - **fs** : flex-start
  - **fe** : flex-end
  - **st** : stretch
2. 第2引数 **$jc** : justify-content（default:null）
指定できる値は下記の通りです。
  - **ce** : center
  - **fs** : flex-start
  - **fe** : flex-end
  - **sa** : space-around
  - **sb** : space-between
3. 第3引数 **$fw** : flex-wrap（default:true）
指定できる値は下記の通りです。
  - **true** : wrap
  - **false** : nowrap

```scss
.selector {
  @include flex(ce,fs);
}
```
```scss
// => Compiled CSS
.selector {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
```

##### Page Types

ページの種別を `<body>` のクラスに付与している場合に有効となります。

```scss
// トップページ
@include index {
  // content
}

// 下層ページ
@include lower {
  // content
}
```

*/

// ========================================
// Gradient
// ========================================

$grad1: $cs01;
$grad2: $cs03;

@mixin gradtypes($color1: $grad1, $color2: $grad2, $start: left) {
  color: $color1;
  background: -webkit-linear-gradient($start,$color1,$color2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin gradbg($color1: $grad1, $color2: $grad2, $to:right) {
  background: linear-gradient(to $to, $color1 0%, $color2 100%);
}

// ========================================
//  IE Hack
// ========================================

@mixin opacity($transparent) {
  opacity: $transparent;
  filter: alpha(opacity= $transparent * 100);
}

@mixin clearfix {
  *zoom: 1;

  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin object-fit($type:cover) {
  object-fit: $type;
  font-family: 'object-fit: #{$type}';
}

// ========================================
// Images
// ========================================

@mixin crisp {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

@mixin bgs($width,$height) {
  width: $width;
  height: $height;
  background-size: $width $height;
}

// ========================================
// Links & Hover action
// ========================================

@mixin transition($time:.15s) {
  transition: $time all;
}

@mixin ease($time:.15s) {
  transition: $time all ease-in-out;
}

@mixin linear($time:.15s) {
  transition: $time all linear;
}

@mixin hover {
  &:hover {
    @include gtMD { @content; }
  }
}

@mixin external($ct:↗,$family:glyph) {
  &[target] {
    &::after {
      font-size: .8em;
      margin-left: .2em;
      margin-right: .2em;
    }
    @if $family == glyph {
      @include glyph-after($ct,.5);
    } @else if $family == far {
      @include far-after($ct,.5);
    } @else if $family == fab {
      @include fab-after($ct,.5);
    } @else if $family == fas {
      @include fas-after($ct,.5);
    }
  }
}

// ========================================
// Icons
// ========================================

@mixin icons-base($family) {
  font-family: $family;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: auto;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  font-weight: 400;
}

@mixin far-before($ct,$op:1,$cl:inherit) {
  &::before {
    @include icons-base('Font Awesome 5 Free');
    content: '#{$ct}';
    opacity: $op;
    filter: alpha(opacity= $op * 100);
    color: $cl;
    @content;
  }
}

@mixin far-after($ct,$op:1,$cl:inherit) {
  &::after {
    @include icons-base('Font Awesome 5 Free');
    content: '#{$ct}';
    opacity: $op;
    filter: alpha(opacity= $op * 100);
    color: $cl;
    @content;
  }
}

@mixin fab-before($ct,$op:1,$cl:inherit) {
  &::before {
    @include icons-base('Font Awesome 5 Brands');
    content: '#{$ct}';
    opacity: $op;
    filter: alpha(opacity= $op * 100);
    color: $cl;
    @content;
  }
}

@mixin fab-after($ct,$op:1,$cl:inherit) {
  &::after {
    @include icons-base('Font Awesome 5 Brands');
    content: '#{$ct}';
    opacity: $op;
    filter: alpha(opacity= $op * 100);
    color: $cl;
    @content;
  }
}

@mixin fas-before($ct,$op:1,$cl:inherit) {
  &::before {
    @include icons-base('Font Awesome 5 Free');
    content: '#{$ct}';
    opacity: $op;
    filter: alpha(opacity= $op * 100);
    color: $cl;
    font-weight: 900;
    @content;
  }
}

@mixin fas-after($ct,$op:1,$cl:inherit) {
  &::after {
    @include icons-base('Font Awesome 5 Free');
    content: '#{$ct}';
    opacity: $op;
    filter: alpha(opacity= $op * 100);
    color: $cl;
    font-weight: 900;
    @content;
  }
}

@mixin glyph-before($ct,$op:1,$cl:inherit) {
  &::before {
    @include icons-base('glyphs');
    vertical-align: -.1em;
    content: '#{$ct}';
    opacity: $op;
    filter: alpha(opacity= $op * 100);
    color: $cl;
    @content;
  }
}

@mixin glyph-after($ct,$op:1,$cl:inherit) {
  &::after {
    @include icons-base('glyphs');
    vertical-align: -.1em;
    content: '#{$ct}';
    opacity: $op;
    filter: alpha(opacity= $op * 100);
    color: $cl;
    @content;
  }
}

// ========================================
// Flex Box
// ========================================

@mixin flex($ai:null,$jc:null,$fw:true) {
  display: flex;

  @if $ai {
    @if $ai == ce {
      align-items: center;
    } @else if $ai == fs {
      align-items: flex-start;
    } @else if $ai == fe {
      align-items: flex-end;
    } @else if $ai == st {
      align-items: stretch;
    } @else {
      align-items: $ai;
    }
  }

  @if $jc {
    @if $jc == ce {
      justify-content: center;
    } @else if $jc == fs {
      justify-content: flex-start;
    } @else if $jc == fe {
      justify-content: flex-end;
    } @else if $jc == sa {
      justify-content: space-around;
    } @else if $jc == sb {
      justify-content: space-between;
    } @else {
      justify-content: $jc;
    }
  }

  @if $fw == true {
    flex-wrap: wrap;
  } @else if $fw == false {
    flex-wrap: nowrap;
  } @else {
    justify-content: $fw;
  }
}

// ========================================
// Page Types
// ========================================

@mixin index() {
  .index & { @content; }
}

@mixin lower() {
  .lower & { @content; }
}

@mixin logged-in() {
  .logged-in & { @content; }
}

@mixin admin-bar() {
  .admin-bar & { @content; }
}
