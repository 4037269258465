/*
---
name: Footer
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Layout
---

サイトのグローバルフッターのベーシックスタイルです。

```html
```

```jade
//- Pug
```
*/

.l-footer {
  background: $main;
  color: white;
  padding-top: 3em;

  &__nav {
    @include gtMD {
      max-width: 960px;
      margin-left: auto;
      margin-right: auto;
    };

    @include ltSM {
      padding-left: 2em;
      padding-right: 2em;
    };
  }

  a {
    @include hover {
      color: currentColor;
    };
  }

  &__logo {
    @include fs(36);
    font-weight: normal;
  }

  &__brand-logo {
    font-size: 18px;
  }

  &__cft {}

  &__nav-container {
    @include opacity(.7);
    @include flex(ce,ce);

    @include ltXS {
      display: none;
    };
  }
}


.l-footer-sns {
  margin-bottom: 1em;

  &__header {
    @include fs(22);
    text-align: center;
    text-transform: uppercase;
    margin-bottom: .5em;
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: center;
    @include fs(34);
    margin-top: 0;
  }

  &__item {
    margin-left: .2em;
    margin-right: .2em;
  }

  &__link {
    font-weight: normal;
    color: currentColor;
    display: block;

    @include hover {
      transform: scale(1.2);
    };
  }
}


.l-footer-sitemap {
  @include flex(st,sb);
  margin-bottom: 4em;

  &__item {
    @include fs(14);
    width: 31%;
    // width: 24%;
    font-weight: 500;

    @include ltSM { width: 48%; };
    @include ltXXS { width: 100%; };

    a {
      text-transform: uppercase;
      display: block;
      color: currentColor;
      padding: .6em 0;
    }

    > li {

      > a {
        border-bottom: 1px solid rgba($truewhite,.5);
      }

      > ul {
        margin-top: .4em;

        a {
          &::before {
            content: "─";
            display: inline-block;
            color: currentColor;
            margin-right: .5em;
            @include opacity(.5);
          }
        }

      }
    }

    &--journals {
      @include ltSM {
        width: 100%;
        margin-bottom: 1em;

        > li > ul {
          @include flex(ce);

          > li {
            margin-right: 2em;
          }
        }
      };

      @include ltXXS {
        margin-bottom: 0;

        > li > ul > li {
          margin-right: 1em;
        }
      };
    }

    &--tags {
      border: 1px solid rgba($truewhite,.5);
      border-radius: .5em;
      margin-top: 3em;
      padding: .5em;
      max-height: 12em;
      overflow-y: scroll;

      @include ltSM {
        max-height: none;
        overflow-y: auto;
      };

      a {
        @include fs(11);
        display: inline-block;
        background: rgba($truewhite,.1);
        padding: 0 .8em;
        border-radius: 1em;
        margin: .2em;
      }
    }
  }
}
