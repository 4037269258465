/*
---
name: Over
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Component
---

*/

.c-over {
  position: fixed;
  width: 40px;
  height: 40px;
  top: 18px;
  right: 16px;
  z-index: 101;
  background: $main;
  @include ease(.3s);
  border-radius: 100%;
  transform: scale(0);

  .is-entire-nav-target & {
    transform: scale(40);
  }
}
