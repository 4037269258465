/*
---
name: 404
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Project
---

*/

.p-404 {
  text-align: left;

  & > * {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  ul {
    list-style: disc;
    margin-left: 2em;
  }

  ol {
    list-style: decimal;
    margin-left: 2em;
  }

  blockquote {
    border-left: .5em solid rgba($grey,.2);
    padding-left: 1em;
  }

  b {
    font-weight: 700;
    @include opacity(.5);
  }

  .c-button {
    margin-top: 2em;
    color: $main;
    border-color: $main;

    @include hover {
      color: $truewhite;
      background: $main;
      border-color: $main;
    };
  }
}
