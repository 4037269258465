// ==================================================
// carousel style setting
// ==================================================
.c-carousel {
  $this: &;

  &__item {
    display: block;
    padding: 5px;
  }

  .slick-prev,
  .slick-next {
    &::before {
      // @include fs(40);
    }
  }

  // ==================================================
  // Slick re-styling.
  // ==================================================
  &--01 {
    $paddingTopBase: 2em;
    padding-top: $paddingTopBase + 2em;
    padding-bottom: 0;

    @include ltSM {
      padding-top: $paddingTopBase + 2.5em;
    };

    @include ltXS {
      padding-top: $paddingTopBase + 3em;
    };

    @media screen and (max-width: 641px) {
      padding-top: $paddingTopBase + 5.5em;
    }

    @include ltXXS {
      padding-top: $paddingTopBase + 3em;
    };

    #{$this}__item {
      margin: 0 2em;
      padding: 0;
    }

    // Arrows
    // ----------------------------------------
    .slick-prev,
    .slick-next {
      background: $cs02;
      font-size: inherit;
      width: 3em;
      height: 3em;
      border-radius: 50%;
      z-index: 1;
      top: calc( 50% + 2em );
      @include ease;

      &.slick-disabled:before {}

      &:before {
        display: inline-block;
        width: 10px;
        height: 10px;
        border: 1px solid transparent;
        border-top-color: white;
        border-left-color: white;
        @include opacity(1);
        @include ease;
      }

      &:hover, &:focus {
        background: white;

        &:before {
          border-top-color: $cs02;
          border-left-color: $cs02;
        }
      }
    }

    .slick-prev {
      left: 12.5em;

      @include ltMD {
        left: 2.5em;
      };

      @include ltXS {
        left: .5em;
      };

      [dir="rtl"] & {}

      &:before {
        content: "";
        transform: rotate(-45deg);
        margin-left: .4em;

        [dir="rtl"] & {}
      }
    }

    .slick-next {
      right: 12.5em;

      @include ltMD {
        right: 2.5em;
      };

      @include ltXS {
        right: .5em;
      };

      [dir="rtl"] & {}

      &:before {
        transform: rotate(135deg);
        content: "";
        margin-right: .4em;

        [dir="rtl"] & {}
      }
    }

    // // Dots
    // // ----------------------------------------
    // .slick-dotted.slick-slider {}
    //
    // .slick-dots {
    //   bottom: -18px;
    //   background: #f3f3f3;
    //   width: 100%;
    //   margin-left: -5px;
    //
    //   @include ltXS {
    //     width: calc( 92vw - 4em ) !important;
    //     padding: 0 2em;
    //     box-sizing: border-box;
    //     margin-left: 0;
    //   };
    //
    //   li {
    //     width: 10px;
    //
    //     button {
    //       &:hover, &:focus {&:before {}}&:before {
    //         .dark & {
    //           color: white;
    //         }
    //       }
    //     }
    //
    //     &.slick-active button:before {
    //       .dark & {
    //         color: white;
    //       }
    //     }
    //   }
    // }

  }

  &--02 {

    // Arrows
    // ----------------------------------------
    .slick-prev,
    .slick-next {
      background: $cs02;
      font-size: inherit;
      width: 3em;
      height: 3em;
      border-radius: 50%;
      z-index: 1;
      top: calc( 50% + -.3em );
      @include ease;

      &.slick-disabled:before {}

      &:before {
        display: inline-block;
        width: 10px;
        height: 10px;
        border: 1px solid transparent;
        border-top-color: white;
        border-left-color: white;
        @include opacity(1);
        @include ease;
      }

      &:hover, &:focus {
        background: white;

        &:before {
          border-top-color: $cs02;
          border-left-color: $cs02;
        }
      }
    }

    .slick-prev {
      left: -1.5em;

      @include ltMD {
        // left: 2.5em;
      };

      @include ltXS {
        left: .5em;
      };

      [dir="rtl"] & {}

      &:before {
        content: "";
        transform: rotate(-45deg);
        margin-left: .4em;

        [dir="rtl"] & {}
      }
    }

    .slick-next {
      right: -1.5em;

      @include ltMD {
        // right: 2.5em;
      };

      @include ltXS {
        right: .5em;
      };

      [dir="rtl"] & {}

      &:before {
        transform: rotate(135deg);
        content: "";
        margin-right: .4em;

        [dir="rtl"] & {}
      }
    }

    // Dots
    // ----------------------------------------
    .slick-dotted.slick-slider {}

    .slick-dots {
      bottom: -0.75em;

      @include ltMD {
        bottom: -1.5em;
      };

      li {
        width: 1em;

        button {
          color: white;

          &:before {
            @include fs(16);
            color: inherit;
            @include ease;
          }
        }

        &.slick-active button:before {
          color: inherit;
        }
      }
    }

  }
}
