/*
---
name: Infotable
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Project
---
```
*/

.p-infotable {
  @include fs(16);
  width: 100%;
  margin-top: 2em;
  margin-bottom: 2em;
  border-collapse: separate;
  border-spacing: 2px;

  @include ltXS {
  };

  caption {
    text-align: center;
    background: $charcoal;
    color: white;
    padding: .8em;
  }

  tbody {
    background: $truewhite;
  }

  &--recruit {
    tbody {
      background: $base;
    }
  }

  &--about {
    th,td {
      border: none !important;
      background: $truewhite !important;
    }
  }

  th, td {
    padding: 1em 1em;
  }

  th {
    width: 20%;
    min-width: 12em;

    @include ltXS {
      min-width: 6em;
    };
  }

  a {
    @include external('\f360',fas);
  }
}
