/*
---
name: Article
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Project
---
*/

.p-article-container {
  padding-top: 4em;
  padding-bottom: 4em;

  &__header {
    @include flex(ce,fs,false);
    margin-bottom: 2em;

    @include ltXS {
      @include flex(fs,fs,false);
    };

    img {
      max-width: 5em;
      min-width: 5em;
      height: 5em;
      margin-right: 1em;
    }
  }

  &__text {
    @include fs(14);
    font-weight: 500;
    line-height: 1.2;
  }

  &__title {
    @include fs(20);
    display: block;
    margin-bottom: .5em;
  }

  &__detail {
    @include fs(16);
    display: block;
  }

  &__eyecatch {
    height: 30em;
    margin-bottom: 2em;

    @include ltXS {
      height: 50vw;
    };

    img {
      display: block;
      width: 100%;
      height: 100%;
      @include object-fit;
    }
  }

  &__tab {
    padding: 0;
    @include flex(ce,sb);
    margin-bottom: .5em;

    @include ltXS {
      padding-left: 6%;
      padding-right: 6%;
    };

    li {
      width: 32%;
      color: white;
      text-transform: uppercase;

      > * {
        display: block;
        color: currentColor;
        text-decoration: none;
        cursor: pointer;
        padding: 1.4em 1.4em;
        position: relative;

        @include ltXS {
          text-align: center;
          padding: .4em .4em;
        };

        &::after {
          box-sizing: border-box;
          @include fs(10);
          content: "";
          display: block;
          width: 1em;
          height: 1em;
          position: absolute;
          top: calc( 50% - .75em );
          right: 2.4em;
          transform: rotate(135deg);
          z-index: 1;
          border: 1px solid transparent;
          border-top-color: currentColor;
          border-right-color: currentColor;
          @include opacity(.7);

          @include ltXS {
            display: none;
          };
        }

        small {
          @include fs(12);
          display: inline-block;
          background: rgba($truewhite,.2);
          border-radius: 1em;
          padding: 0 1em;
          margin-left: 1em;

          @include ltXS {
            @include fs(11);
            padding: 0;
            display: block;
            max-width: 10em;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
          };
        }

        @include hover {
          color: currentColor;
          @include opacity(.7);
        };
      }

      &:nth-of-type(1) > * {background: $cs01;}
      &:nth-of-type(2) > * {background: $cs02;}
      &:nth-of-type(3) > * {background: $cs03;}

      &.is-active {
        > *::before {
          content: "";
          display: block;
          width: 100%;
          height: .5em;
          bottom: -.5em;
          left: 0;
          background: inherit;
          position: absolute;
        }

        > * {
          @include hover {
            @include opacity(1);
            cursor: inherit;
          };
        }
      }
    }
  }
}

.p-article {
  @include fs(16);
  border-top: .3em solid transparent;

  @include ltXS {
    border-top-width: 2px;
  };

  &--01 {border-color: $cs01;}
  &--02 {border-color: $cs02;}
  &--03 {border-color: $cs03;}

  &__header {
    @include fs(40);
    margin: 0;
    padding: .5em 0;
    font-weight: 400;
    text-transform: uppercase;
    @include flex(ce,fs,true);
    border-bottom: 1px solid rgba($grey,.2);
    position: relative;

    @include ltXS {
      font-weight: 300;
      @include fs(50);
    };

    small {
      @include fs(26);
      margin-left: 1em;

      @include ltXS {
        @include fs(20);
        font-weight: 500;
        width: 100%;
        display: block;
        text-align: right;
        @include opacity(.5);
        position: absolute;
        bottom: 1em;
        right: 0;
        z-index: 10;
      };
    }
  }
}

#tinymce {}

#tinymce,
.p-article-post {
  @include clearfix;
  line-height: 2em;

  h2 {
    clear: both;
    border-bottom: 2px solid rgba($cs02Lt,.5);
    padding-bottom: 1rem;
  }

  h3 {
    clear: both;
    border-bottom: 1px solid rgba($cs02Lt,.5);
    padding-bottom: 1rem;
  }

  h4 {
    clear: both;
    border-left: 1rem solid rgba($cs02Lt,1);
    padding-left: 2rem;
  }

  h5 {
    clear: both;

    &::before {
      content: '▪';
      color: rgba($cs02Lt,1);
      margin-right: 1rem;
    }
  }

  h6 {
    clear: both;

    &::before {
      content: "•";
      font-weight: 900;
      color: rgba($cs02Lt,1);
      margin-right: 1rem;
    }
  }

  p {}
}
