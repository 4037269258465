/*
---
name: Fluid
tag:
  - Dependent
#  - Origin
#  - Adhoc
#  - Modify
#  - Standalone
#  - temporary
#  - draft
  - latest
#  - deprecated
category:
  - Structure/Object/Project
---
 *
ほげほげ説明文
 *
```css
.p-articles {
  color: aaa;
  display: block;
}
```

*
ほげほげな説明文
*
```css
.p-articles {
  &--hoge {
    なんか
  }
}
```
*/

.p-fluid {}

.p-fluid-container {
  color: $truewhite;
  display: flex;
  flex-wrap: wrap;

  &__item {
    width: 50%;
    text-align: center;
    padding: 3em 10%;

    &--primary {background: $cs02;}
    &--secondary {background: $cs03;}

    @include ltXS {
      width: 100%;
      padding: 3em 5%;
    };
  }

  &__header {
    @include fs(36);
    font-weight: 400;
    text-transform: uppercase;
  }

  &__lead {
    @include fs(14);
    margin-top: 1em;
    margin-bottom: 1em;
  }

  &__text {
    text-align: left;
    @include fs(14);
    margin-bottom: 3em;
  }
}
